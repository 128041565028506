@use "./config" as *;

%tick {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    @extend %accent-bg;
    @extend %flex-center;
    @include position(0, .75rem, null, null);

    &::after {
        content: "";
        width: 0.4rem;
        height: 0.8rem;
        border: solid hsl(var(--color-hsl-white-default));
        border-width: 0 0.2rem 0.2rem 0;
        @include rotate(45);
    }
}

%action-icon {
    width: 10rem;
    height: 10rem;
    border-radius: 100%;
    @extend %accent-bg-1;
    @extend %flex-center;

    &::before {
        font-size: 5rem;
        font-family: $font-icon;
        @extend %accent;
    }

    .tick {
        @extend %tick;
    }
}
.dark-mode{
    .waf-action-modal .message-wrap .title{
        @extend %accent;
    }
}

.restore-account {
    .icon {
        @extend %action-icon;

        &::before {
            content: "\e85e";
        }
    }
}

.delete-account {
    .icon {
        @extend %action-icon;

        &::before {
            content: "\e860";
        }
    }

}

.waf-action-modal {
    .message-wrap {
        text-align: center;

        .btn-group {
            gap: var(--half-space);
            @extend %flex-center;
        }

        .message {
            &-wrap {
                text-align: center;
            }

            &-title {
                margin: 0;
                @include font(26, $font-primary-bold);
            }
        }

        .icon {
            text-align: center;
            position: relative;
            margin: var(--half-space) auto;

            &-img {
                width: 10.8rem;
                margin: var(--full-space) auto;
            }
        }

        .content {
            text-align: center;
            @extend %font-16-primary-r;

            &:not(:last-child) {
                margin-bottom: var(--full-space);
            }
        }

        button {
            width: 100%;
            margin: var(--half-space) 0;
        }

        .content-wrap {
            margin-bottom: var(--full-space);
        }

        .title {
            @extend %primary-dark;
            @extend %font-20-primary-b;
        }
    }


}