@use "../config/" as *;
.dark-mode {
    .waf-impact {
        .impact-wrap:first-child .impact-title {
            border-left-color: var(--color-button-primary)
        }
        .impact-wrap:last-child .impact-title {
            border-right-color: var(--color-button-primary)
        }
        .impact-label {
            background-color: clr(button-primary, 3);
            border-color: var(--color-button-primary)
        }
        .description {
            color: hsl(var(--color-hsl-white-default)/0.8);
        }
        .title {
            @include background("cssimages/homepage/impact-title-dark.png", center/contain no-repeat);
        }
        .impact {
            &-item {
                @include background("cssimages/homepage/pixel-blur.png");
                @extend %text-center;
                @extend %m-b-full;
                &:hover {
                    .impact-label {
                        transition: 1s;
                        @extend %button-primary-bg;
                    }
                }
            }
        }
    }
    // #95046133
}
.waf-impact {
    margin: 0 var(--half-space-negative);
    aspect-ratio: 375/852;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 0;
    @include background("cssimages/homepage/mobile/royals-impact-bg.png", center/100% 100%);
    @extend %white-default;
    @extend %text-center;
    .waf-head {
        display: block;
        margin: auto;
        position: relative;
    }
    .description {
        @extend %font-14-primary-r;
        @extend %gray-dark;
    }
    .title {
        font-size: 0;
        aspect-ratio: 374/68;
        max-width: 30rem;
        margin: 0 auto 2rem;
        @include background("cssimages/homepage/impact-title-light.png", center/contain no-repeat);
    }
    .waf-body {
        @extend %flex-sb-n;
        @extend %gap-full;
    }
    .waf-footer {
        margin-inline: var(--full-space-negative);
        img {
            display: block;
        }
        .text {
            position: absolute;
            bottom: 0;
            left: calc(-1* var(--container-white-space));
            width: var(--window-inner-width);
            background: linear-gradient(90deg, clr(secondary-dark, 1) 0%, clr(secondary-dark, 7) 5.59%, clr(secondary-dark) 50%, clr(secondary-dark, 7) 94.96%, clr(secondary-dark, 1) 100%);
            @extend %p-y-half;
            @extend %font-14-primary-b;
            @extend %w-100;
            @extend %flex-c-c;
            @extend %gap-quater;
            &::before {
                @include icon(reverse, 20);
            }
        }
    }
    .impact {
        &-title {
            letter-spacing: 5%;
            @extend %text-left;
            @extend %p-x-full;
            @extend %font-20-primary-r;
            @extend %m-y-full;
        }
        &-wrap {
            flex: 1;
            &:first-child {
                .impact-title {
                    border-left: .4rem solid clr(primary);
                    @extend %text-left;
                }
            }
            &:last-child {
                .impact-title {
                    border-right: .4rem solid clr(primary);
                    @extend %text-right;
                }
            }
        }
        &-item {
            @include background("cssimages/homepage/pixel-blur.png");
            @extend %text-center;
            @extend %m-b-full;
            &:hover {
                .impact-label {
                    transition: 1s;
                    @extend %primary-bg;
                }
                .impact-value {
                    transition: 1s;
                    @extend %white-default;
                }
            }
        }
        &-value {
            @extend %font-20-primary-b;
        }
        &-label {
            border-top: .3rem solid clr(primary);
            @extend %p-y-half;
            @extend %primary-bg-2;
            @extend %font-12-primary-b;
            @extend %p-x-full;
        }
    }
}
@media (max-width:$tablet-max-width) {
    .waf-impact {
        aspect-ratio: 375/659;
        padding-block: var(--full-space);
        .layout-wrapper {
            display: flex;
            flex-direction: column;
        }
        .impact-label {
            padding: 0;
        }
        .waf-body {
            order: 2;
            margin-bottom: calc(var(--full-space) + var(--half-space));
        }
        .waf-footer {
            margin-bottom: -3rem;
            padding-inline: 4rem;
            img {
                mask-image: linear-gradient(var(--color-button-primary) 70%, transparent);
                -webkit-mask-image: linear-gradient(var(--color-button-primary) 70%, transparent)
            }
            .text {
                bottom: 2rem;
                padding: .5rem;
            }
        }
    }
}
@media (min-width:$desktop-min-width) {
    .waf-impact {
        aspect-ratio: unset;
        padding-top: 3rem;
        isolation: isolate;
        overflow: hidden;
        @include background("cssimages/homepage/royals-impact-bg.png", center/100% 100%);
        .waf-head {
            max-width: calc(var(--container-max-width) - 47rem);
            .head-wrap {
                position: absolute;
            }
        }
        .impact-title {
            font-size: 4rem
        }
        .impact {
            &-label {
                font-size: 2rem
            }
            &-value {
                font-size: 6rem;
                -webkit-text-stroke: .1rem clr(white-default);
                color: clr(white-default, 5);
                padding-block: var(--full-space);
            }
        }
        .layout-wrapper {
            position: relative;
            &::before,
            &::after {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                font-size: 8rem;
                font-weight: 700;
                writing-mode: vertical-lr;
                color: transparent;
                -webkit-text-stroke: .1rem clr(white-default, 3)
            }
            &::before {
                content: "";
                left: 0;
                bottom: 7%;
                transform: translateX(-100%);
                @include background("cssimages/homepage/international.png", no-repeat center / contain);
                width: 5%;
                height: 65%;
            }
            &::after {
                content: "";
                right: -8.6%;
                bottom: 7%;
                transform: translateX(-100%);
                @include background("cssimages/homepage/domestic.png", no-repeat center / contain);
                width: 4%;
                height: 65%;
            }
        }
        .title {
            max-width: 50rem;
        }
        .description {
            font-size: 2rem;
            padding-inline: 3rem;
        }
        .layout-wrapper {
            position: relative;
            max-width: var(--container-max-width);
            margin: auto;
        }
        .waf-body {
            padding-bottom: 4rem;
        }
        .impact {
            &-wrap {
                max-width: 22rem
            }
        }
        .waf-footer {
            position: absolute;
            bottom: 0;
            left: var(--full-space);
            width: 100%;
            text-align: center;
            display: flex;
            align-items: flex-end;
            z-index: var(--z-index1-negative);
            height: 100%;
            img {
                max-width: calc(100% - 30rem);
                max-height: 80%;
                margin-inline: auto;
                object-fit: contain;
                object-position: bottom center;
            }
        }
        .text {
            margin-block: 0;
        }
    }
}