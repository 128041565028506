@use "../config" as *;

.waf-child-wrapper {
    @extend %m-x-auto;
}

.waf-ad {
    display: block;
    width: 100%;
    height: max-content;
    @extend %m-y-full;

    &-wrapper {
        font-size: 0.1rem;
        isolation: isolate;
        @include shimmer();
        @extend %m-x-auto;
        @extend %text-center;
        @extend %flex-c-c;
        &::after{
            z-index: var(--zindex-shimmer);
        }
    }

    &-leader &-wrapper {
        width: 32rem;
        height: 5rem;
    }

    &-rectangle &-wrapper {
        width: 30rem;
        height: 10rem;
    }

    &-content &-wrapper {
        width: 32rem;
        height: 5rem;
    }

    &-mrec &-wrapper {
        width: 30rem;
        height: 25rem;
    }

    &-halfpage &-wrapper {
        width: 30rem;
        height: 60rem;
    }

    &-skyscrapper &-wrapper {
        width: 16rem;
        height: 60rem;
    }

    &-multiplex &-wrapper {
        width: 30rem;
        height: 46rem;
    }
}

@media screen and (min-width: $tablet-min-width) {
    .waf-ad {

        &-leader,
        &-rectangle,
        &-content {
            .waf-ad-wrapper {
                width: 46.8rem;
                height: 6rem;
            }
        }

        &-multiplex &-wrapper {
            width: 60rem;
            height: 22rem;
        }
    }
}

@media screen and (min-width: $desktop-min-width) {
    .waf-ad {

        &-leader,
        &-rectangle,
        &-content {
            .waf-ad-wrapper {
                width: 72.8rem;
                height: 9rem;
            }
        }

        &-multiplex &-wrapper {
            width: 80rem;
            height: 42rem;
        }
    }
}
