@use "./config" as *;
.waf-academy-foundation {
    padding: 0;
    margin: 0 var(--half-space-negative);
    .article-item {
        overflow: hidden;
    }
    .article-wrap {
        position: relative;
    }
    .img-box {
        // aspect-ratio: 4/3;
        border-radius: unset;
        img {
            display: block;
            @extend %w-100;
            @extend %h-100;
        }
    }
    @extend %img-shade;
    .article-title {
        @extend %white-default;
        @extend %font-24-primary-b;
    }
    .text {
        @extend %white-default;
        @extend %font-12-m;
        @extend %m-b-half;
    }
    .article-content {
        padding-bottom: var(--full-space);
        @extend %p-half;
        @extend %position-bottom-left;
    }
    .readmore {
        font-size: 0;
        @extend %w-100;
        @extend %h-100;
        @extend %position-top-left;
    }
    .logo {
        width: 5rem;
        aspect-ratio: 3/4;
        img {
            height: 100%;
            object-fit: contain;
            display: block;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-row-academy-foundation {
        padding-bottom: var(--full-space);
        @include background("svg/section-bg.svg");
        background-size: cover;
    }
    .waf-academy-foundation {
        margin: 0;
        margin-top: var(--two-space);
        .img-box {
            aspect-ratio: 16/9;
        }
        .article-title {
            @include font(26);
        }
        .text {
            @include font(14);
            margin-bottom: var(--full-space);
        }
        .article-content {
            padding: var(--full-space);
        }
        .logo {
            width: 9.5rem;
        }
        .article-item {
            border-radius: var(--half-radius);
            .btn-fill {
                padding-block: var(--quater-space);
            }
        }
    }
    .sticky-container-academy-foundation {
        .col-lg-6 {
            &:first-child {
                padding-left: var(--full-space);
            }
            &:last-child {
                padding-right: var(--full-space);
            }
        }
    }
}