@use "./config" as *;
.waf-listing.home-podcast-list {
    @extend %primary-dark-bg;
    .timestamp p {
        @extend %font-10-m;
        @extend %text;
        @include color(black-default);
        &::before {
            content: "\e80e";
            color: inherit;
            font-size: 1rem;
            font-family: $font-icon;
            @include color(black-default);
            @extend %m-r-quater;
        }
    }
    .reaction-section {
        @extend %m-l-auto;
    }
    .icon-b-share::after {
        @include color(black-default);
    }
    .item-type-audio {
        .article-thumbnail {
            position: relative;
            .item-type-icon {
                bottom: 1rem;
                right: 1rem;
                width: 2.8rem;
                height: 2.8rem;
                border: .2rem solid clr(white-default);
                &::after {
                    content: "\e80f";
                    font-size: .7rem;
                    font-family: $font-icon;
                    @extend %white-default;
                }
            }
        }
        .img-box {
            @extend %radius-half;
        }
    }
    .item-type-icon {
        position: absolute;
        margin-bottom: 0;
        @extend %black-default-bg;
        @extend %circle-radius;
        @extend %flex-c-c;
    }
    .item-type-audio {
        background-color: transparent;
    }
    .head-wrap {
        a {
            @extend %text;
        }
        .title {
            @extend %text;
        }
    }
    .article {
        &-list {
            @include card-count-new(2, var(--full-space));
            @extend %m-x-half-neg;
            @extend %p-x-half;
        }
        &-info {
            @include position(null, null, var(--half-space), 0);
        }
        &-item {
            flex-shrink: 0;
            margin-inline: 0;
        }
        &-title {
            @extend %font-16-primary-b;
            @extend %text;
            @extend %m-b-full;
            @include truncate-vertical-line(16, 2);
        }
        &-content {
            position: relative;
            padding-inline: 0;
            &::before,
            &::after {
                content: '';
                height: 0.1rem;
            }
        }
    }
    .meta-wrap {
        position: relative;
        @extend %w-100;
        @extend %flex-sb-n;
        &::after {
            content: '';
            height: 0.1rem;
            @extend %w-100;
            @extend %white-default-bg-1;
            @include position(var(--full-space-negative), 0);
        }
    }
}
.dark-mode {
    .waf-listing.home-podcast-list {
        .article-item {
            background-color: transparent;
        }
        .reaction-section {
            @extend %m-l-auto;
        }
        .icon-b-share::after {
            @include color(white-default);
        }
        .timestamp p {
            @include color(white-default);
            &::before {
                @include color(white-default);
            }
        }
        .article-content {
            background: transparent;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-listing.home-podcast-list {
        background-position: left;
        .timestamp p {
            color: clr(white-default);
            &::before {
                color: clr(white-default);
            }
        }
        .head-wrap {
            .title {
                color: #{clr(white-default)};
            }
            .head-tab {
                a {
                    color: #{clr(white-default)};
                }
            }
        }
        .icon-b-share::after {
            @include color(white-default);
        }
        .article {
            &-title {
                margin-bottom: var(--full-space);
                @include font(20, $font-primary-bold);
                @include color(white-default);
                @include truncate-vertical-line(20, 2);
            }
            &-list {
                overflow-x: unset;
                flex-wrap: wrap;
                @include card-count-new(3, var(--full-space));
            }
        }
        .article-content {
            background-color: unset;
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-listing.home-podcast-list {
        .article-list {
            @include card-count-new(4, var(--full-space));
        }
    }
}