@use "./map" as *;
$base-space: 0.5rem; //base space variable
$max-space-count: 25; // maximum count variable generation
:root {
	@each $color,
	$value in $default-mode {
		--color-#{$color}: hsl(#{$value});
		--color-hsl-#{$color}: #{$value};
	}
	body.dark-mode {
		@each $color,
		$value in $dark-mode {
			--color-#{$color}: hsl(#{$value});
			--color-hsl-#{$color}: #{$value};
		}
	}
	@for $i from 0 through $max-space-count {
		--space-#{$i}: #{$base-space * $i};
		--space-#{$i}-neg: #{$base-space * $i * -1};
	}
	// system colors
	--color-grey: hsl(0, 0%, 88%);
	--color-hsl-grey: 0 0% 88%;
	--color-black: hsl(0, 0%, 0%);
	// --color-error-text: hsl(4, 90%, 58%);
	// --color-success-text: hsl(142, 100%, 26%);
	/* shimmer color variables*/
	--shimmer-light-color: hsl(0, 0%, 87%);
	--shimmer-dark-color: hsl(0, 0%, 67%);
	--tracker-color: hsl(142, 100%, 26%);
	// space variables
	--full-space: 2rem;
	--full-space-negative: calc(var(--full-space) * -1);
	--one-n-half-space: calc(var(--full-space) + var(--half-space));
	--three-fourth-space: calc(var(--quater-space) + var(--half-space)); // value 15 px
	--three-fourth-space-negative: calc((var(--quater-space) + var(--half-space)) * -1);
	--half-space: calc(var(--full-space) / 2); // value 10 px
	--half-space-negative: calc(var(--half-space) * -1);
	--quater-space: calc(var(--half-space) / 2); // value 5 px
	--quater-space-negative: calc(var(--quater-space) * -1); // value -5 px
	--one-third-space: calc(var(--full-space) / 3); // value 6.66 px
	--two-space: calc(2 * var(--full-space)); // value 40 px
	--two-space-negative: calc((2 * var(--full-space)) * -1); // value 40 px
	--two-n-half-space: calc((2 * var(--full-space)) + var(--half-space)); // value 40 px
	--three-space: calc(3 * var(--full-space)); // value 60 px
	--full-radius: 1rem;
	--half-radius: calc(var(--full-radius) / 2);
	--quarter-radius: calc(var(--full-radius) / 4);
	--container-white-space: calc((var(--window-inner-width) - var(--container-max-width)) / 2);
	--form-guide-item-gap: ((100% - 25rem) / 4);
	--form-guide-item-width: 2rem;
	--z-index1: 1;
	--z-index1-negative: -1;
	--z-index2-negative: -2;
	--header-z: 9;
	--score-strip-z: 8;
	--next-slide-z: -1;
	--secondary-nav-z: 6;
	--bg-gradient: -2;
	--active-line: -1;
	--theme-toggle: 4;
	--list: 1;
	--title: 1;
	--more-title: 1;
	--scroll-top: 8;
	--filter-z: 4;
	--filter-more-z: 20;
	--tab-height: 3.6rem;
	--modal-z: 10;
	--modal-close-z: 9;
	--enroll-zindex: 3;
	--background: -1;
	--pagination-bullet-z: 1;
	--swiper-btn-z: 1;
	--loader: 9;
	--scroll-indicator: 1;
	--zindex-join:100;
	--z-fixture-pattern:-1;
	--zindex-shimmer:-1;
	--z-index-1-auction: 1;
	--z-index-0-auction: 0;
	--z-index-neg-auction: -1;
	--z-auction-logo:2;
	--z-auction-icon:1;
	--z-table-head: 5;
	--z-carousel-2:1;
	--z-carousel:2;
	--z-matchcenter-share:10;
	--z-statscard: 3;
	--z-confetti-image:10;
	--z-index-content: 2;
	--z-skippers-gradient-btn: 2;
	--z-skippers-community-gradient: 1;
	--z-skippers-community-thumbnail: 3;
	--z-membership-showcase-video: 2;
	--z-membership-showcase-image: 3;
	--z-transaction-button: 2;
	--z-index-selection-card: 10;
	--z-index-retention-btn: 10;
	// gradient
	--primary-light-gradient: 321 100% 11%;
	--primary-mid-gradient: 303 100% 12%;
	--primary-dark-gradient: 233 100% 8%;
	body.dark-mode {
		--primary-light-gradient: 327 74% 67%;
		--primary-mid-gradient: 205 100% 13%;
		--primary-dark-gradient: 205 100% 17%;
	}
	/* width variables*/
	// ratio variables
	// --ratio-1by1: 1;
	// --ratio-4by3: 0.75;
	// --ratio-16by9: 0.5625;
	--ratio-2by3: 1.5;
	// --ratio-9by16: 1.77778;
	// non color variables
	--base-font-size: 62.5%;
	--container-max-width: 100%;
	--content-width: var(--window-inner-width);
	--vh: 1vh;
	--vw: 1vw;
	--window-inner-height: calc(var(--vh, 1vh) * 100);
	--window-inner-width: calc(var(--vw, 1vw) * 100);
	// height varible
	// --header-height: 6rem;
	--header-height: 10rem;
	--theme-height: 6rem;
	--search-height: 8rem;
	--score-height: calc(5rem + env(safe-area-inset-bottom));
	--waf-head-height: 9.4rem;
	--footer-top-height: 38.2rem;
	--footer-middle-height: 5rem;
	--footer-bottom-height: 5rem;
	--footer-height: calc(var(--footer-middle-height) + var(--footer-bottom-height));
	--secondary-nav-height: 3.6rem;
	--input-height: 4rem;
	--filter-height: 3.2rem;
	/*space variable*/
	@for $i from 0 through 12 {
		--space-#{$i}: #{$base-space * $i};
		--space-#{$i}-neg: #{$base-space * $i * -1};
	}
	/* HOW TO USE
		--space-1 = 5px
		--space-2 = 10px.... till --space-12 = 60px
	  	this is according to base space variable 
	*/
}
$small-mobile-min-width: 0px;
$small-mobile-max-width: 360px;
$medium-mobile-min-width: 576px;
$mobile-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 991px;
$desktop-min-width: 992px;
$large-desktop-min-width: 1200px;
$xl-desktop-min-width: 1600px;
$font-primary-regular: "Romagna-Regular", sans-serif;
$font-primary-bold: "Romagna-Bold", sans-serif;
$font-secondary-regular: "Montserrat-Regular", sans-serif;
$font-secondary-bold: "Montserrat-Bold", sans-serif;
$font-medium: "Montserrat-Medium", sans-serif;
$font-default: Arial, Helvetica, sans-serif;
$font-icon: "waf-font-icon";
$image-version: "4.86";
$font-version: "46589655";
@media (min-width: $tablet-min-width) {
	:root {
		--container-max-width: 72rem;
		--header-social-height: 4.5rem;
		--top-header-height: 4.3rem;
		--header-top-height: 4rem;
		--header-bottom-height: 6rem;
		--header-height: calc(var(--header-top-height) + var(--header-bottom-height));
		--waf-head-height: 13rem;
		--secondary-nav-height: 3.6rem;
		--btn-buy-now: 3.2rem;
		// --footer-height: 5rem;
	}
}
@media (min-width: $desktop-min-width) {
	:root {
		--container-max-width: 96rem;
	}
}
@media (min-width: $large-desktop-min-width) {
	:root {
		--container-max-width: 114rem;
	}
}
@media (min-width: $xl-desktop-min-width) {
	:root {
		--container-max-width: 140rem;
	}
}