@use "./config" as *;


.waf-standings {
    // .title {
    //     margin: 0;
    //     @extend %uppercase;
    //     @extend %black-default;
    //     @extend %font-24-primary-b;
    // }

    // .waf-head {
    //     margin-bottom: var(--full-space);
    //     @extend %m-b-full;
    //     @extend %flex-v-center;

    // }

    .waf-footer {
        display: none;
    }

    .table {
        @extend %flex;

        &-left {
            flex-shrink: 0;
            @extend %w-50;
        }

        &-right {
            flex-shrink: 0;
            @extend %w-50;
        }

        &-data {
            flex-shrink: 0;
            @extend %flex-center;

            &.position {
                @extend %w-20;
            }

            &.team {
                @extend %w-80;
                @include flex-config(null, null, flex-start, null);

                p,
                .text {
                    text-align: left;
                }
            }
        }

        &-row {
            @extend %w-100;
            @extend %h-100;
            @extend %flex;

            &-wrap {
                flex-shrink: 0;
                width: inherit;
                height: inherit;
            }
        }
    }

    .team {
        &-image {
            display: none;
        }

        &-wrap {
            @extend %w-100;
            @extend %flex-v-center;
        }

        &-information {
            @extend %flex-v-center;
        }
    }

    .row-head {
        @extend %h-100;
        @extend %flex;
    }
}