@use "./config" as *;
.waf-scorestrip.widget-layout-07 {
  height: var(--score-height);
  margin: 0;
  width: var(--window-inner-width);
  position: fixed;
  left: 0;
  padding-bottom: env(safe-area-inset-bottom);
  bottom: 0;
  z-index: var(--score-strip-z);
  @extend %p-quater;
  @extend %black-dark-bg;
  &:empty {
    display: none;
  }
  .match-time,
  .card-footer-text {
    display: none;
  }
  .swiper-button {
    display: none;
  }
  .recent,
  .upcoming {
    .card-head {
      display: none;
    }
  }
  .live {
    .status {
      font-size: 0;
      @extend %flex-center;
      @extend %h-100;
      &::before {
        content: "";
        display: inline-block;
        width: 1.4rem;
        aspect-ratio: 1/1;
        background: url(/static-assets/images/svg/live.svg?v=#{$image-version}) center center no-repeat;
        background-size: contain;
        @extend %m-r-quater;
      }
    }
  }
  .score-strip-slider {
    margin: unset;
  }
  .waf-body {
    @extend %radius-half;
    @extend %black-default-bg;
    @extend %p-x-quater;
    @extend %flex-sb;
  }
  .score {
    @extend %font-14-primary-b;
    @extend %white-default-6;
  }
  .team-current {
    .score,
    .overs {
      @extend %white-default;
    }
  }
  .overs {
    @extend %font-9-primary-r;
    @extend %white-default-6;
    @extend %m-l-quater;
  }
  .info {
    &-section {
      position: relative;
      @extend %m-l-full;
      @include line();
      &::after {
        right: unset;
        left: var(--half-space-negative);
        @include bg(white-default, 5);
      }
      @extend %w-60;
    }
    &-title {
      @extend %font-12-primary-b;
      @extend %accent;
      @extend %uppercase;
    }
    &-body {
      overflow: hidden;
    }
    &-wrapper {
      width: max-content;
      transform: translateX(100%);
      @extend %flex;
      &:hover {
        animation-play-state: paused;
      }
    }
    &-text {
      display: block;
      width: max-content;
      position: relative;
      flex-shrink: 0;
      @extend %font-12-primary-r;
      @extend %white-default;
      @extend %m-r-full;
      @include line();
      &:first-child::after {
        display: none;
      }
      &::after {
        left: var(--half-space-negative);
        @include bg(white-default, 5);
      }
    }
  }
  .team {
    @extend %flex-v-center;
    &-name {
      @extend %font-12-primary-b;
      @extend %accent;
      @extend %uppercase;
    }
    &-data {
      @extend %flex-v-center;
    }
    &-score {
      @extend %flex;
    }
    &-info {
      margin-right: var(--full-space);
    }
  }
  .card {
    &-item {
      position: relative;
      @extend %flex;
    }
    &-footer {
      @extend %flex-n-c;
      &-text {
        @extend %font-12-primary-r;
        @extend %white-default;
        @include truncate-vertical-line(12, 2);
        height: unset;
        max-height: 2.8rem;
        display: none;
      }
    }
  }
  .time-text {
    padding-inline: var(--half-space);
    @extend %font-14-primary-r;
    @extend %white-default-5;
  }
  .btn-more {
    display: none;
    @extend %w-100;
    @extend %h-100;
    @extend %position-top-right;
    .btn-text {
      font-size: 0;
    }
    &.btn-scorecard {
      display: block;
    }
  }
  /* media query to enable animation for only those who want it */
  // @media (prefers-reduced-motion: no-preference) {
  .info-wrapper {
    width: 100%;
    animation: marquee linear 30s forwards infinite;
    @for $i from 6 through 10 {
      &.child-#{$i} {
        animation-duration: 20s;
      }
    }
    @for $i from 11 through 20 {
      &.child-#{$i} {
        animation-duration: 10s;
      }
    }
  }
  // }
}
.waf-row-scorestrip {
  .row > div {
    padding: 0;
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-scorestrip.widget-layout-07 {
    bottom: unset;
    top: var(--header-height);
    @include bg(black-default);
    .waf-body {
      padding: unset;
      background-color: transparent;
      height: calc(var(--score-height) - var(--half-space));
    }
    .layout-wrapper {
      height: 100%;
    }
    .score-strip-slider {
      width: 60%;
    }
    .info-section {
      width: 40%;
      margin-left: var(--half-space);
      &::after {
        display: none;
      }
    }
    .match-time,
    .card-footer {
      @include flex();
    }
    .team-wrapper {
      @include flex-config(flex, null, space-around, null);
    }
    .card {
      &-item {
        padding-inline: var(--half-space);
        @include border-radius(var(--half-radius));
        @include bg(black-light);
      }
      &-list,
      &-item {
        height: 100%;
      }
      &-content {
        position: relative;
        width: calc(65% - var(--full-space));
        margin-right: var(--two-space);
        @include line();
        &::after {
          right: var(--full-space-negative);
          @include bg(white-default, 1);
        }
      }
      &-footer {
        width: calc(35% - var(--full-space));
        &-text {
          display: block;
        }
      }
    }
    .overs {
      @include font-style(14);
    }
    .score {
      @include font-style(20);
      &.no-score {
        @include font-style(12);
      }
    }
    .card-content,
    .match-time-wrap,
    .team-extra {
      @include flex-config(flex, null, null, center);
    }
    .team {
      &-name {
        @include font-style(18);
      }
      &-info {
        margin: unset;
      }
      &-a .team-info {
        margin-right: var(--quater-space);
      }
      &-b {
        @include flex-config(null, row-reverse, null, null);
        .team-info {
          margin-left: var(--quater-space);
        }
      }
    }
  }
  .dark-mode {
    .waf-scorestrip.widget-layout-07 {
      @include bg(extra-dark);
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-scorestrip.widget-layout-07 {
    padding-inline: var(--full-space);
    .card-item {
      padding-inline: var(--full-space);
    }
    .time-text {
      margin-inline: var(--full-space);
    }
    .score-strip-slider {
      width: 40%;
    }
    .info-section {
      width: calc(60% - var(--half-space));
    }
    .info-text::after {
      @include bg(white-default, 1);
    }
    .team-detail,
    .team-wrapper {
      width: 100%;
    }
  }
}