@use "./config" as *;
.site-app {
	> div {
		@extend %w-100;
		@extend %h-100;
		@extend %flex;
	}
}
.waf-membership .waf-app-banner {
	@extend %w-100;
	@extend %m-x-zero;
	@extend %m-b-two;
	.app-banner-wrap {
		// aspect-ratio: 16/8;
		// width: 60rem;
		aspect-ratio: 3.8/3;
		margin: 0 auto;
		padding-inline: 10%;
		@extend %w-100;
	}
}
.waf-app-banner {
	width: calc(100% + var(--full-space));
	padding: 0;
	margin: 0;
}
.app-banner-wrap {
	aspect-ratio: 16/8.3;
	position: relative;
	background: url("/static-assets/images/banner/mobile/download-app-banner.png?v=#{$image-version}") bottom/contain no-repeat;
	@extend %m-x-half-neg;
}
.btn-wrap {
	gap: var(--half-space);
	@extend %flex;
	@include position(null, null, 2rem, 5%);
	.store {
		margin-bottom: var(--half-space);
	}
}
.btn-image {
	height: auto;
	width: 7.5rem;
}
.store-link {
	display: block;
	line-height: 0;
}
@media screen and (min-width: $tablet-min-width) {
	.waf-app-banner {
		margin-inline: 0;
	}
	.app-banner-wrap {
		aspect-ratio: 16/4;
		background: url("/static-assets/images/banner/download-app-banner.png?v=#{$image-version}") bottom/contain no-repeat;
	}
	.btn-wrap {
		@include flex();
		left: 6%;
		bottom: 5.5rem;
	}
	.btn-image {
		width: 18rem;
	}
	.store {
		margin-right: var(--full-space);
	}
	.waf-membership .waf-app-banner {
		width: var(--container-max-width);
		margin: 0 auto var(--full-space);
		.btn-wrap {
			left: 28.5%;
			bottom: 1rem;
		}
		.btn-image {
			width: 14rem;
		}
		.app-banner-wrap {
			background: url(/static-assets/images/cssimages/banner/app-light-banner-d.png?v=#{$image-version}) bottom;
			background-repeat: no-repeat;
			background-size: 100%;
			width: 100%;
			aspect-ratio: 16/8;
		}
	}
}
@media screen and (min-width: $desktop-min-width) {
	.waf-membership {
		.waf-app-banner {
			padding: 0 15%;
			.app-banner-wrap {
				aspect-ratio: unset;
				height: 30rem;
			}
		}
	}
	.waf-app-banner {
		padding-left: var(--full-space);
	}
	.btn-wrap {
		@include flex();
		left: 6%;
		bottom: 3.5rem;
	}
	.btn-image {
		width: 12rem;
	}
}
@media screen and (min-width: $xl-desktop-min-width) {
	.waf-app-banner {
		.btn-wrap {
			left: 9rem;
			bottom: 4.6rem;
		}
	}
}