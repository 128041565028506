@use "variables" as *;
@use "mixins" as *;
@use "map" as *;
@use "function" as *;
@use "animation" as *;
// how to use 
// it will give you font sizses from 1 to 60;
// @extend %font-9-primary-r
// @extend %font-9-primary-b
// @extend %font-9-secondary-r
// @extend %font-9-secondary-b
// @extend %font-9-m
@for $font-size from 1 through 60 {
	$font-size-rem: #{calc($font-size * 0.1rem)};
	$line-height-rem: #{calc(($font-size * 0.1rem) + 0.6rem)};
	%font-#{$font-size}-primary-r {
		font: $font-size-rem / $line-height-rem $font-primary-regular;
	}
	%font-#{$font-size}-primary-b {
		font: $font-size-rem / $line-height-rem $font-primary-bold;
	}
	%font-#{$font-size}-secondary-r {
		font: $font-size-rem / $line-height-rem $font-secondary-regular;
	}
	%font-#{$font-size}-secondary-b {
		font: $font-size-rem / $line-height-rem $font-secondary-bold;
	}
	%font-#{$font-size}-m {
		font: $font-size-rem / $line-height-rem $font-medium;
	}
}
%font-0 {
	font-size: 0;
}
// font sizes end
%c-inherit {
	color: inherit;
}
@each $alpha-color in $colors {
	@for $i from 1 through 9 {
		%#{$alpha-color}-#{$i} {
			color: hsl(var(--color-hsl-#{$alpha-color}) / #{calc($i / 10)});
		}
		%#{$alpha-color}-bg-#{$i} {
			background-color: hsl(var(--color-hsl-#{$alpha-color}) / #{calc($i / 10)});
		}
	}
	%#{$alpha-color} {
		color: var(--color-#{$alpha-color});
	}
	%#{$alpha-color}-bg {
		background-color: var(--color-#{$alpha-color});
	}
}
%transparent-bg {
	background-color: transparent;
}
// you can select change the value from 1 to 10
// 1 means 0.1 opacity , 10 means no opacity solidcolor
// @extend %button-primary-1;
// @extend %button-primary;
// @extend %button-primary-bg-1;
// @extend %button-primary-bg;
// flex start
%d-block {
	display: block;
}
%d-none {
	display: none;
}
%flex {
	@include flex-config(flex, null, null, null);
}
%flex-sb {
	@include flex-config(flex, null, space-between, null);
}
%flex-v-center {
	@include flex-config(flex, null, null, center);
}
%flex-h-center {
	@include flex-config(flex, null, center, null);
}
%flex-center {
	@include flex-config(flex, null, center, center);
}
%flex-column-center {
	@include flex-config(flex, column, center, center);
}
%flex-column-h-center {
	@include flex-config(flex, column, null, center);
}
%flex-column-v-center {
	@include flex-config(flex, column, center, null);
}
%flex-column {
	@include flex-config(flex, column, null, null);
}
%flex-wrap {
	flex-wrap: wrap;
}
// flex end
// absolute start
%absolute {
	position: absolute;
}
%relative {
	position: relative;
}
%position-v-center {
	@include center(vertical);
}
%position-h-center {
	@include center(horizontal);
}
%position-center {
	@include center(both);
}
%position-top-left {
	@include position(0, null, null, 0);
}
%position-top-right {
	@include position(0, 0, null, null);
}
%position-bottom-left {
	@include position(null, null, 0, 0);
}
%position-bottom-right {
	@include position(null, 0, 0, null);
}
// absolute end
// new absolute start
%position-v-center {
	@include position-combo(y-center);
}
%position-h-center {
	@include position-combo(x-center);
}
%position-center {
	@include position-combo(center);
}
%position-t-l {
	@include position(0, null, null, 0);
}
%position-t-r {
	@include position(0, 0, null, null);
}
%position-b-l {
	@include position(null, null, 0, 0);
}
%position-b-r {
	@include position(null, 0, 0, null);
}
%circle {
	@include border-radius(50%);
}
// %m-full {
// 	margin: var(--full-space);
// }
// %m-half {
// 	margin: var(--half-space);
// }
// %m-quater {
// 	margin: var(--quater-space);
// }
// %p-full {
// 	padding: var(--full-space);
// }
// %p-half {
// 	padding: var(--half-space);
// }
// %p-quater {
// 	padding: var(--quater-space);
// }
%gap-full {
	gap: var(--full-space);
}
%gap-half {
	gap: var(--half-space);
}
%gap-quater {
	gap: var(--quater-space);
}
%gap-three-fourth {
	gap: var(--three-fourth-space);
}
// radius
%radius-full {
	border-radius: var(--full-radius);
}
%radius-half {
	border-radius: var(--half-radius);
}
// radius
%full-radius {
	@include border-radius(var(--full-radius));
}
%half-radius {
	@include border-radius(var(--half-radius));
}
%circle-radius {
	@include border-radius(50%);
}
%rounded-radius {
	@include border-radius(50vmax);
}
@each $direction,
$direction-value in $directions {
	@each $space,
	$space-value in $spacing {
		%m-#{$direction}-#{$space} {
			margin-#{$direction-value}: #{$space-value};
		}
		%p-#{$direction}-#{$space} {
			padding-#{$direction-value}: #{$space-value};
		}
	}
}
//how to use
// @extend %m-t-full
// @extend %p-r-half
// @extend %m-b-full-neg
// @extend %p-l-half-neg
// @extend %m-l-auto
@each $direction,
$direction-value in $new-directions {
	@each $space, $space-value in $new-spacing {
		%m-#{$direction}-#{$space} {
			margin#{$direction-value}: #{$space-value};
		}
		%p-#{$direction}-#{$space} {
			padding#{$direction-value}: #{$space-value};
		}
	}
}
//how to use
// @extend %m-t-full
// @extend %p-r-half
// @extend %m-b-full-neg
// @extend %p-l-half-neg
// @extend %m-l-auto
// @extend %m-x-full : margin-inline
// @extend %m-y-full : margin-block
@each $space,
$space-value in $new-spacing {
	%m-#{$space} {
		margin: #{$space-value};
	}
	%p-#{$space} {
		padding: #{$space-value};
	}
}
//how to use
// @extend %m-full
// @extend %p-half
// @extend %m-full-neg
// @extend %p-half-neg
@for $i from 1 through 10 {
	%h-#{calc($i * 10)} {
		height: calc($i * 10%);
	}
	%w-#{calc($i * 10)} {
		width: calc($i * 10%);
	}
}
@each $direction, $direction-value in $new-directions {
	@for $space from 0 through $max-space-count {
		%m#{$direction}-#{$space} {
			margin#{$direction-value}: var(--space-#{$space});
		}
		%p#{$direction}-#{$space} {
			padding#{$direction-value}: var(--space-#{$space});
		}
		%m#{$direction}-#{$space}-neg {
			margin#{$direction-value}: var(--space-#{$space}-neg);
		}
		%p#{$direction}-#{$space}-neg {
			padding#{$direction-value}: var(--space-#{$space}-neg);
		}
	}
	%m#{$direction}-auto {
		margin#{$direction-value}: auto;
	}
	%p#{$direction}-auto {
		padding#{$direction-value}: auto;
	}
}
@for $space from 0 through $max-space-count {
	%m-#{$space} {
		margin: var(--space-#{$space});
	}
	%p-#{$space} {
		padding: var(--space-#{$space});
	}
	%m-#{$space}-neg {
		margin: var(--space-#{$space}-neg);
	}
	%p-#{$space}-neg {
		padding: var(--space-#{$space}-neg);
	}
}
@for $i from 0 through $max-space-count {
	@for $j from 0 through $max-space-count {
		%m-#{$i}-#{$j} {
			margin: var(--space-#{$i}) var(--space-#{$j});
		}
		%p-#{$i}-#{$j} {
			padding: var(--space-#{$i}) var(--space-#{$j});
		}
	}
}
%uppercase {
	text-transform: uppercase;
}
%lowercase {
	text-transform: lowercase;
}
%capitalize {
	text-transform: capitalize;
}
%button-common {
	// margin: var(--full-space) 0;
	width: max-content;
	height: max-content;
	border: 0.1rem solid;
	padding: var(--half-space) var(--full-space);
	@include border-radius(var(--half-radius));
	.btn-text {
		@extend %font-12-secondary-b;
	}
}
%btn-outline {
	@extend %button-common;
	border-color: hsl(var(--color-hsl-button-primary));
	background-color: transparent;
	.btn-text {
		@extend %button-primary;
	}
}
%btn-fill {
	@extend %button-common;
	@extend %button-primary-bg;
	border-color: transparent;
	.btn-text {
		@extend %white-default;
	}
}
%btn-fill-anchor {
	@extend %button-common;
	@extend %btn-fill;
	@extend %white-default;
	@extend %font-12-secondary-b;
}
%img-shade {
	.img-box {
		position: relative;
		&::after {
			content: "";
			pointer-events: none;
			background: linear-gradient(180deg, clr(black-dark, 0) 43.78%, clr(black-dark, 8.5) 65.73%);
			@extend %w-100;
			@extend %h-100;
			@extend %position-bottom-left;
		}
	}
}
%vertical-card {
	.article {
		&-item {
			flex-shrink: 0;
			height: max-content;
			@extend %white-default-bg;
			@include border-radius(var(--half-radius));
			.item-type-icon ~ a {
				flex-shrink: 0;
				@extend %w-100;
			}
		}
		&-content {
			flex-wrap: wrap;
			@extend %p-half;
			@extend %flex;
			@include flex-config(flex, null, space-between, null);
		}
		&-title {
			margin-top: 0;
			@extend %black-default;
			@extend %font-20-primary-b;
			@extend %m-b-half;
			@include truncate-vertical-line(20, 2);
		}
	}
}
%vertical-video-card {
	.article {
		&-item {
			position: relative;
			flex-shrink: 0;
			height: max-content;
			@extend %white-default-bg;
			@include card-count(1.1, var(--half-space));
			@include border-radius(var(--half-radius), hidden);
		}
		&-content {
			flex-wrap: wrap;
			@extend %p-half;
			@extend %flex;
			@include flex-config(flex, null, space-between, null);
			@include position(null, null, 0, 0);
		}
		&-title {
			margin-top: 0;
			@extend %white-default;
			@extend %font-24-primary-b;
			@extend %m-b-full;
			@extend %w-80;
			@include truncate-vertical-line(24, 3);
		}
	}
	.item-type-video {
		.article-content {
			.item-type-icon {
				position: relative;
				width: 3.2rem;
				height: 3.2rem;
				margin-bottom: 0;
				// @extend %primary-bg;
				@extend %flex-n-n;
				&::after {
					content: "\e80f";
					font-size: 2rem;
					font-family: $font-icon;
					@extend %white-default;
				}
				& ~ a {
					flex-shrink: 0;
					@extend %w-100;
				}
			}
		}
	}
	.meta {
		@extend %white-default-7;
	}
	.reaction-section {
		.icon-b-share {
			&::after {
				@extend %white-default-7;
			}
		}
	}
	@extend %img-shade;
}
%horizontal-card {
	.article {
		&-item {
			flex-shrink: 0;
			height: max-content;
			@extend %white-default-bg;
			@include card-count(1, var(--half-space));
			@include border-radius(var(--half-radius), hidden);
			&:not(:last-child) {
				@extend %m-b-full;
			}
			.item-type-icon ~ a {
				flex-shrink: 0;
				@extend %w-100;
			}
		}
		&-wrap {
			@extend %flex;
		}
		&-content {
			flex-wrap: wrap;
			width: calc(100% - 13.2rem);
			@extend %p-half;
			@extend %flex;
			@include flex-config(flex, null, space-between, null);
		}
		&-title {
			margin-top: 0;
			@extend %black-default;
			@extend %font-16-primary-b;
			@extend %m-b-half;
			@include truncate-vertical-line(16, 3);
		}
		&-thumbnail {
			width: 12.5rem;
		}
	}
}
%title-gradient {
	.waf-head,
	.article-head,
	.about-us {
		.title {
			background: linear-gradient(90deg,
					hsl(var(--color-hsl-primary-dark)) 0.93%,
					hsl(var(--color-hsl-secondary-dark)) 100%);
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
			text-align: center;
			position: relative;
			@extend %m-b-full;
			@extend %font-28-primary-b;
			&::after {
				height: 0.5rem;
				@include position(null, null, -1.2rem, 0);
			}
			&::before,
			&::after {
				content: "";
				width: 100%;
				background: linear-gradient(90deg,
						hsl(var(--color-hsl-primary-dark)) 0.93%,
						hsl(var(--color-hsl-secondary-dark)) 100%);
			}
			&::before {
				height: 0.2rem;
				@include position(null, null, -1.5rem, 0);
			}
		}
	}
}
.dark-mode {
	%title-gradient {
		.waf-head,
		.article-head,
		.about-us {
			.title {
				background: var(--color-white-default);
				-webkit-text-fill-color: transparent;
				-webkit-background-clip: text;
				&::before,
				&::after {
					background: var(--color-white-default);
				}
			}
		}
	}
}
%white-title-gradient {
	.waf-head {
		.title {
			position: relative;
			width: max-content;
			background: var(--color-white-default);
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
			margin-bottom: var(--two-space);
			@extend %font-28-primary-b;
			&::before,
			&::after {
				content: "";
				width: 100%;
				background: var(--color-white-default);
			}
			&::after {
				height: 0.5rem;
				@include position(null, null, -1.2rem, 0);
			}
			&::before {
				height: 0.2rem;
				@include position(null, null, -1.5rem, 0);
			}
		}
	}
}
%gradient-bg {
	@include linear-gradient(270deg,
		hsl(var(--color-hsl-primary)) 0%,
		hsl(var(--color-hsl-secondary)) 121.25%);
}
.dark-mode {
	%gradient-bg {
		@include linear-gradient(270deg,
			hsl(var(--color-hsl-accent)) 0%,
			hsl(var(--color-hsl-secondary)) 121.25%);
	}
}
@each $justify,
$justify-value in $flex-spacing {
	@each $align,
	$align-value in $flex-spacing {
		%flex#{$justify}#{$align} {
			@include flex-config(flex, null, #{$justify-value}, #{$align-value});
		}
		%flex-row#{$justify}#{$align} {
			@include flex-config(flex, row, #{$justify-value}, #{$align-value});
		}
		%flex-column#{$justify}#{$align} {
			@include flex-config(flex, column, #{$justify-value}, #{$align-value});
		}
	}
}
%flex {
	@include flex-config(flex);
}
%flex-row {
	@include flex-config(flex, row);
}
%flex-column {
	@include flex-config(flex, column);
}
// shortform
// 	sa: space-around,
// 	sb: space-between,
// 	se: space-evenly,
// 	fe: flex-end,
// 	fs: flex-start,
// 	c:center,
// 	s:stretch,
// 	u:unset,
// 	n:null
//how to use
// @extend %flex;
// @extend %flex-column;
// @extend %flex-null-c;
// @extend %flex-sb-c;
// @extend %flex-c-fs;
// @extend %flex-column-fs-c;
// @extend %flex-column-c-sb;
%x-scroll {
	overflow-x: scroll;
}
%x-auto {
	overflow-x: auto;
}
%y-scroll {
	overflow-y: scroll;
}
%y-auto {
	overflow-y: auto;
}
%hidden {
	overflow: hidden;
}
// text alignment
%text-center {
	text-align: center;
}
%text-left {
	text-align: left;
}
%text-right {
	text-align: right;
}
@each $width,
$height in $aspect-ratio {
	%ratio-#{$width}-#{$height} {
		aspect-ratio: #{$width}/#{$height};
	}
}
%v-line {
	position: relative;
	@extend %m-r-full;
	&::after {
		content: "";
		width: 0.1rem;
		height: 2rem;
		right: var(--half-space-negative);
		@extend %white-default-bg;
		@extend %position-v-center;
	}
}
%h-line {
	position: relative;
	&::after {
		content: "";
		width: 100%;
		height: 0.1rem;
		@extend %white-default-bg-3;
	}
}