@use "./config" as *;
.waf-newsletter,
.waf-download-banner {
    margin: 0;
    padding: 0;
    .errordiv {
        @extend %flex-column;
        gap: var(--half-space) 0;
        position: relative;
        display: block;
        padding: var(--half-space);
        border-left: 0.3rem solid var(--color-error-text);
        @extend %font-14-primary-r;
        @extend %error-text;
        @extend %m-b-half;
        position: relative;
        isolation: isolate;
        &::after,
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            @extend %position-center;
        }
        &::before {
            z-index: -2;
            @extend %white-default-bg;
        }
        &::after {
            z-index: -1;
            @extend %error-text-bg-1;
        }
        &:empty {
            display: none;
        }
    }
    .form-element {
        padding: 0;
        background-color: transparent;
    }
    .form-control {
        &:placeholder-shown {
            ~ .control-label {
                color: hsl(var(--color-hsl-white-default) / 0.5);
            }
        }
        &:not(:placeholder-shown) {
            padding-left: var(--half-space);
            & ~ .control-label {
                @extend %white-default;
            }
        }
    }
    .layout-wrapper {
        margin-top: 14rem;
        @extend %radius-half;
        @extend %p-half;
        @extend %secondary-dark-bg;
    }
    .title {
        text-align: center;
        @extend %white-default;
        @extend %font-18-primary-b;
    }
    .content {
        text-align: center;
        margin-bottom: var(--two-space);
        @extend %white-default-7;
        @extend %font-12-secondary-r;
    }
    .form-element {
        position: relative;
        @extend %m-b-half;
    }
    .btn-fill {
        @extend %w-100;
    }
    .newsletter-thumb {
        position: relative;
        &::after {
            content: '';
            pointer-events: none;
            @extend %w-100;
            @extend %h-100;
            @extend %position-bottom-left;
            @include linear-gradient(0deg, hsl(var(--color-hsl-secondary-dark)/1) 0%, transparent 100%);
        }
        .icon {
            margin-top: -14rem;
        }
    }
    .placeholder {
        text-align: center;
        pointer-events: none;
        @extend %position-center;
        @extend %white-default-7;
        @extend %font-12-m;
        @extend %w-100;
        &::before {
            content: '\e80c';
            font-family: $font-icon;
            @extend %m-r-half;
            @extend %white-default-7;
        }
    }
    .form-control {
        height: 4rem;
        width: 100%;
        background: transparent;
        border: 0.1rem solid var(--color-white-default);
        @extend %font-12-m;
        @extend %white-default;
        @include border-radius(var(--half-radius));
        &:focus ~ span {
            display: none;
        }
    }
    .global-msg {
        @extend %white-default-bg;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-newsletter,
    .waf-download-banner {
        margin: 0 var(--half-space-negative);
        .layout-wrapper {
            margin: 4rem 0 0;
        }
        .newsletter-wrapper {
            display: flex;
        }
        .newsletter-content {
            @include flex-config(flex, null, null, center);
            display: flex;
        }
        .title {
            text-align: left;
            @include font(22);
        }
        .content {
            text-align: left;
            &-wrap {
                padding: 0 var(--full-space);
            }
        }
        .newsletter-thumb {
            .icon {
                margin-top: 0rem;
            }
        }
    }
}