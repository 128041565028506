@use "./config" as *;

.dark-mode .waf-showcase {
    background-image: linear-gradient(180deg, clr(primary-dark) -16.97%, clr(primary-dark) 109.7%);
    .swiper-button-next,
    .swiper-button-prev {
        border: 0;
    }
    .swiper-pagination {
        --swiper-pagination-color: #{clr(button-primary)};
        --swiper-pagination-bullet-inactive-color: #{clr(white-default,6)};
    }
}
.waf-showcase {
    padding: 0;
    background-image: linear-gradient(180deg, clr(primary-dark) -16.97%, clr(secondary-dark) 109.7%);
    margin: 0 var(--half-space-negative);
    .swiper {
        --swiper-pagination-bottom: 2rem;
        padding: var(--full-space) 5rem 5rem;
    }
    .img-box {
        padding-bottom: 0;
        position: relative;
        aspect-ratio: 2/3;
        @include border-radius(unset);
        @extend %flex-v-center;
        @extend %black-light-bg;
        &::after {
            content: '';
            pointer-events: none;
            @extend %w-100;
            @extend %h-100;
            @extend %position-bottom-left;
            @include linear-gradient(0deg, hsl(var(--color-hsl-black-dark)/0.7) 20%, transparent 50%, transparent 100%);
        }
        img {
            object-fit: contain;
            height: 100%;
        }
    }
    .no-content {
        .img-box::after {
            display: none
        }
    }
    .article {
        &-list {
            @extend %flex;
        }
        &-item {
            flex-shrink: 0;
            box-shadow: 0rem 0rem 6rem 0rem clr(black-dark, 3);
            @extend %half-radius;
            @extend %hidden;
            @extend %w-100;
        }
        &-wrap {
            position: relative;
        }
        &-content {
            pointer-events: none;
            @extend %flex-column-fe-n;
            height: 18rem;
            padding: 0 var(--full-space);
            @extend %w-100;
            @extend %position-bottom-left;
        }
        &-title {
            @extend %font-26-primary-b;
            @extend %white-default;
            @include truncate-vertical-line(26, 3);
        }
    }
    .swiper {
        &-button {
            &-next,
            &-prev {
                @extend %d-none;
                top: unset;
                @extend %black-light-bg-8;
                &.swiper-button-disabled {
                    @extend %black-light-bg-6;
                    &::after {
                        @extend %white-default-6;
                    }
                }
            }
            &-next {
                @include position(null, null, var(--full-space), 7rem);
            }
            &-prev {
                @include position(null, null, var(--full-space), var(--full-space));
            }
        }
        &-pagination {
            --swiper-pagination-color: #{clr(primary)};
            --swiper-pagination-bullet-inactive-color: #{clr(white-default,6)};
            &-bullet {
                transition: 300ms width, 300ms border-radius;
                &-active {
                    width: 3rem;
                    @extend %rounded-radius;
                }
            }
        }
    }
    .preview-swiper {
        position: relative;
    }
}
@media (min-width: $tablet-min-width) {
    .scorestrip-active {
        .waf-showcase .swiper {
            padding-top: 6rem;
        }
    }
    .waf-showcase {
        background-image: linear-gradient(180deg, clr(primary-dark) -16.97%, clr(secondary-dark) 109.7%);
        .article {
            &-title {
                width: 100%;
                @include font(30);
                @include truncate-vertical-line(30, 2);
            }
            &-content {
                text-align: center;
                padding-inline: 10rem;
                padding-bottom: 5rem;
                height: 15rem;
            }
        }
        .swiper {
            padding: 2rem 16.5%;
            &-button {
                &-prev,
                &-next {
                    cursor: pointer;
                    display: flex;
                    left: unset;
                    width: 2.9rem;
                    height: 2.9rem;
                    bottom: var(--two-space);
                }
                &-prev {
                    right: calc(16.5% + var(--half-space) + 8rem);
                }
                &-next {
                    right: calc(16.5% + var(--half-space) + 4rem);
                }
            }
            &-pagination {
                display: none;
            }
        }
        .img-box {
            aspect-ratio: 16/9;
            &::after {
                @include linear-gradient(0deg, clr(black-dark, 10) 0%, clr(black-dark, 5) 50%, transparent 100%);
            }
            img {
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .dark-mode .waf-showcase {
        // @include background("cssimages/homepage/showcase-bg.png",  center center);
        background-image: linear-gradient(180deg, clr(primary-dark) -16.97%, clr(primary-dark) 109.7%);
        background-size: cover;
    }
}
@media (min-width: $desktop-min-width) {
    .waf-showcase {
        .article {
            &-title {
                @include font(30);
                @include truncate-vertical-line(30, 2);
            }
            &-content {
                height: 21rem;
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            bottom: var(--two-space);
        }
    }
}