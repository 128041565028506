@use "../config" as *;
.header-wrap {
  position: sticky;
  top: 0;
  z-index: var(--header-z);
}
.site-header-wrapper {
  height: var(--header-height);
  width: var(--window-inner-width);
  z-index: var(--header-z);
  // position: relative;
  position: relative;
  @include shadow(0 0 1.6rem -0.2rem hsla(var(--color-hsl-black-default) / 0.6));
  @extend %flex;
  @extend %primary-bg;
}
// body {
//   padding: 0 0 env(safe-area-inset-bottom);
// }
.site-logo {
  width: 10rem;
  height: 10rem;
  position: relative;
  @extend %p-half;
  @extend %flex;
  @extend %h-100;
  @extend %m-r-auto;
  &::after {
    content: "";
    width: 0.1rem;
    right: 0;
    @extend %h-100;
    @extend %white-default-bg-1;
    @extend %position-v-center;
  }
  .logo {
    display: block;
    background: url("/static-assets/images/svg/logo.svg?v=#{$image-version}") center no-repeat;
    background-size: contain;
    @extend %w-100;
    @extend %h-100;
    &-text {
      font-size: 0;
    }
  }
}
.nav-actions-wrap {
  width: 75%;
  @include flex-config(flex, column, space-between, null);
}
.login-menu {
  // @extend %flex-v-center;
  @include flex-config(flex, null, flex-end, center);
}
.extra-promo-module {
  @extend %w-100;
  .promo {
    &-list {
      @extend %primary-dark-bg;
      // @extend %flex-v-center;
      @include flex-config(flex, null, flex-end, center);
    }
    &-link {
      position: relative;
      padding-inline: var(--three-fourth-space);
      padding-left: var(--half-space);
      width: auto;
      &:not(:last-child)::after {
        content: "";
        width: 0.2rem;
        right: 0;
        @extend %h-80;
        @extend %white-default-bg-3;
        @extend %position-v-center;
      }
      &:last-child {
        @extend %p-r-half;
      }
      .promo-anchor {
        position: relative;
        gap: var(--quater-space);
        @include flex-config(flex, row, space-between, center);
        @extend %h-100;
        &::before {
          content: "";
          font-family: $font-icon;
          font-size: 1.7rem;
          display: inline-block;
          @extend %white-default;
        }
      }
      &-icon {
        .promo-anchor {
          &.promo-anchor-membership::before {
            content: "\e83b";
          }
          &.promo-anchor-shop::before {
            content: "\e83a";
          }
          &.promo-anchor-auction::before {
            content: "\e87e";
          }
        }
      }
    }
    &-text {
      @extend %font-16-primary-b;
      @extend %white-default;
      @extend %uppercase;
    }
  }
}
.login-menu,
.nav-promo-list {
  @extend %w-100;
  @extend %h-100;
}
.btn-menu {
  @extend %m-x-half;
  @extend %h-100;
  @extend %flex-center;
  &[aria-expanded="true"] {
    .temp-user {
      display: none;
    }
    ~ .list-wrap {
      @include dropdown(open);
    }
    .btn-text,
    .user-photo {
      display: none;
    }
    .close-icon::after,
    .icon::after {
      content: "\e812";
      margin-bottom: 0;
      font-size: 2rem;
      margin-bottom: 0;
    }
  }
  .btn-text {
    @extend %font-14-primary-b;
    @extend %white-default;
    @extend %uppercase;
  }
}
.btn-lang,
.btn-login {
  .close-icon::after,
  .icon::after {
    font-family: $font-icon;
    font-size: 1.7rem;
    display: inline-block;
    height: 2rem;
    @extend %white-default;
    @include transition(font-size 100ms ease-in-out);
  }
}
.btn-login {
  @extend %gap-quater;
  .icon::after {
    content: "\e83c";
  }
}
.btn-lang {
  flex-direction: column;
  justify-content: space-around;
  @extend %m-auto;
  .btn-text {
    @include font(12, $font-primary-regular);
    @extend %capitalize;
  }
  .icon::after {
    content: "\e802";
  }
}
.list-wrap {
  width: var(--window-inner-width);
  top: 100%;
  z-index: var(--list);
  @include dropdown(close);
  @extend %gap-full;
  @extend %flex-h-center;
  @extend %position-h-center;
  @extend %p-full;
  @extend %black-default-bg;
  .btn-site {
    display: inline-block;
    width: 12rem;
    text-align: center;
    @include border(all, 1, white-default);
    @include border-radius(var(--half-radius));
    @extend %p-half;
    .btn-text {
      @extend %uppercase;
      @extend %font-12-primary-b;
      @extend %white-default;
    }
    &.active {
      border: unset;
      @extend %accent-bg;
      .btn-text {
        @extend %white-default;
      }
    }
  }
}
.site-login,
.lang-section,
.login-section {
  @extend %h-100;
}
.user-photo {
  width: 1.7rem;
  height: 1.7rem;
  flex-shrink: 0;
  aspect-ratio: 1/1;
  margin-bottom: 0;
  @include border-radius(50%, hidden);
}
.temp-user {
  width: 2rem;
  // aspect-ratio: 1/1;
  letter-spacing: 0.1rem;
  flex-shrink: 0;
  @include border-radius(50%, hidden);
  @extend %capitalize;
  @extend %flex-center;
  @extend %font-13-primary-b;
  @extend %white-default;
  @extend %secondary-light-bg;
  // @extend %m-b-quater;
}
// hamburger animation
.btn-hamburger {
  .line {
    fill: none;
    stroke: var(--color-white-default);
    stroke-width: 4;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    &:first-child {
      stroke-dasharray: 60 207;
    }
    &:nth-child(2) {
      stroke-dasharray: 60 60;
    }
    &:last-child {
      stroke-dasharray: 60 207;
    }
  }
  &[aria-expanded="true"] {
    .line {
      stroke-width: 5;
      &:first-child {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
      }
      &:nth-child(2) {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
      }
      &:last-child {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
      }
    }
    ~ .site-nav {
      right: 0;
    }
  }
}
//navigation menu css
.site-nav {
  width: var(--window-inner-width);
  height: calc(var(--window-inner-height) - var(--header-height));
  @include transition(right 500ms ease-in-out);
  @include position(var(--header-height), -100%, null, null, fixed);
}
.menu-section {
  @extend %h-100;
  @include flex-config(flex, column, null, null);
  @include linear-gradient(0deg,
    var(--color-primary-dark) 20%,
    var(--color-primary) 100%);
  &-top {
    position: relative;
    @extend %h-100;
  }
  &-bottom {
    height: var(--theme-height);
    @extend %flex;
    @extend %w-100;
  }
}
.dark-mode {
  .site-header-wrapper {
    @extend %black-light-bg;
  }
  .login-menu {
    @extend %black-light-bg;
  }
  .menu-section {
    @include linear-gradient(0deg,
      var(--color-primary) 40%,
      var(--color-primary-light) 100%);
  }
  .list-wrap {
    @extend %extra-dark-bg;
    .btn-site.active {
      @extend %button-primary-bg;
    }
  }
  .theme-text {
    font-size: 0;
    &::before {
      font-size: 1.4rem;
      content: 'Light Mode'
    }
  }
  .temp-user {
    // @extend %white-default-bg;
    @include color(black-dark);
    @include bg(white-default);
    // @extend %black-dark;
  }
  .promo-list,
  .promo-section {
    @include bg(primary);
  }
}
.primary-nav,
.secondary-nav {
  &-link {
    &:not(:last-child) {
      .nav-anchor {
        position: relative;
        &::after {
          content: "";
          height: 0.1rem;
          bottom: 0;
          @extend %w-100;
          @extend %position-h-center;
          @extend %white-default-bg-2;
        }
      }
    }
    &-highlight > .nav-anchor {
      position: relative;
      &::before {
        content: "";
        height: 2.5rem;
        position: absolute;
        inset-inline: -0.5rem 0;
        top: 0.8rem;
        border-radius: var(--half-radius);
        animation: fade 1s infinite alternate;
        background-color: clr(secondary);
      }
    }
  }
}
.primary-nav {
  flex-basis: 50%;
  padding: 0 var(--half-space) 0 var(--full-space);
  @extend %w-50;
  @extend %h-100;
  @extend %primary-dark-bg;
  @include flex-config(flex, column, space-evenly, null);
  .primary-nav-link {
    @extend %w-100;
    @extend %h-100;
    &-new {
      .nav-anchor .btn-text,
      .nav-anchor .nav-text {
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 1.5rem;
          aspect-ratio: 1/1;
          background: url(/static-assets/images/svg/live.svg?v=#{$image-version}) center center no-repeat;
          background-size: contain;
          @extend %m-l-half;
          @extend %m-t-full-neg;
        }
      }
    }
    &.active-link {
      > .nav-anchor {
        position: relative;
        isolation: isolate;
        &::before {
          content: "";
          z-index: var(--bg-gradient);
          width: calc(100% + var(--two-space));
          @extend %h-100;
          @include position(0, null, null, var(--full-space-negative));
          @include linear-gradient(90deg,
            hsl(var(--color-hsl-black-dark) / 0.5) 20%,
            transparent 100%);
        }
        &::after {
          content: "";
          width: 0.3rem;
          z-index: var(--active-line);
          @extend %h-100;
          @extend %white-default-bg;
          @include position(0, null, null, var(--full-space-negative));
        }
      }
    }
    .nav-anchor[aria-expanded="true"] {
      ~ .secondary-nav {
        right: 0;
        opacity: 1;
      }
    }
  }
  .btn-text {
    @extend %uppercase;
    @extend %font-12-primary-b;
    @extend %white-default;
    @extend %text-left;
  }
  .nav-text {
    @extend %uppercase;
    @extend %font-12-primary-b;
    @extend %white-default;
  }
  .nav-anchor {
    width: inherit;
    height: inherit;
    @extend %flex-v-center;
  }
}
.sub-nav-text {
  @extend %white-default;
  @extend %font-14-primary-r;
}
.secondary-nav {
  width: calc(var(--window-inner-width) / 2);
  height: 100%;
  padding: 0 var(--full-space);
  overflow-y: auto;
  opacity: 0;
  @include transition(right 600ms ease-in-out, opacity 500ms ease-in-out);
  @include position(0, calc(-1 * var(--window-inner-width)), null, null);
  .secondary-nav-link {
    @include flex-config(flex, column, center, null);
    &-social,
    &-app {
      .nav-anchor .nav-text {
        @extend %uppercase;
        @extend %font-14-primary-b;
      }
    }
    &-highlight > .nav-anchor {
      isolation: isolate;
      &::before {
        top: 1.6rem;
        z-index: var(--z-index1-negative);
      }
    }
    &-new {
      .nav-anchor .btn-text,
      .nav-anchor .nav-text {
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 1.5rem;
          aspect-ratio: 1/1;
          background: url(/static-assets/images/svg/live.svg?v=#{$image-version}) center center no-repeat;
          background-size: contain;
          @extend %m-l-half;
          @extend %m-t-full-neg;
        }
      }
    }
  }
  .nav-anchor {
    width: 100%;
    padding: var(--full-space) 0;
    .btn-text,
    .nav-text {
      text-align: left;
      @extend %capitalize;
      @extend %w-100;
      @extend %font-14-primary-r;
    }
    &-social {
      @include flex-config(flex, column, null, flex-start);
    }
  }
  .submenu {
    .nav-anchor[aria-expanded="true"] {
      ~ .sub-ul {
        pointer-events: initial;
        .sub-nav-link {
          height: 4.5rem;
          opacity: 1;
        }
      }
      .btn-text::before {
        @include transform(translateY(-50%) rotate(180deg));
      }
    }
    .btn-text {
      position: relative;
      &::before {
        content: "\e814";
        font-family: $font-icon;
        font-size: 0.8rem;
        right: 0;
        @extend %white-default;
        @extend %position-v-center;
        @include transition(transform 450ms linear);
      }
    }
  }
}
.sub-ul {
  pointer-events: none;
  @include flex-config(flex, column, null, flex-start);
}
.sub-nav-link {
  height: 0;
  opacity: 0;
  @extend %p-l-half;
  @extend %w-100;
  @include transition(all 500ms ease-in-out);
}
.site-search {
  height: var(--search-height);
  @extend %p-full;
  @extend %primary-dark-bg;
  &-form {
    height: 4rem;
    position: relative;
    @include border-radius(50vmax);
    @extend %flex-v-center;
    @extend %black-default-bg-5;
    &::before {
      content: "\e809";
      font-family: $font-icon;
      font-size: 1.7rem;
      left: var(--full-space);
      @extend %white-default;
      @extend %position-v-center;
    }
  }
}
.search-input {
  background-color: transparent;
  border: unset;
  padding: 0 var(--half-space) 0 var(--three-space);
  @extend %w-100;
  @extend %h-100;
  @extend %white-default;
  @extend %font-12-m;
  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}
//theme buttom css
.theme-module {
  @extend %w-50;
  @extend %h-100;
  @extend %p-full;
  @extend %primary-dark-bg;
  @include flex-config(flex, null, space-between, center);
}
.promo {
  &-section {
    height: inherit;
    min-width: 6.5rem;
    @extend %w-50;
    @extend %h-100;
    @extend %p-half;
    @extend %primary-dark-bg;
    @include flex-config(flex, null, space-between, center);
  }
  &-list {
    @extend %flex;
    @extend %w-100;
    @extend %h-100;
  }
  &-link {
    @extend %w-50;
    @extend %h-100;
    &-highlight {
      position: relative;
      padding-right: var(--full-space);
      &::before {
        border-radius: var(--half-radius);
        content: "";
        position: absolute;
        inset: -0.5rem 0.5rem -0.5rem -0.5rem;
        background-color: clr(secondary);
        animation: fade 1s infinite linear alternate;
      }
    }
    &:not(:nth-last-child(2), :last-child) {
      position: relative;
      @include line(dark);
    }
    &.promo-link-label {
      padding-right: 0;
      &::after {
        display: none;
      }
    }
    &-new {
      .promo-anchor .promo-text {
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 1.5rem;
          aspect-ratio: 1/1;
          background: url(/static-assets/images/svg/live.svg?v=#{$image-version}) center center no-repeat;
          background-size: contain;
          @extend %m-l-half;
          @extend %m-t-full-neg;
        }
      }
    }
  }
  &-text {
    @extend %capitalize;
    @extend %font-12-primary-r;
    @extend %white-default;
  }
}
.promo-link-icon {
  position: relative;
  &:not(:last-child)::after {
    content: "";
    width: 0.1rem;
    right: 0;
    @extend %h-80;
    @extend %white-default-bg-1;
    @extend %position-v-center;
  }
  .promo-anchor {
    height: 100%;
    @include flex-config(flex, column, space-between, center);
    &::before {
      content: "";
      font-family: $font-icon;
      font-size: 1.7rem;
      display: inline-block;
      @extend %white-default;
    }
    &.promo-anchor {
      &-tickets::before {
        content: "\e80d";
      }
      &-shop::before {
        content: "\e839";
      }
      &-play-store::before {
        content: "\e82b";
      }
      &-app-store::before {
        content: "\e822";
      }
      &-play-store,
      &-app-store {
        .promo-text {
          display: none;
        }
      }
    }
  }
}
.theme-text {
  @extend %uppercase;
  @extend %font-14-primary-b;
  @extend %white-default;
}
//theme togle
.theme-toggle {
  width: 4rem;
  height: 2rem;
  position: relative;
  display: inline-block;
}
.toggle-switch {
  position: relative;
  width: 4rem;
  height: 2rem;
  cursor: pointer;
  display: block;
  @include transition(background-color 0.4s linear);
  @include border-radius(10vmax);
  @extend %white-default-bg-5;
  &:before {
    content: "";
    display: block;
    width: 2rem;
    height: 2rem;
    transition: 0.4s;
    z-index: var(--theme-toggle);
    @include position(null, null, 0, 0);
    @include shadow(0 0 0 0.1rem var(--color-white-default));
    @include border-radius(100%);
    @extend %white-default-bg;
  }
  &:after {
    content: "";
    display: block;
    right: 0.5rem;
    width: 0.8rem;
    height: 0.8rem;
    border: 0.1rem solid var(--color-white-default);
    @include transition(all 0.4s);
    @extend %position-v-center;
    @extend %circle;
  }
}
.toggle-checkbox {
  position: absolute;
  opacity: 0;
  width: 4rem;
  height: 2rem;
  &:checked + .toggle-switch {
    @extend %button-primary-bg;
    &:before {
      @include transform(translateX(2rem));
    }
    &::after {
      right: 3rem;
      width: 0.1rem;
      border: unset;
      @include border-radius(unset);
      @extend %white-default-bg;
    }
  }
}
.primary,
.sub {
  &-nav-link-icon {
    .nav-text::before {
      content: "";
      font-family: $font-icon;
      font-size: 1.5rem;
      display: inline-block;
      @extend %m-r-half;
      @extend %white-default;
    }
    .nav-anchor {
      &-redirection {
        .nav-text::before {
          content: "\e804";
        }
      }
      &-whatsapp {
        .nav-text::before {
          content: "\e800";
        }
      }
    }
  }
}
.sub-nav-link-icon {
  .nav-text::before {
    font-size: 1.1rem;
  }
  .nav-anchor-whatsapp {
    .nav-text::before {
      margin-right: var(--quater-space);
    }
  }
}
.loading {
  .btn-login {
    .btn-text {
      font-size: 0;
      &::after {
        content: "Loading...";
        font-size: 1.6rem;
      }
    }
    .icon:after {
      content: "";
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
      @include border(all, 2, white-default, 5);
      border-top-color: var(--white-default);
      animation: spinner 2s infinite linear;
      transform: rotate(0deg);
    }
  }
}
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .user-module {
    .icon::after {
      margin: 0;
    }
    .btn-menu {
      width: 4rem;
      .btn-text {
        display: none;
      }
    }
    .theme-module {
      width: calc(100% - 8rem);
      overflow-x: auto;
      padding: 0 var(--half-space);
    }
    .site-search {
      width: max-content;
      .btn-text {
        font-size: 0;
      }
    }
  }
  .header-bottom {
    @include flex-config(null, null, space-between, null);
  }
  .navigation-link {
    min-width: auto;
    &:last-child::after {
      display: none;
    }
    &-new {
      .nav-anchor {
        text-align: left;
      }
    }
    &-list {
      @include flex-config(null, null, space-between, null);
      .navigation-link.submenu {
        padding-right: var(--full-space);
      }
    }
  }
  .promo-link {
    width: max-content;
    min-width: 4rem;
    &:not(:last-child) {
      .promo-text {
        display: none;
      }
    }
  }
  .redirection-list {
    overflow-x: auto;
  }
}
@media screen and (max-width: $small-mobile-max-width) {
  .header-wrap .promo-anchor-shop .promo-text {
    font-size: 0;
  }
}
@media screen and (min-width: $tablet-min-width) {
  body {
    padding: 0;
  }
  .dark-mode {
    .lang-section,
    .theme-module,
    .promo-link:not(:last-child),
    .btn-search-toggle,
    .navigation-link:not(:last-child),
    .redirection-link {
      &::after {
        @include bg(white-default, 1);
      }
    }
    .promo-link-icon .promo-anchor-whatsapp {
      @include bg(primary-light);
    }
    .theme-module .theme-text {
      font-size: 0;
      &::before {
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }
  }
  .temp-user {
    margin: 0 var(--quater-space) 0 0;
    font-size: 1rem;
  }
  .header-wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: var(--header-z);
  }
  .site-header-wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    @include flex-config(flex, null, null, null);
  }
  .site-logo {
    width: var(--header-height);
    aspect-ratio: 1 / 1;
    padding: var(--half-space);
    margin: unset;
    &::after {
      width: 0.2rem;
    }
    & ~ .head-wrap {
      width: calc(var(--window-inner-width) - var(--header-height));
      height: var(--header-height);
    }
  }
  .site-nav {
    position: unset;
  }
  .header-top {
    height: var(--header-top-height);
    // @include bg(primary-dark);
    @include flex;
    .nav-text {
      text-transform: uppercase;
      @include font(10);
      @include color(white-default);
    }
  }
  .dark-mode {
    .site-header-wrapper {
      box-shadow: none;
    }
    .header-top {
      @include bg(black-light);
    }
    .header-bottom {
      @include bg(black-default);
    }
    .promo-list {
      @include bg(black-default);
    }
  }
  //header-top starts
  .user-photo {
    margin-right: var(--quater-space);
  }
  .user-module {
    flex-shrink: 0;
    width: 30%;
    @include flex-config(flex, null, flex-end, null);
  }
  .theme-module {
    background-color: transparent;
    padding: var(--half-space);
    .theme-text {
      // @include font(10);
      text-transform: uppercase;
      @include font(12, $font-primary-regular);
    }
  }
  .lang-section,
  .theme-module {
    position: relative;
    // padding-inline: var(--half-space);
    @include line;
  }
  .redirection {
    &-module {
      flex-shrink: 0;
      width: 70%;
      height: 100%;
      @include flex-config(flex, null, null, center);
      .redirection-link:first-child {
        padding-left: var(--half-space);
      }
    }
    &-list {
      @include flex-config(flex, null, null, center);
    }
    &-link {
      width: max-content;
      flex-shrink: 0;
      position: relative;
      padding: var(--quater-space) var(--half-space);
      @include line;
      &:first-child {
        padding-left: 0;
      }
      &-highlight {
        position: relative;
        padding-right: var(--full-space);
        isolation: isolate;
        &::before {
          border-radius: var(--half-radius);
          content: "";
          position: absolute;
          inset: -0.5rem 0.5rem -0.5rem 0rem;
          background-color: clr(secondary);
          animation: fade 1s infinite linear alternate;
          z-index: var(--z-index1-negative);
        }
      }
      .nav-anchor-shop.nav-anchor-icon .nav-text::before {
        content: '\e83a';
      }
      // content: "\e83b";
      .nav-anchor-membership.nav-anchor-icon .nav-text::before {
        content: '\e83b';
      }
      .nav-anchor-tickets.nav-anchor-icon .nav-text::before {
        content: '\e80d';
      }
      .nav-anchor-icon .nav-text {
        @include flex-config(flex, null, null, center);
        @include font(12, $font-primary-regular);
        &::before {
          content: "\e804";
          font-family: $font-icon;
          font-size: 1.4rem;
          display: inline-block;
          margin-right: var(--half-space);
          color: var(--color-white-default);
        }
      }
    }
  }
  // header top ends
  // header bottom start
  .site-nav {
    position: relative;
    right: unset;
    top: unset;
    width: 100%;
    height: 100%;
    @include flex;
  }
  .promo-module {
    flex-shrink: 0;
    width: max-content;
    @include flex-config(flex, null, flex-end, center);
  }
  .promo-list {
    height: 100%;
    @include flex-config(flex, null, space-evenly, center);
  }
  .promo-link-icon {
    &:not(:last-child)::after {
      @include bg(primary-light, 1);
    }
    .promo-anchor {
      height: auto;
    }
  }
  .promo-link {
    @include flex-config(flex, null, center, center);
    .promo-anchor-whatsapp {
      padding: var(--half-space);
      margin-inline: var(--half-space);
      @include bg(primary);
      @include border-radius(var(--half-radius));
      @include flex-config(flex, row, null, null);
      &::before {
        content: "\e800";
      }
      .promo-text {
        text-transform: uppercase;
        display: none;
      }
    }
  }
  .navigation-link {
    &-highlight {
      isolation: isolate;
    }
    &-highlight > .nav-anchor {
      position: relative;
      &::before {
        content: "";
        height: 2.5rem;
        position: absolute;
        inset-inline: -0.5rem 0;
        top: 1.7rem;
        border-radius: var(--half-radius);
        animation: fade 1s infinite linear alternate;
        background-color: clr(secondary);
        z-index: var(--z-index1-negative);
      }
    }
    &-list {
      width: 100%;
      @include flex;
    }
    &-new {
      .nav-anchor .btn-text,
      .nav-anchor .nav-text {
        position: relative;
        &::after {
          content: "";
          display: inline-block;
          width: 1.5rem;
          aspect-ratio: 1/1;
          background: url(/static-assets/images/svg/live.svg?v=#{$image-version}) center center no-repeat;
          background-size: contain;
          margin-top: var(--half-space-negative);
        }
      }
    }
    padding: var(--full-space) var(--half-space);
    position: relative;
    @include line(dark);
    &::after {
      right: -0.2rem;
    }
    &.submenu {
      padding: var(--full-space) calc(var(--half-space) + var(--quater-space)) var(--full-space) var(--half-space);
    }
  }
  .site-search {
    height: auto;
    padding: var(--full-space) 0;
    background-color: transparent;
    &-form {
      width: 0;
      height: calc(100% - var(--full-space));
      right: 0;
      opacity: 0;
      @include bg(primary-dark);
      @include center(vertical);
      @include transition(width 500ms ease-in-out, opacity 400ms ease-in-out);
    }
  }
  .btn-search-toggle {
    width: 4rem;
    position: relative;
    @include line(dark);
    .btn-text {
      font-size: 0;
      &::before {
        content: "\e809";
        font-size: 1.4rem;
        font-family: $font-icon;
        @include color(white-default);
      }
    }
    &[aria-expanded="true"] {
      ~ .site-search-form {
        width: calc(100% - var(--half-space));
        opacity: 1;
      }
    }
  }
  .btn-close {
    width: 4rem;
    &::before {
      content: "\e812";
      font-size: 1.4rem;
      font-family: $font-icon;
      @include color(white-default);
    }
  }
  .search-input {
    width: calc(100% - 4rem);
  }
  .login-section {
    position: relative;
  }
  .list-wrap {
    width: unset;
    bottom: unset;
    transform: unset;
    left: unset;
    .btn-site {
      background-color: transparent;
      border-color: var(--color-white-default);
      @include border-radius(var(--half-radius));
      .btn-text {
        @include color(white-default);
      }
    }
  }
  .list-wrap,
  .sub-ul {
    min-width: max-content;
    pointer-events: none;
    border-radius: 0 0 var(--half-radius) var(--half-radius);
    @include dropdown(close);
    @include flex-config(flex, column, null, flex-start);
    @include bg(black-light);
    @include position(100%, null, null, null);
  }
  .list-wrap {
    right: 0;
  }
  .sub-ul {
    left: 0;
  }
  .btn-menu {
    height: var(--header-top-height);
    &[aria-expanded="true"] {
      ~ .list-wrap {
        pointer-events: initial;
        @include dropdown(open);
      }
    }
  }
  .btn-login {
    gap: 0;
    .btn-text {
      @include font(12, $font-primary-regular);
    }
  }
  .sub-nav-link {
    width: 100%;
    height: 3.55rem;
    opacity: 1;
    text-align: center;
    padding: var(--full-space);
    &::after {
      display: none;
    }
    .nav-anchor {
      width: 100%;
      height: 100%;
      @include flex-config(flex, null, center, center);
    }
  }
  .submenu {
    position: relative;
    .btn-text {
      &::after {
        @include transition(0.5s);
      }
    }
    .nav-anchor[aria-expanded="true"] {
      .btn-text::after {
        @include transform(rotate(180deg));
      }
      ~ .sub-ul {
        @include dropdown(open);
        pointer-events: initial;
        .sub-nav-link {
          pointer-events: initial;
        }
      }
      .btn-text::before {
        @include transform(translateY(-50%) rotate(180deg));
      }
    }
    .btn-text {
      position: relative;
      &::before {
        content: "\e814";
        font-family: $font-icon;
        font-size: 0.6rem;
        right: -1.3rem;
        @include transition(transform 450ms ease-in-out);
        @include color(white-default);
        @include center(vertical);
      }
    }
  }
  // header bottom end
  .header-bottom {
    height: var(--header-bottom-height);
    @include flex;
    @include bg(primary-dark);
    .navigation-link-highlight > .nav-anchor::before {
      width: calc(100% + 2rem);
      inset-inline: -1rem 0;
    }
    .navigation-link-new.navigation-link-highlight .nav-anchor .nav-text {
      margin-top: 0.3rem;
      &::after {
        display: none;
      }
    }
  }
  .nav-text {
    @include font(12, $font-primary-regular);
    @include color(white-default);
  }
  .btn-text {
    @include font(12, $font-primary-regular);
    @include color(white-default);
  }
}
@media only screen and (min-width: $tablet-min-width) and (hover: hover) {
  .header-bottom .navigation-link,
  .secondary-navigation .secondary-navigation-link {
    &.submenu {
      .nav-anchor + .sub-ul {
        @include dropdown(close);
      }
      &:hover {
        .btn-text {
          &::before {
            @include transform(translateY(-50%) rotate(180deg));
          }
          &::after {
            @include transform(rotate(180deg));
          }
        }
        .nav-anchor {
          & + .sub-ul {
            pointer-events: initial;
            @include dropdown(open);
          }
        }
      }
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .redirection {
    &-link {
      padding: 0 var(--full-space) 0 var(--half-space);
      &-right {
        margin-right: auto;
        &::after {
          display: none;
        }
      }
    }
    &-module {
      width: 100%;
      flex-shrink: unset;
    }
    &-list {
      width: 100%;
      overflow-x: unset;
    }
    &-link {
      position: relative;
      &.active {
        &::before {
          content: "";
          width: 100%;
          height: var(--header-top-height);
          border-bottom: 0.2rem solid var(--color-white-default);
          left: 0;
          @include center(vertical);
          @include linear-gradient(0deg,
            hsl(var(--color-hsl-white-default) / 0.4) 0%,
            transparent 100%);
        }
      }
    }
  }
  .theme {
    &-module {
      width: max-content;
    }
    &-text {
      margin-right: var(--half-space);
    }
  }
  .lang-section {
    padding-right: var(--full-space);
  }
  .btn-lang {
    .icon::after {
      font-size: 2rem;
    }
    .btn-text {
      position: relative;
      text-transform: uppercase;
      @include font(12, $font-primary-regular);
      &::before {
        content: "\e814";
        font-family: $font-icon;
        font-size: 0.6rem;
        right: var(--full-space-negative);
        @include transition(transform 300ms ease-in-out);
        @include color(white-default);
        @include center(vertical);
      }
    }
    &[aria-expanded="true"] {
      .btn-text::before {
        @include transform(translateY(-50%) rotate(180deg));
      }
    }
  }
  .user-module {
    width: max-content;
  }
  .btn-menu {
    width: max-content;
    padding: 0 var(--half-space);
    @include flex-config(null, row, null, center);
    .icon::after {
      margin-bottom: 0;
      margin-right: var(--half-space);
    }
    &[aria-expanded="true"] {
      .btn-text,
      .user-photo {
        display: inline-block;
      }
      &.btn-login .icon::after {
        content: "\e805";
      }
      &.btn-lang .icon::after {
        content: "\e802";
      }
      .icon::after {
        font-size: 1.7rem;
      }
    }
  }
  .btn-login .icon::after {
    font-size: 1.8rem;
  }
  .site-nav {
    width: 100%;
    flex-shrink: unset;
  }
  .promo-module {
    width: max-content;
    height: 100%;
    padding: 0;
  }
  .promo-link {
    width: max-content;
    padding-left: var(--half-space);
    padding-right: var(--half-space);
    .promo-anchor {
      @include flex-config(null, row, center, null);
    }
    .promo-text {
      margin-left: var(--half-space);
    }
    &-whatsapp {
      width: max-content;
      text-align: center;
      padding: var(--half-space);
      padding-left: 0;
      .promo-anchor {
        width: max-content;
      }
      .promo-text {
        text-transform: uppercase;
        margin: 0;
      }
    }
    &-app-store::after {
      display: none;
    }
    .promo-anchor-whatsapp {
      .promo-text {
        display: block;
        margin-left: var(--quater-space);
      }
    }
    .promo-text {
      @include font(16);
    }
  }
  .btn-search-toggle {
    width: max-content;
    padding: 0 var(--half-space);
    @include line(dark);
    .btn-text {
      &::before {
        font-size: 1.5rem;
        margin-right: var(--half-space);
      }
      @include font(16, $font-primary-regular);
      @include flex-config(flex, null, center, null);
    }
  }
  .promo-anchor,
  .nav-anchor {
    height: 100%;
    @include flex-config(flex, null, null, center);
  }
  .navigation-link {
    height: 100%;
    padding: 0 var(--full-space);
    position: relative;
    &.active {
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        border-bottom: 0.2rem solid var(--color-white-default);
        @include position(null, null, 0, 0);
        @include linear-gradient(0deg,
          hsl(var(--color-hsl-white-default) / 0.5) 0%,
          transparent 100%);
      }
    }
    &.submenu {
      padding: 0 var(--two-space) 0 var(--full-space);
      .btn-text::before {
        right: -1.7rem;
      }
    }
    &:not(:last-child) {
      @include line(light);
    }
    // &::after {
    //   display: none;
    // }
    &:last-child::after {
      display: none;
    }
    &-new {
      margin-top: unset;
    }
  }
  .header-top .nav-text {
    @include font(12, $font-primary-bold);
  }
  .btn-text,
  .nav-text {
    @include font(14);
  }
  .toggle-switch::before {
    width: 1.6rem;
    height: 1.6rem;
    @include center(vertical);
  }
  .toggle-checkbox:checked + .toggle-switch::before {
    transform: translateX(2.3rem) translateY(-50%);
  }
}
@media screen and (max-width: 375px) {
  .extra-promo-module {
    .promo {
      &-text {
        font: 1.3rem/1.4rem "Romagna-Bold", sans-serif;
      }
    }
  }
}