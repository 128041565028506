@use "../config" as *;
.dark-mode{
		.swiper-button-next,
		.swiper-button-prev {
			border: 0.1rem solid var(--color-accent);
			@extend %white-default-bg-2;
			
			&::after {
				@extend %accent;
			}
		}

		.swiper-button-next {
			&:hover {
				border: unset;
				@include linear-gradient(to left,
					var(--color-accent),
					var(--color-secondary-light));

				&::after {
					@extend %white-default;
				}
			}
		}

		.swiper-button-prev {
			&:hover {
				border: unset;
				@include linear-gradient(to right,
					var(--color-accent),
					var(--color-secondary-light));

				&::after {
					@extend %white-default;
				}
			}
		}
}
.swiper {
	&-button-next,
	&-button-prev {
		position: relative;
		width: 4rem;
		height: 4rem;
        z-index: var(--swiper-btn-z);
		@extend %white-default-bg-2;
		@extend %circle;
		@extend %flex-center;

		&.swiper-button-disabled {
			cursor: not-allowed;
			@extend %white-default-bg-1;

			&::after {
				@extend %white-default-6;
			}
		}

		&::after {
			font: 1.2rem/1 $font-icon;
			@extend %white-default;
		}
	}

	&-button-prev {
		&::after {
			content: "\e803";
		}

		&:hover {
			@include linear-gradient(to right,
				var(--color-primary),
				var(--color-secondary));
		}
	}

	&-button-next {
		&:hover {
			@include linear-gradient(to left,
				var(--color-primary),
				var(--color-secondary));
		}

		&::after {
			content: "\e806";
		}
	}
}