@use "./config" as *;
.dark-mode {
	.waf-listing {
		&.home-tv-list {
			// .list-group-wrap
			.second-list .item-type-video .article-content .item-type-icon {
				@extend %button-primary-bg;
			}
		}
	}
}
.waf-listing.home-tv-list {
	.title {
		padding-left: 5rem;
		position: relative;
		&::before {
			content: "";
			width: 5rem;
			height: 5rem;
			background: url("/static-assets/images/svg/royal-tv-logo.svg?v=#{$image-version}") no-repeat;
			background-size: 100%;
			left: 0;
			@include center(vertical);
		}
	}
	.head-wrap {
		margin-bottom: var(--one-n-half-space);
	}
	.article-item {
		margin: 0;
		position: relative;
	}
	.img-box {
		@include border-radius(unset);
	}
	.first-list {
		.article-item {
			width: 100%;
			flex-basis: 100%;
		}
		.article-title {
			@include truncate-vertical-line(24, 2);
			@extend %uppercase;
			@extend %font-24-primary-b;
		}
	}
	.second-list {
		.article-item {
			width: 50%;
			flex-basis: 50%;
		}
		.article-title {
			@include truncate-vertical-line(18, 2);
			@extend %font-18-primary-b;
		}
	}
	.first-list,
	.second-list {
		.article-item {
			height: auto;
		}
		.article-wrap,
		.article-thumbnail,
		.img-box {
			height: 100%;
		}
	}
	.first-list,
	.second-list {
		// height: calc((var(--window-inner-height) - var(--header-height)) / 2);
		.article {
			&-item {
				position: relative;
				flex-shrink: 0;
				@extend %white-default-bg;
			}
			&-content {
				flex-wrap: wrap;
				@extend %p-half;
				@extend %flex;
				@extend %flex-center;
				@include position(null, null, 0, 0);
			}
			&-title {
				text-align: center;
				@extend %white-default;
				@extend %m-b-full;
			}
		}
		.item-type-video {
			.article-content {
				.item-type-icon {
					position: relative;
					width: 3.2rem;
					height: 3.2rem;
					@extend %m-b-half;
					@extend %primary-bg;
					@extend %flex-center;
					&::after {
						content: "\e80f";
						font-size: 1.6rem;
						font-family: $font-icon;
						@extend %white-default;
					}
					& ~ a {
						flex-shrink: 0;
						@extend %w-100;
					}
				}
			}
		}
		.meta {
			@extend %m-r-full;
			@extend %white-default-7;
			position: relative;
			&::after {
				content: "";
				width: 0.1rem;
				height: 2rem;
				background-color: hsl(var(--color-hsl-white-default) / 0.5);
				right: var(--half-space-negative);
				@include center(vertical);
			}
		}
		.reaction-section {
			.icon-b-share {
				&::after {
					@extend %white-default-7;
				}
			}
		}
		@extend %img-shade;
	}
}
@media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
	.waf-listing {
		&.home-tv-list {
			.waf-head {
				top: calc((var(--window-inner-height) - var(--header-height)) / 2);
			}
			.first-list {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				height: calc((var(--window-inner-height) - var(--header-height)) / 2);
				overflow: hidden;
				.article-item {
					width: unset;
					flex-basis: unset;
					height: 100%;
					.article-description {
						display: none;
					}
					&:first-child,
					&:nth-child(2),
					&:nth-child(3) {
						width: unset;
						flex-basis: unset;
						.img-box {
							aspect-ratio: unset;
							img {
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}
					}
					&:first-child {
						grid-area: 1 / 1 / 3 / 2;
						.img-box,
						.article-wrap,
						.article-thumbnail {
							height: calc((var(--window-inner-height) - var(--header-height)) / 2);
						}
					}
					&:nth-child(2) {
						grid-area: 1 / 2 / 2 / 3;
					}
					&:nth-child(3) {
						grid-area: 2 / 2 / 3 / 3;
					}
					&:nth-child(2),
					&:nth-child(3) {
						.img-box,
						.article-wrap,
						.article-thumbnail {
							height: calc((var(--window-inner-height) - var(--header-height)) / 4);
						}
					}
				}
			}
		}
	}
}
@media screen and (min-width: $desktop-min-width) {
	.waf-listing.home-tv-list {
		.first-list {
			width: var(--container-max-width);
			height: calc(var(--window-inner-height) - (var(--header-height) + 14rem));
			position: relative;
			overflow: hidden;
			flex-wrap: wrap;
			@include flex();
			.article {
				&-item {
					height: 100%;
					position: relative;
					flex: 1 0 calc((var(--window-inner-width) - (var(--container-white-space) * 2)) / 4);
					width: calc((var(--window-inner-width) - (var(--container-white-space) * 2)) / 4);
					will-change: flex;
					margin: 0;
					@include transition(flex 500ms ease);
					&-active {
						flex: 1 0 calc((var(--window-inner-width) - (var(--container-white-space) * 2)) / 2);
						width: calc((var(--window-inner-width) - (var(--container-white-space) * 2)) / 2);
						.article {
							&-title {
								text-transform: uppercase;
								@include transform(scale(1.5));
								height: 4rem;
							}
							&-description {
								opacity: 1;
								width: calc((var(--window-inner-width) - (var(--container-white-space) * 2)) / 2);
								@include truncate-vertical-line(14, 2);
								height: auto;
							}
						}
					}
				}
				&-content {
					position: absolute;
					right: 0;
					bottom: 0;
					left: 0;
					padding: var(--half-space);
					text-align: center;
					overflow: hidden;
				}
				&-title {
					text-transform: unset;
					width: calc(((var(--window-inner-width) - (var(--container-white-space) * 2)) / 4) - var(--full-space));
					margin: var(--full-space) auto var(--half-space) auto;
					will-change: transform;
					transform-origin: bottom;
					height: 5rem;
					@include font(20, $font-primary-bold);
					@include transition(transform 500ms ease);
				}
				&-description {
					display: none;
					opacity: 0;
					width: calc(((var(--window-inner-width) - (var(--container-white-space) * 2)) / 4) - var(--full-space));
					margin: var(--half-space) auto;
					will-change: opacity;
					@include font(14, $font-primary-regular);
					@include transition(opacity 500ms ease);
					@include color(white-default, 7);
				}
				&-wrap,
				&-thumbnail {
					height: 100%;
				}
				&-thumbnail {
					a {
						height: 100%;
						@include flex();
					}
				}
			}
			.img-box {
				width: 100%;
				will-change: transform;
				transition: transform 500ms cubic-bezier(0.165, 0.84, 0.44, 1);
				img {
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
	}
}