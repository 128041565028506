@use "./config" as *;
@forward "./squad-common";
.dark-mode {
	.waf-squad {
		.squad-listing {
			&::before {
				background: url(/static-assets/images/cssimages/squad/card-bg-dark-m.jpg?v=#{$image-version}) bottom center no-repeat;
				background-size: cover;
			}
		}
		.swiper-button-next,
		.swiper-button-prev {
			border: 0.1rem solid var(--color-accent);
			&::after {
				@extend %accent;
			}
		}
		.swiper-button-next {
			&:hover {
				border: unset;
				@include linear-gradient(to left,
					var(--color-accent),
					var(--color-secondary-light));
				&::after {
					@extend %white-default;
				}
			}
		}
		.swiper-button-prev {
			&:hover {
				border: unset;
				@include linear-gradient(to right,
					var(--color-accent),
					var(--color-secondary-light));
				&::after {
					@extend %white-default;
				}
			}
		}
		&.home-squad-list {
			background: url(/static-assets/images/cssimages/squad/card-bg-dark-m.jpg?v=#{$image-version}) bottom center no-repeat;
			background-size: cover;
		}
	}
}
.waf-squad.home-squad-list,
.waf-squad.ckt-squad {
	padding: var(--full-space) 0 0;
	.waf-head {
		margin: 0;
		padding: var(--full-space) var(--half-space) 0;
	}
	.swiper-button-next,
	.swiper-button-prev {
		cursor: pointer;
		display: none;
	}
	.squad-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		box-sizing: content-box;
	}
	.view-more {
		font-size: 0;
		@extend %w-100;
		@extend %h-100;
		@extend %position-top-left;
	}
	.player {
		&-meta {
			@extend %flex-v-center;
		}
	}
	.swiper-pagination {
		width: 100%;
		bottom: var(--full-space);
		white-space: nowrap;
		overflow: hidden;
		text-align: center;
		font-size: 0;
		z-index: var(--pagination-bullet-z);
		@extend %position-h-center;
		&-bullet {
			position: relative;
			display: inline-block;
			width: 0.6rem;
			height: 0.5rem;
			margin: 0 0.5rem;
			text-align: center;
			@extend %white-default-bg-4;
			@extend %radius-half;
			@include transition(transform 200ms, left 200ms);
			&-active {
				width: 2rem;
				@extend %button-primary-bg;
			}
		}
		&-bullets-dynamic {
			.swiper-pagination-bullet {
				position: relative;
				transform: scale(0.33);
				&-active,
				&-active-main {
					transform: scale(1);
				}
				&-active-prev,
				&-active-next {
					transform: scale(0.66);
				}
				&-active-prev-prev,
				&-active-next-next {
					transform: scale(0.33);
				}
			}
		}
	}
	.swiper-button-next,
	.swiper-button-prev {
		@include center(vertical);
	}
	.swiper-button-next {
		@include position(null, var(--full-space), null, null);
	}
	.swiper-button-prev {
		@include position(null, null, null, var(--full-space));
	}
}
.waf-squad.home-squad-list {
	@extend %primary-dark-bg;
	position: relative;
	.head-tab {
		a {
			font-size: 1.4rem;
			font-family: $font-primary-bold;
			@extend %white-default;
		}
	}
	.title {
		@extend %white-default;
	}
	.head-tab .title {
		@extend %white-default;
	}
	.head-wrap {
		justify-content: space-between;
		@extend %m-b-zero;
	}
	.card-action {
		@extend %gap-half;
	}
	.btn-site {
		&.view-more {
			position: unset;
			@extend %white-default;
			@extend %text-center;
			@extend %btn-fill;
			@extend %font-12-secondary-b;
		}
	}
	.player-stats {
		&-list {
			flex-wrap: wrap;
		}
		&-item {
			flex-basis: 33.33%;
		}
	}
	.squad-item.overseas {
		.icon {
			bottom: 0;
			top: unset;
		}
	}
	.player-stats-count {
		font-size: 3rem;
		line-height: .5;
	}
	.first-name {
		font-size: 3rem;
		line-height: .5;
	}
	.last-name {
		font-size: 2rem;
	}
	.swiper-pagination-bullet {
		@extend %white-default-bg;
	}
}
@media screen and (min-width: $tablet-min-width) {
	.dark-mode {
		.waf-squad.home-squad-list,
		.waf-squad.ckt-squad {
			.squad-listing {
				&::before {
					background: url(/static-assets/images/cssimages/squad/card-bg-dark.jpg?v=#{$image-version}) bottom center no-repeat;
					background-size: cover;
				}
			}
		}
		.waf-squad.home-squad-list {
			&::before {
				background: url(/static-assets/images/cssimages/squad/card-bg-dark.jpg?v=#{$image-version}) bottom center no-repeat;
				background-size: cover;
			}
		}
	}
	.waf-squad.home-squad-list,
	.waf-squad.ckt-squad {
		margin: 0 var(--half-space-negative) var(--two-space);
		.waf-head {
			margin: 0;
			padding: var(--full-space) 0 0;
		}
		.waf-body {
			margin: 0;
			@include border-radius(var(--half-radius), hidden);
		}
		.swiper-button-next,
		.swiper-button-prev {
			display: flex;
		}
		.squad-listing {
			&::before {
				background: url(/static-assets/images/cssimages/squad/card-bg.jpg?v=#{$image-version}) bottom center no-repeat;
				background-size: cover;
			}
		}
	}
	.waf-squad.home-squad-list {
		margin-bottom: 0;
		.waf-head {
			width: var(--container-max-width);
			margin: 0 auto;
		}
		.head-wrap {
			margin-bottom: 0;
		}
		.layout-wrapper {
			width: 100%;
		}
	}
}
@media screen and (min-width: $desktop-min-width) {
	.waf-squad.home-squad-list,
	.waf-squad.ckt-squad {
		.waf-body {
			@include border-radius(var(--half-radius), hidden);
		}
		.swiper-slide-active {
			&.swiper-slide {
				opacity: 1;
			}
			.player {
				&-content {
					width: 50%;
					opacity: 1;
					padding: var(--full-space) var(--two-space);
				}
				&-image {
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
				&-wrap {
					height: 100%;
				}
				&-thumbnail {
					height: 100%;
				}
			}
			& ~ .swiper-slide .player-thumbnail {
				margin-left: 0;
			}
		}
		.squad-listing {
			&::before {
				height: calc(100% - 3rem);
				@include border-radius(var(--half-radius));
			}
		}
		.swiper-slide {
			opacity: 0.2;
			@include transition(opacity 300ms linear);
		}
		.player {
			&-wrap {
				padding: 0;
				@include flex-config(flex, null, null, null);
			}
			&-image {
				@include transition(height 300ms linear);
				width: 80%;
			}
			&-thumbnail {
				margin-left: auto;
				width: 50%;
				@include flex-config(flex, null, null, flex-end);
			}
			&-content {
				width: 0;
				opacity: 0;
				padding: 0;
				overflow: hidden;
				background: unset;
				position: unset;
				@include transition(width 200ms linear, opacity 500ms linear);
				@include flex-config(flex, column, flex-end, null);
			}
		}
		.first-name {
			@include font(52);
		}
		.last-name {
			@include font(38);
		}
		.player-stats-title {
			@include font(10);
		}
		.player-stats-count {
			@include font-style(48, 30);
		}
		.squad-item {
			&.overseas {
				.icon {
					width: 3.5rem;
					height: 3.5rem;
					&::before {
						font-size: 1.7rem;
					}
				}
			}
		}
	}
	.waf-squad.home-squad-list {
		.swiper-button-next {
			right: 10rem;
		}
		.swiper-button-prev {
			left: 10rem;
		}
	}
}