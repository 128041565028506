@use "./config" as *;
.dark-mode {
    .waf-row-matches-standings {
        @extend %extra-dark-bg;
    }
    .waf-standings {
        .table {
            @extend %primary-light-bg;
        }
        .title {
            @extend %white-default;
        }
        .head-tab a {
            @extend %white-default;
        }
        .table-head {
            .table-data {
                @extend %primary-light-bg;
            }
            .text {
                @extend %white-default;
            }
        }
        .table-data.position {
            .text {
                @extend %white-default;
                &.qualifier-position {
                    @extend %accent;
                }
            }
        }
        .table-body {
            .text {
                @extend %white-default;
            }
            .table-data.team {
                .text {
                    font-family: $font-secondary-bold;
                }
            }
        }
        .table-row.highlight {
            .table-row-wrap {
                @extend %button-primary-bg;
            }
            .qualifier-position {
                @include color(white-default);
            }
        }
    }
    .home-standings {
        .table-row {
            &:not(:last-child) {
                @include border(bottom, 1, white-default, 2, solid);
            }
        }
        .table-row-wrap {
            @extend %black-default-bg;
        }
    }
}
.waf-row-matches-standings {
    .row {
        max-width: var(--container-max-width);
        margin: auto;
    }
    .title {
        @extend %white-default;
    }
    .head-tab {
        a {
            @extend %white-default;
        }
    }
}
.head-wrap {
    @extend %w-100;
}
.table {
    &-right {
        .table-body {
            .text {
                @include font(12, $font-medium);
            }
        }
    }
    &-data {
        &.points {
            .text {
                font-family: $font-secondary-bold;
            }
        }
    }
    &-body {
        p,
        .text {
            text-align: center;
            @extend %black-dark;
            @include font(12, $font-medium);
        }
    }
    &-left,
    &-right {
        .table-head .table-data {
            min-height: unset;
        }
    }
}
.home-standings {
    padding: 0;
    margin: var(--full-space) var(--half-space-negative);
    .waf-head {
        @extend %flex-sb-c;
    }
    .title {
        @include color(black-dark);
    }
    .head-tab a {
        font-size: 1.4rem;
        font-family: $font-primary-bold;
        @include color(black-dark);
    }
    .table {
        @extend %radius-half;
        @extend %flex;
        @extend %black-light-bg;
        &-data {
            min-height: 5.5rem;
            padding-inline: 0;
        }
        &-head {
            @extend %black-default-bg;
            p,
            .text {
                text-align: center;
                @include font(12, $font-primary-bold);
                @extend %uppercase;
                @extend %black-dark;
            }
            .table-data {
                @extend %white-default-bg-7;
            }
        }
        &-body {
            .table-row:nth-child(odd) .table-data,
            .table-data {
                background-color: transparent;
            }
            .team-name .text {
                @include font(12, $font-secondary-bold);
            }
        }
        &-right {
            .table-body .table-row:nth-child(odd) .table-data {
                background-color: transparent;
            }
            .table-data {
                width: 100%;
                flex-shrink: unset;
            }
        }
        &-row {
            position: relative;
            &:not(:last-child) {
                @include border(bottom, 1, white-default, 8, solid);
            }
            &-wrap {
                @extend %white-default-bg;
            }
            &.highlight {
                .table-row-wrap {
                    @extend %primary-bg;
                }
                .text {
                    @extend %white-default;
                }
            }
        }
    }
    .table-data .qualifier-position {
        font-family: $font-secondary-bold;
        @extend %button-primary;
    }
    .team {
        &-information {
            @extend %gap-half;
        }
        &-image {
            width: 3.5rem;
            height: 3.5rem;
            flex-shrink: 0;
            aspect-ratio: 1/1;
            padding: var(--quater-space);
            @extend %white-default-bg;
            @extend %circle-radius;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .home-standings {
        margin: 0;
        padding: calc(2*var(--full-space)) 0;
        .title,
        .head-tab a {
            @include color(white-default);
        }
        .table-data {
            min-height: 5rem;
        }
        .table-row-wrap {
            @include bg(white-default);
        }
    }
    .dark-mode {
        .home-standings {
            .table-head .table-data {
                @include bg(white-default, 1);
            }
            .table-row-wrap {
                background-color: unset;
            }
            .team-image {
                width: 3rem;
                height: 3rem;
            }
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .home-standings {
        padding: calc(2*var(--full-space)) var(--full-space);
    }
}