@use "./config/" as *;
.dark-mode {
	.waf-fixed-dynamic-content-listing {
		.article-item {
			@extend %black-default-bg;
		}
		.article-title {
			@extend %white-default;
		}
		.price-previous {
			@extend %white-default-7;
		}
		.price-current {
			@extend %white-default;
		}
		&.widget-layout-02 {
			.article-content {
				@extend %black-default-bg;
			}
			.content {
				@extend %white-default-7;
			}
		}
	}
	.waf-listing {
		.meta {
			@extend %white-default-7;
		}
		.article-content {
			a {
				align-self: flex-start;
			}
			@include flex-config(null, null, null, flex-end);
		}
		&.home-photo-list {
			.article-list {
				.article-item {
					@extend %black-default-bg;
					.article-title {
						@extend %white-default;
					}
				}
				.icon-b-share::after {
					@extend %white-default;
				}
				.article-content {
					@extend %black-default-bg;
				}
			}
		}
		&.home-news-list {
			.waf-listing.home-news-list .first-list .article-item {
				background-color: transparent;
			}
		}
		&.home-photo-list {
			@extend %secondary-dark-bg;
			.title {
				@extend %white-default;
			}
			.head-tab {
				a {
					@extend %white-default;
				}
			}
			.first-list {
				.article-item {
					@extend %black-default-bg;
					.article-title {
						@extend %white-default;
					}
					.article-content {
						@extend %black-default-bg;
					}
				}
			}
			.second-list .reaction-section .icon-b-share::after {
				@extend %white-default-7;
			}
		}
	}
	.reaction-section .icon-b-share::after {
		@extend %white-default-7;
	}
}
.social-share {
	.social-share-wrap {
		@include fade-in(close);
	}
	&.active {
		.social-share-wrap {
			@include fade-in(open);
		}
	}
}
.reaction-section {
	position: relative;
	.icon-share {
		font-size: 0;
		&::after {
			content: "\e80a";
			font-size: 1.6rem;
			font-family: $font-icon;
			@extend %accent;
		}
	}
	.icon-b {
		@each $class,
		$icon in $social {
			&-#{$class}::after {
				content: "#{$icon}";
			}
		}
		&-facebook,
		&-twitter,
		&-whatsapp,
		&-copylink,
		&-email,
		&-close {
			&::after {
				font-size: 1.6rem;
				font-family: $font-icon;
				@extend %white-default;
			}
		}
		&-share {
			&::after {
				font-size: 1.5rem;
				font-family: $font-icon;
				@extend %black-default-7;
			}
		}
	}
	.close {
		@extend .social-icon;
	}
	.social {
		&-share-wrap {
			width: max-content;
			right: 0;
			@include center(vertical);
			@extend %black-light-bg;
			.share-label {
				display: none;
			}
		}
		&-wrap {
			@extend %flex-center;
		}
		&-items {
			@extend %flex;
		}
		&-icon {
			background-color: transparent;
			width: 3rem;
			height: 3rem;
			margin: 0 0.25rem;
			@extend %flex-center;
		}
	}
}
.waf-listing,
.waf-fixed-dynamic-content-listing {
	margin: 0 var(--half-space-negative);
	padding: var(--full-space) var(--half-space);
	@extend %vertical-card;
	.article-item {
		@include card-count(1, var(--half-space));
	}
	.article-title {
		height: 5rem;
	}
	.article-content {
		a {
			@extend %w-100;
			align-self: flex-start;
		}
	}
	.head-wrap {
		@extend %w-100;
		@extend %flex-sb-c;
	}
	.title {
		margin: 0;
		@extend %white-default;
		@extend %font-24-primary-b;
	}
	.head-tab {
		a {
			@extend %font-14-primary-r;
			@extend %m-l-full;
			@extend %white-default;
		}
	}
	.article-list {
		// margin: 0 calc(-1 * var(--quater-space));
		overflow-x: scroll;
		@extend %flex;
	}
	.meta {
		@extend %black-default-7;
		@extend %font-10-m;
		span {
			@extend %font-10-m;
		}
		&-date {
			&::before {
				@include icon(calander, 10);
				@extend %m-r-quater;
			}
		}
	}
	.readmore {
		font-size: 0;
		@extend %w-100;
		@extend %h-100;
		@extend %position-top-left;
	}
	&.home-photo-list {
		padding-block: var(--full-space) var(--one-n-half-space);
		@extend %secondary-dark-bg;
		.article-item {
			position: relative;
			overflow: hidden;
			@extend %radius-half;
			@include border-radius(var(--half-radius), hidden);
			@include card-count(1.3, var(--three-fourth-space));
			&:where(:first-child) {
				margin-left: 0;
			}
		}
		.article-list {
			@extend %m-x-half-neg;
			@extend %p-x-half;
		}
		.title {
			font-size: 2.2rem;
			@extend %white-default;
		}
		.head-tab {
			a {
				@extend %white-default;
			}
		}
	}
	&.home-video-list {
		padding-block: var(--full-space) var(--one-n-half-space);
		@extend %primary-dark-bg;
		@extend %vertical-video-card;
		.article-list {
			@extend %m-x-half-neg;
			@extend %p-x-half;
		}
		.article-item {
			border-radius: 1.5rem;
			@include card-count(1.3, var(--three-fourth-space));
			&:where(:first-child) {
				margin-left: 0;
			}
		}
		.article-title {
			@include truncate-vertical-line(20, 2, 2.5rem);
		}
		.item-type-video .article-content .item-type-icon {
			margin-bottom: var(--half-space);
		}
		.premium {
			.article-thumbnail {
				position: relative;
				&::after {
					content: '';
					width: 12rem;
					height: 4rem;
					@include background("membership/mobile/premium.png", no-repeat center/contain);
					@include position(0.5rem, 0, 0, 0.5rem);
				}
			}
		}
		.login-required {
			.article-thumbnail {
				position: relative;
				&::after {
					content: '';
					width: 12rem;
					height: 4rem;
					@include background("membership/mobile/login-required.png", no-repeat center/contain);
					@include position(0.5rem, 0, 0, 0.5rem);
				}
			}
		}
		.preview-swiper {
			.swiper-button {
				&-prev {
					left: 0;
				}
				&-next {
					right: 0;
				}
				&-prev,&-next {
					@extend %position-v-center;
					@extend %d-none;
				}
			}
		}
	}
}
.waf-fixed-dynamic-content-listing {
	.first-list {
		@extend %m-b-half;
	}
}
.waf-listing {
	&.home-news-list {
		overflow-x: unset;
		.title {
			@extend %text;
		}
		.head-tab {
			a {
				@extend %text;
			}
		}
		.article-list {
			@extend %p-b-half;
			@extend %p-x-half;
			@extend %img-shade;
			margin: 0 var(--half-space-negative);
			.article {
				&-item {
					position: relative;
					// flex-basis: calc(100% - 5rem);
					position: relative;
					background-color: transparent;
					@include card-count(1.3, var(--three-fourth-space));
					.reaction-section .icon-b-share::after {
						@extend %white-default;
					}
					&:first-child {
						margin-left: 0;
					}
				}
				&-content {
					width: 100%;
					position: absolute;
					inset: auto 0 0 0;
					@extend %radius-half;
				}
				&-title {
					@extend %white-default;
					@include truncate-vertical-line(18, 2);
				}
				&-meta {
					.meta {
						@extend %white-default;
					}
				}
			}
		}
		.article-thumbnail {
			@extend %img-shade;
		}
		.img-box {
			border-radius: var(--half-radius);
		}
	}
	&.home-tv-list {
		@extend %black-light-bg;
		.waf-body {
			margin: 0 var(--half-space-negative);
		}
	}
	&.home-photo-list {
		background-position: center;
	}
	&.widget-layout-01 {
        .swiper-button-next,.swiper-button-prev {
            @extend %d-none;
        }
    }
}
.login-unlock {
	.waf-listing.home-video-list {
		.login-required .article-thumbnail {
			position: relative;
			&::after {
				content: '';
				width: 3rem;
				height: 3rem;
				@include background("login_unlock.png", no-repeat center/contain);
				@include position(.5rem, 0, 0, .5rem);
			}
		}
	}
}
@media screen and (min-width: $tablet-min-width) {
	.dark-mode {
		.waf-listing.home-photo-list {
			.first-list {
				.article-item {
					&:first-child {
						.article-content {
							background-color: transparent;
						}
					}
				}
			}
		}
	}
	.reaction-section .icon-b-share::after {
		@include color(white-default, 10);
	}
	.waf-listing {
		&.home-news-list {
			.waf-body {
				overflow-x: unset;
				gap: 0 var(--full-space);
			}
			.article {
				&-list {
					overflow: hidden;
					@include grid(repeat(3, 1fr));
					.article-item {
						width: 100%;
						margin-inline: 0;
					}
				}
				&-title {
					height: 4.4rem;
					margin-bottom: var(--full-space);
				}
			}
		}
		&.home-photo-list {
			padding-block: var(--full-space);
			.waf-body {
				overflow-x: scroll;
				gap: 0 var(--half-space);
				@include flex();
			}
			.article-list {
				margin: 0;
				width: 100%;
				flex-basis: 100%;
				overflow: hidden;
				padding: 0;
				@include grid(repeat(3, 1fr), var(--full-space));
				.reaction-section {
					margin-top: auto;
					.icon-b-share::after {
						@include color(text, 7);
					}
				}
				.article-item {
					width: 100%;
					height: auto;
					flex-shrink: 0;
					height: auto;
					position: relative;
					padding: 0;
					margin-inline: 0;
					@include vertical-card();
					&:first-child {
						margin-bottom: 0;
					}
					.article {
						&-wrap {
							height: 100%;
							@include flex-config(flex, column, nul, null);
						}
						&-meta {
							align-self: flex-end;
						}
						&-content {
							height: 100%;
						}
						&-thumbnail {
							width: 100%;
							margin: 0;
						}
						&-content {
							width: 100%;
							transform: unset;
							position: unset;
						}
					}
				}
			}
		}
		&.home-video-list {
			padding: var(--full-space);
			.waf-head .head-wrap {
				margin-bottom: var(--full-space);
			}
			.waf-body {
				margin-left: var(--half-space-negative);
				margin-right: var(--half-space-negative)
			}
			.article-item {
				@include card-count(3, var(--full-space));
			}
			.item-type-video .article-content .item-type-icon {
				margin-bottom: var(--half-space);
			}
			.premium {
				.article-thumbnail::after {
					width: 11rem;
					height: 3rem;
					top: 2rem;
					@include background("membership/premium.png", no-repeat center/contain);
				}
			}
			.login-required {
				.article-thumbnail::after {
					width: 11rem;
					height: 3rem;
					top: 2rem;
					@include background("membership/login-required.png", no-repeat center/contain);
				}
			}
			.waf-body {
				&:has(.swiper-initialized) {
					margin-inline: 0;
					position: relative;
				}
			}
			.preview-swiper {
				&.swiper-initialized  {
					position: static;
					.article-list {
						overflow-x: unset;
					}
				}
				.article-item {
					margin-left: 0;
					margin-right: 0;
					width: 33.33%;
					flex-basis: unset;
					margin-right: var(--space-3);
				}
				.article-list {
					gap: 0;
				}
				.swiper-button {
					&-prev {
						left: var(--space-4-neg);
					}
					&-next {
						right: var(--space-4-neg);
					}
					&-prev,&-next {
						@include flex-config(flex);
					}
				}
			}
		}
	}
	.login-unlock {
		.waf-listing.home-video-list {
			.login-required .article-thumbnail {
				&::after {
					content: '';
					width: 3rem;
					height: 3rem;
					top: 1rem;
					left: 1rem;
					@include background("login_unlock.png", no-repeat center/contain);
				}
			}
		}
	}
}
@media screen and (min-width: $desktop-min-width) {
	.waf-listing {
		&.home-photo-list {
			.waf-body {
				overflow-x: unset;
				gap: 0 var(--full-space);
			}
			.article-list {
				@include grid(repeat(4, 1fr), var(--full-space));
			}
		}
		&.home-video-list {
			padding-inline: var(--half-space);
			.waf-head,
			.waf-body {
				max-width: var(--container-max-width);
				margin: auto;
			}
			.article-list {
				overflow-x: unset;
				padding: 0;
			}
			.article-item {
				@include card-count(4, var(--full-space));
			}
			.waf-body {
				&:has(.swiper-initialized) {
					margin-inline: auto;
				}
			}
			.preview-swiper {
				.article-list {
					padding-inline: var(--half-space);
				}
			}
		}
	}
}