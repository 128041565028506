@use "../config" as *;
.waf-firework {
    z-index: var(--z-confetti-image);
    @include bg(black-dark, 8);
    @include position(0, null, null, 0, fixed);
    @extend %d-none;
    @extend %w-100;
    @extend %h-100;
    &::after {
        left: 0;
        bottom: 0;
        transform: translate(-50%, 50%);
        animation: rotate 40s linear infinite;
    }
    &::before {
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
        animation: rotate-alt 40s linear infinite;
    }
    .team {
        &-winner {
            position: absolute;
            inset: 0;
            @extend %m-auto;
            @extend %w-100;
        }
    }
    .close-btn {
        width: 2.4rem;
        height: 2.4rem;
        @include position(3rem, 1.5rem, null, null);
        @extend %accent-bg;
        @extend %radius-half;
    }
    .btn-text {
        @extend %flex-c-c;
        &::before {
            content: "\e812";
            font-family: $font-icon;
            font-size: .8rem;
            @extend %white-default;
        }
    }
    .firework,
    .firework::before,
    .firework::after {
        --initialSize: 0.5vmin;
        --finalSize: 50vmin;
        --particleSize: .4rem;
        --color1: rgb(255, 255, 255);
        --color2: var(--team-primary, var(--color-primary));
        --color3: rgb(255, 255, 255);
        --color4: var(--team-primary, var(--color-primary));
        --color5: rgb(255, 255, 255);
        --color6: var(--team-primary, var(--color-primary));
        --y: -30vmin;
        --x: -50%;
        --initialY: 60vmin;
        content: "";
        mix-blend-mode: color-dodge;
        animation: firework 6s infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, var(--y));
        width: var(--initialSize);
        aspect-ratio: 1;
        background: radial-gradient(circle,
                var(--color1) var(--particleSize),
                var(--color-secondary-light) 0) 50% 0%,
            radial-gradient(circle, var(--color2) var(--particleSize), var(--color-secondary-light) 0) 100% 50%,
            radial-gradient(circle, var(--color3) var(--particleSize), var(--color-secondary-light) 0) 50% 100%,
            radial-gradient(circle, var(--color4) var(--particleSize), var(--color-secondary-light) 0) 0% 50%,
            /* bottom right */
            radial-gradient(circle, var(--color5) var(--particleSize), var(--color-secondary-light) 0) 80% 90%,
            radial-gradient(circle, var(--color6) var(--particleSize), var(--color-secondary-light) 0) 95% 90%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 90% 70%,
            radial-gradient(circle, var(--color2) var(--particleSize), var(--color-secondary-light) 0) 100% 60%,
            radial-gradient(circle, var(--color3) var(--particleSize), var(--color-secondary-light) 0) 55% 80%,
            radial-gradient(circle, var(--color4) var(--particleSize), var(--color-secondary-light) 0) 70% 77%,
            /* bottom left */
            radial-gradient(circle, var(--color5) var(--particleSize), var(--color-secondary-light) 0) 22% 90%,
            radial-gradient(circle, var(--color6) var(--particleSize), var(--color-secondary-light) 0) 45% 90%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 33% 70%,
            radial-gradient(circle, var(--color2) var(--particleSize), var(--color-secondary-light) 0) 10% 60%,
            radial-gradient(circle, var(--color3) var(--particleSize), var(--color-secondary-light) 0) 31% 80%,
            radial-gradient(circle, var(--color4) var(--particleSize), var(--color-secondary-light) 0) 28% 77%,
            radial-gradient(circle, var(--color5) var(--particleSize), var(--color-secondary-light) 0) 13% 72%,
            /* top left */
            radial-gradient(circle, var(--color6) var(--particleSize), var(--color-secondary-light) 0) 80% 10%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 95% 14%,
            radial-gradient(circle, var(--color2) var(--particleSize), var(--color-secondary-light) 0) 90% 23%,
            radial-gradient(circle, var(--color3) var(--particleSize), var(--color-secondary-light) 0) 100% 43%,
            radial-gradient(circle, var(--color4) var(--particleSize), var(--color-secondary-light) 0) 85% 27%,
            radial-gradient(circle, var(--color5) var(--particleSize), var(--color-secondary-light) 0) 77% 37%,
            radial-gradient(circle, var(--color6) var(--particleSize), var(--color-secondary-light) 0) 60% 7%,
            /* top right */
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 22% 14%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 45% 20%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 33% 34%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 10% 29%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 31% 37%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 28% 7%,
            radial-gradient(circle, var(--color1) var(--particleSize), var(--color-secondary-light) 0) 13% 42%;
        background-size: var(--initialSize) var(--initialSize);
        background-repeat: no-repeat;
    }
    .firework::before {
        --x: -50%;
        --y: -50%;
        --initialY: -50%;
        /*   transform: translate(-20vmin, -2vmin) rotate(40deg) scale(1.3) rotateY(40deg); */
        transform: translate(-50%, -50%) rotate(40deg) scale(1.3) rotateY(40deg);
        /*   animation: fireworkPseudo 2s infinite; */
    }
    .firework::after {
        --x: -50%;
        --y: -50%;
        --initialY: -50%;
        /*   transform: translate(44vmin, -50%) rotate(170deg) scale(1.15) rotateY(-30deg); */
        transform: translate(-50%, -50%) rotate(170deg) scale(1.15) rotateY(-30deg);
        /*   animation: fireworkPseudo 2s infinite; */
    }
    .firework:nth-child(2) {
        --x: 30vmin;
    }
    .firework:nth-child(2),
    .firework:nth-child(2)::before,
    .firework:nth-child(2)::after {
        --finalSize: 40vmin;
        left: 30%;
        top: 60%;
        animation-delay: -0.25s;
    }
    .firework:nth-child(3) {
        --x: -30vmin;
        --y: -50vmin;
    }
    .firework:nth-child(3),
    .firework:nth-child(3)::before,
    .firework:nth-child(3)::after {
        left: 70%;
        top: 60%;
        animation-delay: -0.4s;
    }
    .firework:nth-child(4),
    .firework:nth-child(4)::before,
    .firework:nth-child(4)::after {
        // --color1: rgb(255, 255, 255);
        // --color2: var(--team-primary,rgb(167, 0, 167));
        // --color3: rgb(255, 255, 255);
        // --color4: var(--team-primary,rgb(167, 0, 167));
        // --color5: rgb(255, 255, 255);
        // --color6: var(--team-primary,rgb(167, 0, 167));
        --finalSize: 35vmin;
        left: 20%;
        top: 30%;
        animation-delay: -0.4s;
    }
    .firework:nth-child(5),
    .firework:nth-child(5)::before,
    .firework:nth-child(5)::after {
        --finalSize: 35vmin;
        left: 70%;
        top: 60%;
        animation-delay: -0.7s;
    }
    .firework:nth-child(6),
    .firework:nth-child(6)::before,
    .firework:nth-child(6)::after {
        --finalSize: 35vmin;
        left: 80%;
        top: 40%;
        animation-delay: -0.6s;
    }
    .firework:nth-child(7),
    .firework:nth-child(7)::before,
    .firework:nth-child(7)::after {
        --finalSize: 35vmin;
        left: 30%;
        top: 60%;
        animation-delay: -0.6s;
    }
    .firework:nth-child(8),
    .firework:nth-child(8)::before,
    .firework:nth-child(8)::after {
        --finalSize: 35vmin;
        left: 40%;
        top: 60%;
        animation-delay: -0.4s;
    }
    .firework:nth-child(9),
    .firework:nth-child(9)::before,
    .firework:nth-child(9)::after {
        --finalSize: 35vmin;
        left: 90%;
        top: 75%;
        animation-delay: -0.4s;
    }
    .firework:nth-child(10),
    .firework:nth-child(10)::before,
    .firework:nth-child(10)::after {
        --finalSize: 35vmin;
        left: 10%;
        top: 80%;
        animation-delay: -0.4s;
    }
    .firework:nth-child(11),
    .firework:nth-child(11)::before,
    .firework:nth-child(11)::after {
        --finalSize: 35vmin;
        left: 5%;
        top: 25%;
        animation-delay: -0.4s;
    }
    .firework:nth-child(12),
    .firework:nth-child(12)::before,
    .firework:nth-child(12)::after {
        --finalSize: 35vmin;
        left: 50%;
        top: 30%;
        animation-delay: -0.4s;
    }
    .firework:nth-child(13),
    .firework:nth-child(13)::before,
    .firework:nth-child(13)::after {
        --finalSize: 35vmin;
        left: 90%;
        top: 35%;
        animation-delay: -0.4s;
    }
}
.firework-active {
    .waf-firework {
        display: block;
        isolation: isolate;
        // animation: hideFireWork 10s forwards;
        .firework,
        .firework::before,
        .firework::after {
            --color-2: var(--color-primary);
            --color-4: var(--color-primary);
            --color-6: var(--color-primary);
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-firework {
        .team-winner {
            top: -25rem;
        }
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-firework {
        .team {
            &-section {
                padding-inline: var(--full-space);
            }
            &-name {
                font-size: 2rem;
            }
            &-winner {
                top: 0;
            }
        }
        .banner-image {
            height: 100%;
            object-fit: cover;
        }
        .close-btn {
            width: 3.6rem;
            height: 3.6rem;
            right: 7rem;
        }
    }
}
//