@use "./config" as *;
.waf-row-matches-standings {
    @extend %white-default-bg;
}
.dark-mode {
    .waf-row-matches-standings {
        @extend %black-light-bg;
    }
}
.waf-scorestrip.widget-layout-08,
.waf-fixtures {
    .swiper {
        position: relative;
        &-button-next,
        &-button-prev {
            display: none;
            @extend %border-gray-bg;
            @include center(vertical);
            &::after {
                @extend %button-primary;
            }
        }
        &-button-prev {
            left: 0;
        }
        &-button-next {
            right: 0;
        }
        &-3d .swiper-slide-shadow-right,
        .swiper-slide-shadow-left {
            background-image: unset;
        }
    }
    .accordion-head {
        display: none;
    }
    .time-text {
        height: 8.5rem;
        @extend %flex-n-c;
    }
    .score {
        @extend %m-t-half;
    }
    .team {
        &-image {
            width: 8.5rem;
            height: 8.5rem;
            aspect-ratio: 1/1;
        }
        &-data {
            @extend %flex-column-h-center;
        }
    }
    .card {
        &-head {
            @extend %flex-column-h-center;
        }
        &-item {
            .card-head {
                @extend %m-t-quater;
            }
        }
        &-label {
            @extend %capitalize;
            @extend %white-default;
            @extend %font-12-secondary-b;
            @extend %m-b-quater;
        }
    }
}
.waf-scorestrip.widget-layout-08 {
    .swiper-wrapper {
        pointer-events: none;
    }
    .swiper-slide-active,
    .swiper-slide-next,
    .swiper-slide-prev {
        pointer-events: all;
    }
    padding-block: var(--full-space);
    &::before {
        content: "";
        position: absolute;
        inset: 0 -2rem;
        @extend %black-light-bg;
    }
    .accordion-body {
        @extend %m-b-full;
    }
    .title,
    .head-tab {
        z-index: var(--z-index1);
    }
    .head-tab a {
        font-size: 1.4rem;
        font-family: $font-primary-bold;
    }
    .waf-head {
        @extend %m-b-half;
    }
    .team-name {
        @extend %d-none;
    }
    .countdown-wrapper .icon {
        @extend %p-zero;
        @extend %flex-c-c;
        &::after {
            font-size: 2.5rem;
        }
    }
    .team-logo {
        width: 6rem;
        height: 6rem;
    }
    .countdown-label {
        @extend %uppercase;
    }
    .swiper-slide {
        @include shadow(0 0 1.2rem hsl(var(--color-hsl-black-light)/0.5));
    }
    .card {
        &-item {
            height: 47.3rem;
            margin-top: 1.2rem;
            @extend %flex-column;
            .team-wrapper {
                margin-bottom: var(--full-space);
            }
            &.upcoming {
                .card-footer::before {
                    @extend %d-none;
                }
                .countdown-wrapper::after {
                    display: block;
                }
                .card-footer-text {
                    width: max-content;
                    max-width: 100%;
                    @extend %m-x-auto;
                    &::before {
                        content: "\e835";
                        @include font-style(15, null, $font-icon);
                        @extend %white-default;
                        @extend %m-r-quater;
                    }
                }
            }
            .card-footer {
                &::before {
                    top: var(--half-space-negative);
                }
                @extend %m-t-auto;
                &-text {
                    line-height: 1.6rem;
                    @extend %m-t-half;
                    @extend %m-b-full;
                }
                .btn-more {
                    @extend %w-100;
                    &:nth-child(2) {
                        width: 100%;
                        border-color: var(--color-white-default);
                        background-color: transparent;
                        @extend %m-t-half;
                        .btn-text {
                            @extend %white-default;
                        }
                    }
                }
            }
            .meta {
                @extend %white-default;
                &:first-child::after {
                    height: 100%;
                    @include color(white-default, 5);
                }
            }
            &::after {
                bottom: -2.5rem;
                opacity: .6;
                z-index: var(--z-fixture-pattern);
            }
        }
        &-number {
            @extend %m-b-quater;
            .text,
            .number {
                @extend %font-12-m;
                @extend %white-default;
            }
        }
    }
    .card-item.super-over {
        .score {
            @include font-style(20, 20);
        }
        .overs {
            @include font-style(12, 20);
        }
        .score-super-over .score {
            @extend %m-t-zero;
        }
        .team-current,
        .team-won {
            .score {
                @extend %white-default-3;
            }
            .overs {
                @extend %white-default-3;
            }
            .team-data:last-child {
                .score {
                    @extend %white-default;
                }
                .overs {
                    @extend %white-default;
                }
            }
        }
        .team-data {
            position: relative;
            @include flex-config(null, row, null, flex-end);
            &:not(:first-child) {
                @extend %m-t-quater;
                &::before {
                    content: "";
                    height: 0.1rem;
                    width: 100%;
                    top: -0.3rem;
                    @extend %white-default-bg-1;
                    @extend %position-h-center;
                }
            }
        }
        .team-a .overs {
            @extend %m-l-quater;
        }
        .team-b {
            .team-data {
                @include flex-config(null, row-reverse);
            }
            .overs {
                @extend %m-r-quater;
            }
        }
    }
    .card-item.impact-day-style {
        animation: unset;
        @include background("cssimages/homepage/foundation-home-fixture.png", center/cover, linear-gradient(180deg, var(--color-primary-dark) 0%, var(--color-primary-dark) 44.27%, var(--color-primary) 100%));
        .card-footer .btn-more {
            border: 0;
            background: var(--color-secondary-dark);
        }
    }
}
.head-wrap {
    justify-content: space-between;
}
@media screen and (min-width: $tablet-min-width) {
    .waf-scorestrip.widget-layout-08 {
        padding-block: calc(2*var(--full-space));
        &::before {
            display: none;
        }
        .waf-head {
            margin-bottom: var(--full-space);
        }
        .accordion-body {
            margin-bottom: 0;
        }
        .score-strip-slider {
            // padding-inline: 8rem;
            width: calc(100% - 10rem);
            margin: auto;
            position: unset;
        }
        .waf-body {
            position: relative;
        }
        .swiper {
            &-button-next,
            &-button-prev {
                @include flex;
            }
        }
    }
    .waf-row-matches-standings {
        @include bg(black-light);
    }
}
@media screen and (min-width: $desktop-min-width) {}