@use "./config" as *;
@use "./action-modal";
@forward "./membership/steps";
%opcity5 {
	opacity: 0.5;
}
%color-black-default {
	@extend %color-black-default;
}
%radio-check-input {
	height: 1.6rem;
	width: 1.6rem;
	pointer-events: none;
	border: 0.1rem solid hsl(var(--color-hsl-text));
	&:after {
		content: "";
		display: none;
	}
}
.dark-mode {
	.tooltip-text {
		@extend %black-default;
	}
	.size-chart-wrap {
		.table-responsive {
			.size-chart-table {
				.table-body {
					.table-row {
						&:last-child {
							.table-data {
								@extend %secondary-bg-1;
							}
						}
					}
				}
			}
		}
	}
}
.custom-form {
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: var(--full-space) var(--half-space);
	@extend %flex;
}
.form-profile-complete {
	.mail,
	.mobile {
		padding-right: var(--half-space);
	}
	.mobile-number .selected-label {
		width: 100%;
		&::after {
			@extend %d-none;
		}
	}
}
.form-login-wrap .form-login {
	.timer {
		@extend %m-t-half;
	}
	.form-head {
		margin-bottom: var(--two-space);
	}
}
.size-chart-wrap {
	// .accordion-title,
	// .arrow-icon {
	// 	@extend %primary;
	// }
	.size-chart {
		flex-wrap: wrap;
		gap: var(--full-space) var(--half-space);
		@extend %flex;
	}
	.size-chart-table {
		@extend %m-t-full;
		@extend %flex-column;
		.table {
			&-head {
				.table-row {
					@extend %secondary-bg;
				}
			}
			&-data {
				flex: 1;
				@extend %secondary-bg-1;
				&:first-child {
					flex-basis: 30%;
					flex-shrink: 0;
					@include flex-config(null, null, flex-start);
					@extend %capitalize;
					@extend %p-x-half;
					.text {
						@extend %capitalize;
					}
				}
			}
		}
	}
	.accordion-title {
		@extend %font-14-primary-r;
		@extend %label-text;
		@extend %uppercase;
		//@extend %text;
	}
	.waf-accordion-trigger {
		@extend %flex-sb-c;
		@extend %w-100;
		.arrow-icon {
			@extend %flex-c-c;
			@extend %m-l-auto;
			@extend %label-text;
			@extend %p-l-half;
			&::before {
				width: 1rem;
				content: "\e814";
				transform-origin: center;
				flex-shrink: 0;
				@include font-style(14, 14, $font-icon);
				@include transition(0.3s all);
			}
		}
		&[aria-expanded="true"] {
			.arrow-icon {
				&::before {
					@include rotate(180);
				}
			}
		}
	}
	.waf-accordion-title {
		@extend %flex-sb-c;
	}
}
.waf-form-section {
	padding: var(--full-space) var(--half-space);
	background: var(--color-black);
	margin: 0 var(--half-space-negative);
	overflow: hidden;
	position: relative;
	isolation: isolate;
	background: url("/static-assets/images/cssimages/foundation/merchant-bg-m.jpg?v=#{$image-version}") no-repeat center fixed;
	background-size: cover;
	.restore-account,
	.form-register,
	.delete-account {
		.btn-fill,
		.btn-outline {
			text-transform: uppercase;
			.btn-text {
				text-transform: uppercase;
			}
		}
	}
	// &::after,
	// &::before {
	// 	content: "";
	// 	background: url(/static-assets/images/svg/pattern-02.svg?v=#{$image-version})
	// 		no-repeat;
	// 	position: absolute;
	// 	width: 40rem;
	// 	height: 40rem;
	// 	background-size: cover;
	// 	z-index: var(--z-index1-negative);
	// }
	// &::before {
	// 	left: -128%;
	// 	top: -10%;
	// 	width: 70rem;
	// 	height: 70rem;
	// }
	// &::after {
	// 	bottom: -10%;
	// 	right: -50%;
	// 	transform: rotate(100deg);
	// }
	.client-logo {
		height: 12rem;
		width: 100%;
		// padding-top: var(--half-space);
		// background: linear-gradient(90deg, hsl(var(--color-hsl-primary)) 0%, hsl(var(--color-hsl-secondary)) 121.25%);
		position: relative;
		@include flex-config(flex, null, center, center);
		@extend %gradient-bg;
		&:after {
			content: "";
			background: url(/static-assets/images/svg/logo.svg?v=#{$image-version}) center no-repeat;
			width: 100%;
			height: 100%;
			position: absolute;
			background-size: 162%;
			opacity: 0.05;
			background-position: center 15%;
		}
		.logo {
			width: 5rem;
			height: auto;
		}
	}
}
.benefit-info {
	@extend %flex-column;
}
.form-head {
	flex-wrap: wrap;
	@extend %flex-sb-c;
	&-wrap {
		width: 100%;
		flex-shrink: 0;
	}
	.link-text {
		text-decoration: underline;
		cursor: pointer;
		@extend %font-16-primary-r;
	}
}
.link-benefit {
	display: block;
	@extend %m-b-full;
	@extend %font-16-primary-r;
	&::before {
		content: "\e821";
		@extend %m-r-quater;
		@include font-style(14, 14, $font-icon);
	}
}
.membership-button .btn-fill {
	@extend %w-100;
	@extend %m-b-full;
}
.flag-label {
	position: relative;
	display: block;
	width: max-content;
	height: 4.6rem;
	@extend %m-l-full-neg;
	@extend %m-b-full;
	@extend %accent-bg;
	@extend %white-default;
	@extend %font-20-primary-r;
	@extend %p-y-half;
	@extend %p-x-full;
	isolation: isolate;
	&::before {
		content: "";
		width: calc(100% + 1.5rem);
		height: 0.3rem;
		bottom: -0.2rem;
		position: absolute;
		left: -0.2rem;
		transform: skew(50deg);
		@extend %primary-dark-bg;
	}
	&::after {
		content: "";
		width: 2rem;
		height: 4.5rem;
		clip-path: polygon(100% 0, 0 50%, 100% 100%, 0 100%, 0 0);
		position: absolute;
		top: 0;
		right: -2rem;
		@extend %accent-bg;
	}
}
.form-area {
	min-height: calc(var(--window-inner-height) - (var(--header-height) + var(--two-space)));
	position: relative;
	overflow: hidden;
	@include flex-config(flex, column, center, null);
	.social-login {
		.sub-title {
			position: relative;
			text-align: center;
			margin: var(--full-space) 0;
			@extend %text;
			@extend %font-14-primary-r;
			&::before,
			&::after {
				content: "";
				width: 35%;
				height: 0.1rem;
				background-color: hsl(var(--color-hsl-text));
				opacity: 0.2;
				@include center(vertical);
			}
			&::before {
				left: 0;
			}
			&::after {
				right: 0;
			}
			.text {
				position: relative;
				padding: 0 var(--half-space);
				background-color: hsl(var(--color-hsl-page-background));
			}
		}
		.btn-social {
			width: 3.5rem;
			height: 3.5rem;
			margin: 0 var(--full-space);
			background-color: hsl(var(--color-hsl-white-default));
			@extend %circle;
			.btn-text {
				font-size: 0;
				display: none;
			}
			&::after {
				content: "";
				font-family: $font-icon;
				font-size: 2.4rem;
				@extend %black-light;
			}
			&.btn-facebook {
				&::after {
					content: "\e816";
				}
			}
			&.btn-gmail {
				&::after {
					content: "\e825";
				}
			}
			&.btn-apple {
				&::after {
					content: "\e822";
				}
			}
		}
		.social-list {
			text-align: center;
		}
		.new-user {
			margin: var(--full-space) 0;
			flex-wrap: wrap;
			@extend %flex-center;
			.sub-title {
				margin: 0;
				@extend %font-14-primary-r;
				@extend %w-100;
				.btn-register {
					@extend %font-14-primary-b;
				}
				&::before,
				&::after {
					width: 26%;
				}
			}
			.btn-register,
			.btn-login {
				padding: 0.8rem 7rem;
				margin: var(--half-space) auto;
			}
			.btn-text {
				// padding-left: calc(var(--half-space) / 2);
				@extend %white-default;
				@extend %font-14-primary-b;
				line-height: 1;
			}
		}
	}
	.form {
		&-head {
			margin-bottom: var(--full-space);
			.form-title {
				background: linear-gradient(90deg,
						hsl(var(--color-hsl-primary-dark)) 0.93%,
						hsl(var(--color-hsl-secondary-dark)) 100%);
				-webkit-text-fill-color: transparent;
				-webkit-background-clip: text;
				text-align: center;
				@extend %font-28-primary-b;
				margin-block: var(--quater-space);
			}
			.sub-title {
				text-align: center;
				@extend %font-14-primary-r;
				@extend %text;
			}
		}
		&-login-wrap {
			@extend %flex;
			@include transition(0.3s all);
			&.show-forgot {
				transform: translateX(-100%);
			}
		}
		&-list {
			margin-top: var(--full-space-negative);
			position: relative;
			@extend %page-background-bg;
			@include border-radius(1rem, hidden);
		}
		&-wrapper {
			padding: var(--full-space);
			width: 100%;
			align-self: baseline;
			flex-shrink: 0;
			.terms {
				.sub-title,
				.link-text {
					@extend %text;
					@extend %font-12-primary-r;
				}
				.link-text {
					text-decoration: underline;
					line-height: 1.6;
					color: hsl(var(--color-hsl-accent));
				}
			}
		}
	}
	.back-login {
		text-align: center;
		margin: var(--full-space) 0 0;
		.btn-text {
			color: hsl(var(--color-hsl-secondary));
			@extend %font-12-primary-r;
			&::before {
				content: "\e803";
				font-family: $font-icon;
				padding-right: calc(var(--half-space) / 2);
				@include font(8, null);
			}
		}
	}
}
.login-button,
.register-button,
.reset-button {
	margin: var(--full-space) 0;
	button {
		width: 100%;
		text-transform: uppercase;
		.btn-text,
		span {
			text-transform: uppercase;
		}
	}
}
.gender {
	.control-label {
		color: hsl(var(--color-hsl-black-light) / 0.5);
		@extend %font-14-primary-r;
	}
}
.apply-btn {
	button {
		width: 100%;
	}
}
.whatsapp {
	@extend %flex-n-c;
	.text {
		@extend %m-r-auto;
	}
	.btn-outline {
		@extend %p-y-quater;
		@extend %m-l-half;
	}
}
.mobile-number {
	width: 100%;
	height: 100%;
	@extend %flex-v-center;
	select {
		width: 4rem;
		cursor: pointer;
		@include position(0, 0, 0, null);
	}
	.selected-label {
		position: relative;
		height: 100%;
		width: 5rem;
		z-index: var(--z-index1);
		padding-right: 1rem;
		pointer-events: none;
		border-right: 0.1rem solid hsl(var(--color-hsl-black-default) / 0.2);
		inset: 0;
		@extend %form-field-bg;
		@extend %font-12-primary-r;
		@include flex-config(flex, null, center, center);
		&:after {
			content: "\e814";
			font-family: $font-icon;
			font-size: 0.7rem;
			transform: translateY(-50%);
			@include position(50%, 0.5rem, null, null);
		}
	}
	.select {
		&-box {
			position: relative;
			width: 7rem;
			height: 100%;
			@extend %flex-v-center;
		}
	}
	.mobile-number-wrapper {
		position: relative;
		width: calc(100% - 8rem);
		height: 100%;
		padding: 0 var(--half-space);
	}
	.country-flag {
		width: 2.1rem;
		height: 1.4rem;
		object-fit: contain;
	}
}
.error-msg {
	display: none;
	// color: var(--color-error-text);
	// @extend %font-10-secondary-b;
	// &:empty {
	// 	display: none;
	// }
}
.errordiv {
	@extend %error-text;
	@extend %font-10-m;
}
.global {
	&-error,
	&-success {
		.message {
			display: block;
			@extend %font-14-primary-r;
			@extend %p-half;
		}
	}
}
.btn-forgot {
	@extend %secondary;
	@extend %font-12-primary-r;
}
.show-password-icon {
	@extend %opcity5;
	&::after {
		content: "\e823";
		font-family: $font-icon;
		color: hsl(var(--color-hsl-text) / 1);
	}
}
.show-password[aria-expanded="true"] {
	.show-password-icon::after {
		content: "\e827";
	}
}
.edit-icon {
	@extend %opcity5;
	&::after {
		content: "\e861";
		font-family: $font-icon;
		color: hsl(var(--color-hsl-text) / 1);
	}
}
.timer {
	color: hsl(var(--color-hsl-black-light));
	font-size: 0.9rem;
	font-family: $font-primary-regular;
	line-height: 1;
	margin-top: 0.2rem;
	@extend %flex;
	// @include position(100%, null, null, null);
}
button {
	&.disabled {
		filter: grayscale(1);
		opacity: 0.5;
		pointer-events: none;
	}
}
.resend {
	justify-content: center;
	align-items: center;
	// gap: var(--quater-space) 0;
	@extend %flex-column;
	button {
		width: 100%;
		padding: var(--quater-space) 0;
	}
	.btn-outline {
		padding-block: var(--half-space);
	}
}
.otp {
	.title {
		background: linear-gradient(90deg,
				hsl(var(--color-hsl-primary-dark)) 0.93%,
				hsl(var(--color-hsl-secondary-dark)) 100%);
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
	}
	.text {
		@extend %m-b-half;
	}
	&.verify {
		.check {
			display: block;
		}
	}
	.check {
		width: 1.6rem;
		height: 1.6rem;
		display: inline-block;
		position: relative;
		margin-left: var(--full-space);
		flex-shrink: 0;
		border: 0.1rem solid var(--color-success-text);
		@include border-radius(0.2rem);
		&:after {
			content: "";
			width: 0.4rem;
			height: 0.7rem;
			border: solid var(--color-success-text);
			border-width: 0 0.1rem 0.1rem 0;
			@include rotate(45);
			@include position(0.2rem, null, null, 0.5rem);
		}
	}
	.form-element {
		border: none;
		padding: 0;
		background-color: transparent;
		@include flex-config(null, null, flex-start, null);
	}
	&-wrap {
		width: calc(18rem + calc(var(--half-space) * 5));
		position: relative;
		flex-shrink: 0;
		gap: var(--half-space);
		@extend %flex-v-center;
		.form-element {
			width: 3rem;
			height: 3rem;
			flex-shrink: 0;
			border: 0.1rem solid hsl(var(--color-hsl-black-light) / 0.2);
			@extend %font-18-primary-r;
			color: hsl(var(--color-hsl-black-light) / 0.5);
			@include border-radius(0.5rem);
			@include flex-config(inline-flex, null, center, center);
			.form-control {
				text-align: center;
				padding: 0;
			}
		}
	}
	// &-element {
	//     &-wrap {
	//         gap: var(--half-space);
	//         @include position(0, 0, 0, 0);
	//         @include flex-config(flex, null, null, null);
	//     }
	// }
}
select {
	background: transparent;
	border: 0;
	width: 100%;
	outline: none;
}
.checkbox-container,
.radio-container {
	@extend %flex-v-center;
}
.checkbox-container,
.radio-box {
	.checkbox,
	.form-radio-input {
		position: absolute;
		width: 1.6rem;
		height: 1.6rem;
		opacity: 0;
		cursor: pointer;
		&:checked {
			~ .checkmark,
			~ .radiomark {
				border-color: hsl(var(--color-hsl-button-primary));
				&:after {
					display: block;
				}
			}
		}
	}
	.text {
		padding-left: var(--half-space);
		@extend %color-black-default;
		@extend %font-12-primary-r;
	}
	.link-text {
		color: hsl(var(--color-hsl-primary));
		text-decoration: underline;
		@extend %font-12-primary-b;
	}
}
.radio-box {
	margin-inline: var(--half-space);
	&:first-child {
		margin-inline-start: 0;
	}
	&-wrap {
		.radiomark {
			flex-shrink: 0;
			@include border-radius(100%);
			@extend %radio-check-input;
			@extend %flex-center;
			/* @include position(0.2rem, null, null, 0); */
			&:after {
				content: "";
				display: none;
				width: 1rem;
				height: 1rem;
				background-color: hsl(var(--color-hsl-button-primary));
				@include border-radius(100%);
				/* @include position(0.2rem, null, null, 0.2rem); */
			}
		}
	}
	.text {
		/* padding-left: 2rem;
		text-transform: capitalize; */
		@extend %text;
		@extend %font-14-primary-r;
	}
}
.checkbox-container {
	.checkmark {
		flex-shrink: 0;
		@include border-radius(0.5rem);
		@extend %radio-check-input;
		@extend %flex-center;
		// @include position(0.3rem, null, null, 0);
		&:after {
			content: "✓";
			display: none;
			color: hsl(var(--color-hsl-button-primary));
			font-size: 1.2rem;
			font-weight: bold;
			/* width: 0.4rem;
			height: 0.6rem;
			border: solid hsl(var(--color-hsl-button-primary));
			border-width: 0 0.1rem 0.1rem 0;
			@include rotate(45);
			@include position(0.8rem, null, null, 0.6rem); */
		}
	}
}
.radio-box-wrap {
	margin-block: var(--quater-space);
	@extend %flex-v-center;
}
.tooltip {
	&-text {
		width: 20rem;
		@extend %color-black-default;
		background: hsl(var(--color-hsl-white-default));
		padding: var(--half-space);
		display: none;
		@include font(10);
		@include position(null, 0, calc(var(--input-height) / 2), null);
		@include shadow(0 0 0.5rem 0 hsl(var(--color-hsl-black-default) / 0.2));
	}
	.show-tooltip {
		@extend %opcity5;
		&-icon {
			&::after {
				content: "\e821";
				font-family: $font-icon;
				color: hsl(var(--color-hsl-text) / 1);
			}
		}
		&[aria-expanded="true"] {
			~ .tooltip-text {
				display: block;
			}
		}
	}
}
.password {
	.btn-group {
		right: var(--half-space);
		@extend %gap-half;
		@include center(vertical);
		@include flex-config(flex, null, null, null);
	}
	input {
		width: calc(100% - 5rem);
	}
}
.form {
	&-control {
		width: 100%;
		height: 100%;
		outline: none;
		border: 0;
		background-color: transparent;
		@extend %font-14-primary-r;
		&:placeholder-shown {
			~ .control-label {
				color: hsl(var(--color-hsl-black-light) / 0.5);
			}
		}
		&:not(:placeholder-shown) {
			padding-top: var(--half-space);
			& ~ .control-label {
				top: 30%;
				@extend %accent;
				@include font(10);
			}
		}
	}
	&-group {
		// margin: var(--half-space) 0;
		position: relative;
		&.forgot-password {
			margin-top: 0;
		}
	}
	&-element {
		position: relative;
		height: var(--input-height);
		background-color: hsl(var(--color-hsl-form-field));
		border: 0.1rem solid hsl(var(--color-hsl-black-light) / 0.2);
		padding: 0 var(--half-space);
		@include border-radius(0.5rem, visible);
		@include flex-config(flex, null, flex-start, center);
		&.form-element-textarea {
			height: auto;
			.control-label {
				transform: unset;
				top: var(--full-space);
			}
			.form-control {
				min-height: 8.5rem;
				min-width: 100%;
				@extend %p-t-full;
				&:not(:placeholder-shown) ~ .control-label {
					top: var(--half-space);
				}
			}
		}
		input {
			@include placeholder(transparent);
		}
		.control-label {
			left: var(--half-space);
			@include transition(0.3s all);
			@extend %font-14-primary-r;
			@include center(vertical);
		}
	}
}
.required-icon {
	font-size: 2rem;
	@include position(0, -0.8rem);
	@extend %error-text;
}
@media (min-width: $tablet-min-width) {
	.form-area {
		width: calc(var(--window-inner-width) * 0.75);
		margin: 0 auto;
		.form-wrapper {
			width: 100%;
			padding: var(--two-space) 6rem;
			margin: 0 auto;
			&.form-login {
				padding-inline: 10rem;
			}
			&.form-donation {
				padding-inline: 0;
			}
		}
		.social-login {
			.sub-title {
				.text {
					padding-left: 0;
				}
			}
		}
		.flag-label {
			margin-left: -6rem;
		}
	}
	.form-register,
	.form-profile-complete {
		/* padding: calc(var(--full-space) * 2); */
		.custom-form {
			/* margin: 0 var(--half-space-negative); */
		}
		.form-group {
			// padding: 0 var(--half-space);
		}
	}
	.size-chart-wrap {
		position: relative;
		.waf-accordion-title {
			position: absolute;
			top: 1rem;
			right: 0;
		}
		.table-responsive {
			margin-bottom: 0;
		}
	}
}
@media (min-width: $desktop-min-width) {
	.timer {
		@include font(10);
	}
	.form-area {
		width: calc(var(--window-inner-width) * 0.6);
		/* min-height: calc(
			var(--window-inner-height) - var(--header-height) - var(--full-space) * 2
		); */
		flex-direction: row;
		.social-login {
			.social-list {
				gap: calc(var(--full-space) * 2);
				@include flex-config(flex, null, null, null);
			}
			/* .sub-title {
				text-align: left;
			} */
			.btn-social {
				margin: 0;
			}
			.new-user {
				@include flex-config(null, null, flex-start, null);
			}
			.sub-title {
				text-align: left;
				&::before {
					display: none;
				}
				&::after {
					width: 72%;
				}
			}
			.new-user {
				flex-wrap: nowrap;
				.sub-title {
					&::after {
						display: none;
					}
				}
				.btn-register,
				.btn-login {
					margin-left: 0;
				}
			}
		}
		.form {
			&-head {
				margin-bottom: var(--half-space);
			}
			&-forgot,
			&-reset {
				.form-head {
					.form-title {
						text-align: center;
					}
				}
			}
			&-list {
				overflow-y: auto;
				margin: 0;
				z-index: var(--z-index1);
			}
			&-wrapper {
				/* align-self: center;
				padding: var(--half-space) calc(var(--full-space) * 4); */
				&.form-register-interest {
					/* align-self: flex-start; */
				}
				&.form-register,
				&.form-profile-complete {
					/* align-self: baseline;
					padding: var(--full-space); */
				}
			}
			&-login-wrap {
				width: 100%;
			}
		}
		.form-head {
			.sub-title {
				text-align: left;
			}
			.form {
				&-title {
					text-align: left;
					@include font(30);
				}
			}
		}
	}
	.btn-forgot {
		padding-left: var(--half-space);
	}
	.tooltip-text {
		font-size: 1.3rem;
		pointer-events: none;
	}
	.waf-form-section {
		.client-logo {
			width: calc((var(--window-inner-width) * 0.6) * 0.35);
			height: calc(var(--window-inner-height) - var(--header-height) - var(--full-space) * 2);
			max-width: 30rem;
			margin: 0;
			flex-shrink: 0;
			@include linear-gradient(268deg, hsl(var(--color-hsl-primary)) 0%, hsl(var(--color-hsl-secondary)) 121.25%);
			margin-right: var(--half-space-negative);
			@include flex-config(flex, null, null, center);
			&:after {
				content: "";
				background-size: 145%;
				background-position: center;
			}
			.logo {
				width: 10rem;
			}
		}
		.form-list {
			width: calc((var(--window-inner-width) * 0.6) * 0.65);
			max-width: 50rem;
			height: calc(var(--window-inner-height) - (var(--header-height) + var(--two-space)));
			// @include flex-config(flex, null, null, center);
			.form-wrapper {
				min-height: 100%;
				@include flex-config(flex, column, center, null);
			}
		}
	}
}
@media (min-width: $large-desktop-min-width) {
	.form-area {
		.social-login {
			.sub-title {
				&::after {
					/* width: 78%; */
				}
			}
		}
	}
}
@media (min-width: $xl-desktop-min-width) {
	.form-area {
		.form-wrapper {
			&.form-reset {
				padding: var(--half-space) calc(var(--full-space) * 6);
			}
		}
	}
	.waf-form-section {
		.client-logo {
			&:after {
				background-size: 250%;
			}
			.logo {
				width: 12rem;
			}
		}
	}
	.form {
		&-head {
			margin-bottom: 2rem;
		}
	}
}
.dark-mode {
	.form-area .form-head .form-title {
		background: hsl(var(--color-hsl-white-default));
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
	}
	.form-element {
		border-color: hsl(var(--color-hsl-white-default) / 0.5);
		background-color: transparent;
		.control-label {
			@include color(white-default, 5);
		}
	}
	// .otp .title {
	// 	background: var(--color-white-default);
	// 	-webkit-background-clip: text;
	// }
	.form-control {
		&:not(:placeholder-shown) {
			& ~ .control-label {
				@extend %accent;
			}
		}
	}
	.waf-form-section {
		background: url("/static-assets/images/cssimages/foundation/merchant-bg-dark-d.png?v=#{$image-version}") no-repeat center fixed;
		background-size: cover;
		.text {
			color: hsl(var(--color-hsl-white-default) / 0.5);
			a {
				@include color(button-primary);
			}
		}
	}
	.mobile-number .selected-label {
		border-right: 0.1rem solid hsl(var(--color-hsl-white-default) / 0.3);
	}
	.waf-contact-page .text-area {
		border-color: hsl(var(--color-hsl-white-default) / 0.5);
	}
	.mobile-number .selected-label {
		@include bg(page-background);
	}
	.mobile-number .selected-label,
	.show-password-icon,
	.show-tooltip-icon,
	.waf-contact-page .text-area,
	.waf-contact-page.waf-registration .text {
		@include color(white-default);
	}
	.back-login {
		.btn-login {
			@include color(secondary-light);
		}
	}
	// select{
	// 	@extend %black-default;
	// 	option{
	// 		@extend %black-default;
	// 	}
	//  }
}
@media (min-width: $tablet-min-width) {
	.waf-form-section {
		background: url("/static-assets/images/cssimages/foundation/merchant-bg-d.png?v=#{$image-version}") no-repeat center fixed;
		background-size: cover;
	}
	.dark-mode {
		.waf-form-section {
			background: url("/static-assets/images/cssimages/foundation/merchant-bg-dark-d.jpg?v=#{$image-version}") no-repeat center fixed;
			background-size: cover;
		}
	}
}