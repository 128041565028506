@use "variables" as *;
[class^="icon-"] {
	font-size: 0;
	font-style: normal;
	color: var(--white-color);
}
[class^="icon-"]::before {
	font-family: $font-icon;
	font-size: 1.8rem;
	font-style: normal;
	font-weight: normal;
	speak: never;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.icon-whatsapp:before {
	content: '\e800';
}
/* '' */
.icon-up-arrow:before {
	content: '\e801';
}
/* '' */
.icon-language:before {
	content: '\e802';
}
/* '' */
.icon-left-arrow:before {
	content: '\e803';
}
/* '' */
.icon-link:before {
	content: '\e804';
}
/* '' */
.icon-login:before {
	content: '\e805';
}
/* '' */
.icon-right-arrow:before {
	content: '\e806';
}
/* '' */
.icon-rupee:before {
	content: '\e807';
}
/* '' */
.icon-scroll-down:before {
	content: '\e808';
}
/* '' */
.icon-search:before {
	content: '\e809';
}
/* '' */
.icon-share:before {
	content: '\e80a';
}
/* '' */
.icon-shop:before {
	content: '\e80b';
}
/* '' */
.icon-subscribe:before {
	content: '\e80c';
}
/* '' */
.icon-tickets:before {
	content: '\e80d';
}
/* '' */
.icon-time:before {
	content: '\e80e';
}
/* '' */
.icon-video:before {
	content: '\e80f';
}
/* '' */
.icon-plus:before {
	content: '\e810';
}
/* '' */
.icon-reset:before {
	content: '\e811';
}
/* '' */
.icon-close:before {
	content: '\e812';
}
/* '' */
.icon-youtube:before {
	content: '\e813';
}
/* '' */
.icon-down-arrow:before {
	content: '\e814';
}
/* '' */
.icon-facebook:before {
	content: '\e815';
}
/* '' */
.icon-facebook-circle:before {
	content: '\e816';
}
/* '' */
.icon-instagram:before {
	content: '\e817';
}
/* '' */
.icon-link-1:before {
	content: '\e818';
}
/* '' */
.icon-linkedin:before {
	content: '\e819';
}
/* '' */
// .icon-twitter:before { content: '\e81a'; } /* '' */
.icon-twitter:before {
	content: '\e8ae';
}
.icon-timer:before {
	content: '\e81b';
}
/* '' */
.icon-photos:before {
	content: '\e81e';
}
/* '' */
.icon-filter:before {
	content: '\e81f';
}
/* '' */
.icon-plane:before {
	content: '\e820';
}
/* '' */
.icon-info:before {
	content: '\e821';
}
/* '' */
.icon-apple:before {
	content: '\e822';
}
/* '' */
.icon-password--show:before {
	content: '\e823';
}
/* '' */
.icon-google:before {
	content: '\e825';
}
/* '' */
.icon-show-password:before {
	content: '\e827';
}
/* '' */
.icon-call:before {
	content: '\e828';
}
/* '' */
.icon-camera:before {
	content: '\e829';
}
/* '' */
.icon-android:before {
	content: '\e82b';
}
/* '' */
.icon-right:before {
	content: '\e82c';
}
/* '' */
.icon-super-over:before {
	content: '\e82d';
}
/* '' */
.icon-stumps:before {
	content: '\e82e';
}
/* '' */
.icon-bat:before {
	content: '\e82f';
}
/* '' */
.icon-ball:before {
	content: '\e830';
}
/* '' */
.icon-in_1:before {
	content: '\e832';
}
/* '' */
.icon-out_1:before {
	content: '\e833';
}
/* '' */
.icon-stadium-2:before {
	content: '\e835';
}
/* '' */
.icon-tick-2:before {
	content: '\e836';
}
/* '' */
.icon-icon:before {
	content: '\e837';
}
/* '' */
.icon-settings:before {
	content: '\e85c';
}
/* '' */
.icon-logout:before {
	content: '\e85d';
}
/* '' */
.icon-restore-account:before {
	content: '\e85e';
}
/* '' */
.icon-delete:before {
	content: '\e860';
}
/* '' */
.icon-edit:before {
	content: '\e861';
}
/* '' */
.icon-notification:before {
	content: '\e862';
}
/* '' */
.icon-search-icon:before 
{ content: '\e841'; } /* '' */