@use "../config/" as *;
.step {
    &-wrapper {
        max-width: 80rem;
        @extend %m-x-auto;
        @extend %p-y-full;
    }
    &-list {
        @extend %flex-c-c;
    }
    &-item {
        position: relative;
        width: 12rem;
        @extend %white-default;
        @extend %p-x-two;
        .icon {
            display: block;
            overflow: hidden;
            @include transition(200ms);
            @extend %circle-radius;
            @extend %w-30;
            @extend %h-30;
            &-wrap {
                width: 3.2rem;
                height: 3.2rem;
                aspect-ratio: 1/1;
                border: 0.2rem solid var(--color-white-default);
                overflow: hidden;
                @extend %flex-c-c;
                @extend %circle-radius;
            }
        }
        .text {
            display: block;
            width: max-content;
            @extend %m-t-half;
            @extend %font-14-primary-r;
        }
        &:not(:last-child) {
            &::after {
                content: "";
                display: block;
                height: 0.2rem;
                width: 0;
                @extend %accent-bg;
                @include position(1.6rem, null, null, 7.2rem);
                @include transition(300ms 200ms);
            }
            &::before {
                content: "";
                width: calc(100% - 3rem);
                display: block;
                height: 0.2rem;
                @extend %white-default-bg;
                @include transition(500ms all);
                @include position(1.6rem, null, null, 7.2rem);
            }
        }
        &.inprogress {
            .icon {
                width: 40%;
                height: 40%;
                @extend %accent-bg;
                &-wrap {
                    border-color: var(--color-accent);
                }
            }
            .text {
                font-family: $font-primary-bold;
            }
        }
        &.completed {
            .icon {
                width: 100%;
                height: 100%;
                @extend %flex-c-c;
                @extend %accent-bg;
                &::before {
                    content: "\e81d";
                    font-family: $font-icon;
                    @extend %accent-bg;
                }
                &-wrap {
                    border-color: var(--color-accent);
                }
            }
            .text {
                font-family: $font-primary-bold;
            }
            &::after {
                width: calc(100% - 3rem);
                @extend %accent-bg;
            }
            &::before {
                @extend %accent-bg;
            }
        }
    }
}