@use "./config" as *;
.dark-mode {
  .waf-fixed-dynamic-content-listing.home-mega-list {
    .article-content {
      background: transparent;
    }
    .price-previous {
      @extend %white-default-4;
    }
    .badge {
      @extend %button-primary-bg;
      &:before {
        content: "";
        border-left: 1.3rem solid var(--color-button-primary);
      }
      &:after {
        content: "";
        background: url(/static-assets/images/cssimages/homepage/tag-dark.png?v=#{$image-version}) no-repeat center / contain;
      }
    }
    .article-item {
      @extend %black-default;
    }
  }
  .waf-fixed-dynamic-content-listing.home-rsb-list {
    &.widget-layout-02 {
      .article-content {
        @include bg(page-background);
      }
    }
  }
}
.waf-fixed-dynamic-content-listing {
  .waf-head {
    .title {
      @extend %white-default;
    }
  }
  .head-tab a {
    font-size: 1.4rem;
    font-family: $font-primary-bold;
  }
  .badge {
    position: absolute;
    top: var(--half-space);
    right: var(--half-space);
    display: inline-block;
    @extend %button-primary-bg;
    @extend %white-default;
    @extend %font-10-secondary-b;
    @extend %p-y-quater;
    @extend %p-x-half;
    @extend %m-b-quater;
  }
  .view-more {
    font-size: 0;
    @extend %w-100;
    // @extend %h-100;
    @extend %position-top-left;
  }
  .price {
    &-section {
      @extend %flex-n-c;
      @extend %gap-half;
    }
    &-previous {
      text-decoration: line-through;
      @extend %text;
      @extend %font-10-m;
    }
    &-current {
      @extend %text;
      @extend %font-14-secondary-b;
    }
  }
  .img-box {
    @include border-radius(0.2rem, hidden);
  }
  .article {
    &-wrap {
      position: relative;
    }
    &-footer {
      padding: 0;
      @include flex-config(flex, null, space-between, flex-end);
    }
    &-title {
      width: 100%;
    }
    &-content {
      background: transparent;
    }
  }
  .content {
    @extend %font-12-m;
    @extend %black-default-7;
    @include truncate-vertical-line(12, 3);
  }
  @extend %vertical-card;
  // verticle card
  .first-list {
    @extend %m-b-full;
    .article-item {
      @include card-count(1, var(--half-space));
    }
  }
  .second-list {
    .article {
      &-item {
        @include card-count(1.5, var(--half-space));
      }
      &-title {
        height: 4rem;
        @extend %font-14-primary-b;
      }
    }
  }
  &.widget-layout-01 {
    .article-thumbnail {
      @extend %p-half;
      padding-bottom: 0;
    }
  }
  &.widget-layout-02 {
    .article {
      &-item {
        @include card-count(1.3, var(--half-space));
      }
      &-title {
        height: 5rem;
        @extend %font-18-primary-b;
      }
      &-content {
        @extend %w-100;
      }
    }
    .price {
      &-previous {
        @extend %font-16-m;
      }
      &-current {
        @extend %font-18-secondary-b;
      }
    }
    .btn-fill {
      @extend %secondary-bg;
      @extend %p-x-full;
      @extend %p-y-quater;
    }
  }
  .btn-fill {
    @extend %secondary-bg;
    @extend %p-x-full;
    @extend %p-y-quater;
  }
  &.home-mega-list {
    background-size: cover;
    background-position: left;
    .title {
      color: clr(white-default);
      @extend %uppercase;
    }
    .article {
      &-list {
        gap: calc(var(--full-space) + var(--half-space) / 2);
      }
      &-item {
        margin: 0;
        padding: var(--half-space);
        @extend %white-default;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &-title {
        margin-bottom: 0;
        font-family: $font-primary-bold;
        font-size: 1.6rem;
        @extend %black-default;
      }
      &-list {
        padding-inline: calc(var(--full-space) - var(--half-space) / 2);
        margin-inline: 0;
        @extend %m-x-half-neg;
      }
      &-footer {
        align-items: center;
        // padding-top: 0;
        @extend %pt-3;
        @extend %d-block;
      }
      &-content {
        padding-inline: 0;
        padding-block: var(--half-space) 0;
      }
    }
    .btn-fill {
      background-color: clr(button-primary);
      padding-inline: var(--half-space);
      flex-shrink: 0;
      height: var(--btn-buy-now);
      @extend %uppercase;
      @extend %w-100;
      @extend %text-center;
      @extend %d-block;
    }
    .price {
      &-section {
        gap: var(--half-space);
        padding-block: var(--three-fourth-space);
        @extend %d-none;
      }
      &-previous {
        @extend %black-default-4;
      }
      &-current {
        @extend %black-default;
      }
    }
    .article-item {
      position: relative;
    }
    .badge {
      top: var(--half-space);
      left: var(--half-space-negative);
      right: unset;
      padding-inline: .6rem;
      &:before {
        content: "";
        right: -1.2rem;
        top: 0;
        bottom: 0;
        border-left: 1.3rem solid var(--color-button-primary);
        border-top: 1.3rem solid transparent;
        border-bottom: 1.3rem solid transparent;
        @extend %absolute;
      }
      &:after {
        content: "";
        width: 4rem;
        height: 3.8rem;
        background: url(/static-assets/images/cssimages/homepage/tag-light.png?v=#{$image-version}) no-repeat center / contain;
        position: absolute;
        top: 0;
        left: calc(var(--full-space-negative) + var(--half-space-negative)/2);
      }
    }
    .badge-text {
      // animation: blinker 1s linear infinite;
      animation: fade 1s infinite linear alternate;
      font: 1rem / 1.6rem "Montserrat-Bold", sans-serif;
      @extend %uppercase;
    }
    @keyframes blinker {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
    }
  }
  &.home-rsb-list {
    .title {
      color: clr(text);
    }
    .head-tab a {
      color: var(--color-text);
    }
    .img-box {
      border-top-left-radius: var(--half-radius);
      border-top-right-radius: var(--half-radius);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
    }
    .btn-fill {
      @extend %button-primary-bg;
    }
    .price-previous {
      @extend %d-none;
    }
    .article {
      &-item {
        box-shadow: 0 .2rem .6rem 0 var(--color-border-gray);
        border-radius: 0;
        padding: 0;
        @include bg(page-background);
      }
      &-content {
        background: transparent;
        // @include bg(page-background);
      }
      &-footer {
        align-items: center;
        padding-inline: var(--space-2);
      }
      &-title {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-fixed-dynamic-content-listing {
    &.widget-layout-01 {
      .first-list {
        overflow-x: scroll;
        flex-wrap: nowrap;
        .article-item {
          width: 30%;
          flex-basis: 30%;
          flex-shrink: 0;
          height: auto;
        }
        .btn-fill {
          padding: var(--quater-space) var(--half-space);
        }
      }
    }
    &.home-mega-list {
      .article-title,
      .article-content,
      .article-item,
      .price-previous,
      .price-current {
        background: transparent;
        color: clr(text);
      }
      .article {
        &-list {
          overflow: visible;
          @include grid(repeat(3, 1fr));
        }
        &-item {
          margin: 0;
          width: 100%;
          padding: var(--half-space);
          background-color: var(--color-white-default);
        }
      }
      .article {
        &-title {
          font-size: 2rem;
        }
      }
      .price-previous {
        font-size: 1.8rem;
        color: hsl(var(--color-hsl-black-default) /46%);
      }
      .price-current {
        font-size: 1.8rem;
      }
      .badge {
        left: var(--half-space-negative);
      }
    }
    &.home-mega-list,
    &.home-rsb-list {
      .head-tab a {
        color: var(--color-white-default);
        font-size: 1.6rem;
      }
    }
    &.home-rsb-list {
      .article-content {
        @include border-radius(var(--half-radius));
      }
      .article-title {
        margin-bottom: 0;
      }
      .article-footer {
        padding-top: 0;
      }
      .article-list {
        overflow: hidden;
        @include grid(repeat(3, 1fr));
      }
      .article-item {
        margin: 0;
        width: 100%;
      }
    }
  }
  .dark-mode {
    .waf-fixed-dynamic-content-listing {
      &.home-rsb-list {
        .article-content {
          @include bg(page-background);
        }
      }
    }
    .waf-fixed-dynamic-content-listing.widget-layout-02 {
      .article-item {
        background-color: var(--color-black-default);
      }
    }
  }
}
@media screen and (min-width: $desktop-min-width) {
  .waf-fixed-dynamic-content-listing {
    &.widget-layout-01 {
      .first-list {
        overflow-x: unset;
        margin: 0 var(--half-space-negative);
      }
      .article-item {
        @include card-count(4, var(--full-space));
        &:first-child {
          @include flex-config(flex, null, null, null);
          @include card-count(2, var(--full-space));
          .article-thumbnail {
            @include flex-config(flex, null, center, center);
          }
        }
      }
      .img-box {
        width: 100%;
        background-color: transparent;
      }
    }
    &.widget-layout-02 {
      .article {
        &-list {
          margin: 0;
          overflow-x: unset;
          padding-inline: 0;
          @include grid(repeat(4, 1fr), var(--full-space))
        }
        &-wrap {
          height: 100%;
          @include flex-config(flex, column, space-between, null);
        }
        &-item {
          margin: 0;
          width: 100%;
          padding: var(--half-space);
          background-color: var(--color-white-default);
        }
      }
      &.home-rsb-list {
        .article-item {
          padding: 0;
        }
      }
    }
  }
}