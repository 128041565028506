@use "./config" as *;
.waf-statslisting {
    @extend %p-x-three-fourth;
    @extend %m-x-half-neg;
    @extend %m-y-zero;
    @extend %p-t-zero;
    @extend %p-b-zero;
    .waf-head {
        @extend %d-none;
    }
    .tabs {
        @extend %d-none;
    }
    .rank {
        width: 11rem;
        line-height: 1.2rem;
        border-bottom-left-radius: var(--half-radius);
        @include position-combo(tr);
        @extend %p-y-quater;
        @extend %d-none;
        @extend %text;
        @extend %capitalize;
        @extend %font-14-primary-r;
        &::before {
            @include icon(cap, 15);
            @extend %m-r-half;
        }
    }
    .card {
        &-item {
            @extend %m-b-full;
            &.highest-score,
            &.best-bowling {
                .team-item {
                    &:after {
                        content: "";
                        pointer-events: none;
                        @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / .1), hsl(var(--color-hsl-accent) / .1));
                        @extend %w-100;
                        @extend %h-100;
                        @extend %position-bottom-left;
                        @extend %radius-half;
                    }
                }
                .label {
                    @extend %text-left;
                }
            }
            &.highest-score {
                .label {
                    max-width: 6rem;
                }
            }
            &.best-bowling {
                .item-wrap {
                    align-items: flex-start;
                }
                .logo {
                    left: unset;
                    right: var(--three-fourth-space);
                }
                .label {
                    max-width: 9.6rem;
                }
            }
            &.most-runs,
            &.most-wickets {
                .team-item {
                    position: relative;
                    @extend %p-t-quater;
                    @extend %hidden;
                    .item-wrap::after {
                        content: "";
                        pointer-events: none;
                        @include linear-gradient(180deg, hsl(var(--color-hsl-accent) / 0) 0%, hsl(var(--color-hsl-accent) / .5) 290.67%);
                        @extend %w-100;
                        @extend %h-100;
                        @extend %position-bottom-left;
                    }
                }
                .logo {
                    width: 11rem;
                    transform: unset;
                    @include position(unset, null, 0, 0);
                }
                .item-wrap {
                    flex-direction: row;
                    justify-content: flex-end;
                    flex-wrap: wrap;
                }
                .total-points {
                    flex-direction: column;
                    gap: 0;
                    @extend %radius-half;
                    @extend %p-y-half;
                    &:not(:last-child) {
                        @extend %m-r-half;
                    }
                    position: relative;
                    @extend %white-default-bg;
                    &::before {
                        content: "";
                        pointer-events: none;
                        @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.2), hsl(var(--color-hsl-accent) / .2));
                        @extend %w-100;
                        @extend %h-100;
                        @extend %radius-half;
                        @extend %position-bottom-left;
                    }
                }
                .label {
                    font-size: 1rem;
                    @extend %text;
                }
                .name {
                    height: 2.5rem;
                    @include position(6rem, null, null, 11rem);
                    & + .total-points {
                        flex-direction: row;
                        align-items: baseline;
                        justify-content: flex-start;
                        background: transparent;
                        margin-bottom: calc(var(--full-space) + var(--half-space));
                        padding-left: 9.5rem;
                        @extend %gap-half;
                        @extend %w-100;
                        @extend %p-zero;
                        &::before {
                            background: transparent;
                        }
                        .points {
                            font-size: 6.4rem;
                            line-height: 5.8rem;
                        }
                        .label {
                            font-size: 1.4rem;
                            @extend %text-5;
                        }
                    }
                }
                .fname,
                .lname {
                    line-height: 2.1rem;
                    font-size: 2.4rem;
                }
                .points {
                    font-size: 2rem;
                    line-height: 1.8rem;
                }
            }
            &.most-runs {
                .total-points {
                    width: 6.5rem;
                }
            }
            &.most-wickets {
                .total-points {
                    width: 6.5rem;
                }
            }
            &.orange-cap {
                .team-item {
                    &::before {
                        @extend %orange-default;
                    }
                    .item-wrap::after {
                        @include linear-gradient(180deg, hsl(var(--color-hsl-orange-default) / 0) 0%, hsl(var(--color-hsl-orange-default) / 1) 176.61%);
                    }
                }
                .total-points {
                    background: hsl(var(--color-hsl-white-default));
                    &::before {
                        background: transparent;
                    }
                }
                .rank {
                    @extend %orange-default-bg;
                }
            }
            &.purple-cap {
                .team-item {
                    &::before {
                        @extend %purple-default;
                    }
                    .item-wrap::after {
                        @include linear-gradient(180deg, hsl(var(--color-hsl-purple-default) / 0) -28.23%, hsl(var(--color-hsl-purple-default) / .1) 20.29%, hsl(var(--color-hsl-purple-default) / .3) 78.97%, hsl(var(--color-hsl-purple-default) / 1) 140.73%);
                    }
                }
                .name + .total-points {
                    &::before {
                        background: unset;
                    }
                }
                .total-points {
                    position: relative;
                    @extend %white-default-bg;
                    &::before {
                        content: "";
                        pointer-events: none;
                        @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.2), hsl(var(--color-hsl-accent) / .2));
                        @extend %w-100;
                        @extend %h-100;
                        @extend %position-bottom-left;
                    }
                }
                .rank {
                    @extend %purple-default-bg;
                    @extend %white-default;
                    &::before {
                        @extend %white-default;
                    }
                }
            }
            &.orange-cap,
            &.purple-cap {
                .team-item {
                    &::before {
                        line-height: 5.5rem;
                        opacity: .1;
                        @include icon(cap, 70);
                        @include position(3.5rem, 2rem, null, null);
                    }
                }
                .rank {
                    @extend %flex-c-c;
                }
            }
        }
        &-head {
            @extend %d-none;
        }
    }
    .team {
        &-item {
            position: relative;
            @extend %white-default-bg;
            @extend %p-three-fourth;
            @extend %radius-half;
            .more-btn {
                @extend %d-none;
            }
            .logo {
                width: 6.5rem;
                @include position-combo(y-center, null, var(--three-fourth-space));
                img {
                    display: block;
                    @extend %h-100;
                }
            }
            .item-wrap {
                flex-direction: column-reverse;
                @extend %flex-column-c-fe;
            }
        }
    }
    .total-points {
        @extend %flex-c-c;
        @extend %gap-half;
    }
    .name {
        @extend %capitalize;
    }
    .fname,
    .lname {
        line-height: 2.8rem;
        @extend %text;
        @extend %font-32-primary-b;
    }
    .points {
        line-height: 3.6rem;
        @extend %accent;
        @extend %font-40-primary-b;
    }
    .label {
        display: block;
        min-width: 6rem;
        width: max-content;
        line-height: 1.6rem;
        @extend %text-center;
        @extend %text-5;
        @extend %font-18-primary-r;
        @extend %capitalize;
    }
    .name,
    .logo,
    .total-points {
        z-index: var(--z-statscard);
    }
}
.waf-row-statslisting {
    @extend %white-default-bg;
    @extend %p-b-half;
}
.dark-mode {
    .waf-statslisting {
        @extend %black-default-bg;
        .card-item {
            &.most-runs,
            &.most-wickets {
                .logo img {
                    filter: unset;
                }
                .total-points {
                    @extend %black-light-bg;
                }
            }
            &.orange-cap,
            &.purple-cap {
                .team-item::before {
                    opacity: .4;
                }
            }
        }
        .team-item {
            @extend %black-light-bg;
            .logo img {
                filter: contrast(0.5);
            }
        }
        .rank {
            @extend %black-light;
        }
    }
    .waf-row-statslisting {
        @extend %black-default-bg;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-row-statslisting {
        padding-top: var(--full-space);
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-row-statslisting {
        padding-block: var(--full-space);
        @include bg(white-default);
        .col-lg-12 {
            display: flex;
            flex-wrap: wrap;
            padding-inline: 0;
            gap: 0 var(--full-space);
            section {
                width: calc(50% - var(--half-space));
            }
        }
    }
    .waf-statslisting {
        margin-inline: 0;
        padding-inline: 0;
        .team-item {
            padding: calc(var(--full-space) + var(--half-space)) var(--full-space);
            .item-wrap {
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;
                padding-left: calc(var(--half-space) * 10);
                gap: var(--full-space) var(--three-fourth-space);
            }
            .logo {
                width: 7.5rem;
                left: var(--full-space);
            }
        }
        .points {
            font-size: 5.6rem;
            line-height: 5rem;
        }
        .label {
            font-size: 1.8rem;
            line-height: 1.6rem;
            // max-width: 7.5rem;
        }
        .total-points {
            align-items: center;
        }
        .card {
            &-item {
                &.highest-score {
                    .label {
                        max-width: 8rem;
                    }
                }
                &.best-bowling {
                    .label {
                        max-width: 16rem;
                    }
                    .item-wrap {
                        padding-inline: 0 calc(var(--half-space) * 10);
                        align-items: center;
                    }
                    .logo {
                        right: var(--full-space);
                    }
                }
                &.highest-score,
                &.best-bowling {
                    &:hover {
                        cursor: default;
                        .team-item::after {
                            @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.3), hsl(var(--color-hsl-accent) / 0.3));
                        }
                        .points {
                            @include color(primary-dark);
                        }
                    }
                }
                &.most-runs,
                &.most-wickets {
                    .logo {
                        width: 18rem;
                        img {
                            transition: scale 300ms;
                        }
                    }
                    .team-item {
                        padding-block: var(--half-space) var(--full-space);
                        &:hover {
                            img {
                                scale: 1.2;
                            }
                        }
                    }
                    .total-points {
                        position: relative;
                        align-items: center;
                        transition: background 300ms ease;
                        &:not(:last-child) {
                            margin-right: 0;
                        }
                        &::before {
                            content: "";
                            pointer-events: none;
                            @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.2), hsl(var(--color-hsl-accent) / 0.2));
                            width: 100%;
                            height: 100%;
                            border-radius: var(--half-radius);
                            // cursor: pointer;
                            @include position-combo(bl);
                        }
                        .points {
                            transition: all 300ms ease-in;
                        }
                        &:hover {
                            cursor: default;
                            &::before {
                                @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.3), hsl(var(--color-hsl-accent) / 0.3));
                            }
                            .points {
                                @include color(primary-dark);
                            }
                        }
                    }
                    .fname,
                    .lname {
                        font-size: 4rem;
                        line-height: 3.6rem;
                    }
                    .name {
                        height: 3.6rem;
                        top: 10rem;
                        left: 19rem;
                        & + .total-points {
                            padding-left: 0;
                            .points {
                                font-size: 10rem;
                                line-height: 9rem;
                            }
                            .label {
                                font-size: 2.4rem;
                                line-height: 2.1rem;
                            }
                            &:hover::before {
                                background: transparent;
                            }
                            &:hover .points {
                                @include color(accent);
                            }
                        }
                    }
                    .points {
                        font-size: 3.2rem;
                        line-height: 2.8rem;
                    }
                    .label {
                        font-size: 1.4rem;
                    }
                    .item-wrap {
                        justify-content: flex-start;
                        padding-left: calc(var(--half-space)*17);
                    }
                }
                &.most-runs {
                    .total-points {
                        width: 10.5rem;
                    }
                }
                &.most-wickets {
                    .total-points {
                        width: 10.5rem;
                    }
                }
                &.orange-cap,
                &.purple-cap {
                    .team-item {
                        &::before {
                            font-size: 13rem;
                            line-height: 10.5rem;
                            top: 5rem;
                        }
                    }
                }
                &.purple-cap {
                    &.most-wickets {
                        .name + .total-points {
                            gap: 0;
                        }
                    }
                }
                &.orange-cap {
                    .total-points::before {
                        background: transparent;
                    }
                }
            }
        }
        .rank {
            width: 18rem;
            font-size: 2rem;
            padding-block: var(--half-space);
            &::before {
                font-size: 2.6rem;
            }
        }
    }
    .dark-mode {
        .card-item {
            &.most-runs,
            &.most-wickets {
                .total-points {
                    &:hover {
                        &::before {
                            @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.3), hsl(var(--color-hsl-accent) / .3));
                        }
                        .points {
                            @include color(accent);
                        }
                    }
                }
            }
            &.highest-score,
            &.best-bowling {
                &:hover {
                    .team-item::after {
                        @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.3), hsl(var(--color-hsl-accent) / .3));
                    }
                    .points {
                        @include color(accent);
                    }
                }
            }
        }
    }
}