@use "./config" as *;
body.hindi-lang {
    .waf-showcase .article-title {
        @include translate-hindi(2.2rem, 3.2rem, 9.4rem);
    }
    .waf-listing {
        &.home-news-list .article-title {
            @include translate-hindi(1.6rem, 2.4rem, 4.6rem);
        }
        & .article-title,
        &.home-video-list .article-title {
            @include translate-hindi(1.8rem, 2.8rem, 5.4rem);
        }
    }
    .waf-fixed-dynamic-content-listing .article-title {
        @include translate-hindi(1.8rem, 2.8rem, 5.4rem);
    }
    .waf-squad.home-squad-list .first-name {
        @extend %m-b-half;
    }
}
@media (min-width: $tablet-min-width) {
    body.hindi-lang {
        .waf-showcase .article-title {
            @include translate-hindi(2.4rem, 3.4rem, 6.6rem);
        }
        .waf-listing {
            &.home-news-list .article-title {
                @include translate-hindi(1.8rem, null, 4.8rem);
            }
            & .article-title,
            &.home-video-list .article-title {
                @include translate-hindi(2rem);
            }
            &.home-photo-list .article-item .article-title {
                @include translate-hindi(1.8rem, 2.7rem, 5.4rem);
            }
        }
        .waf-fixed-dynamic-content-listing .article-title {
            @include translate-hindi(2rem);
        }
        .waf-squad.home-squad-list .first-name {
            margin-bottom: var(--full-space);
        }
    }
}