@use "../config" as *;
html {
  &.no-scroll,
  &.no-scroll body {
    width: 100%;
    height: var(--window-inner-height);
    touch-action: none;
    -ms-scroll-chaining: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}
[v-cloak] {
  visibility: hidden;
}
ul {
  list-style: none;
}
.grecaptcha-badge {
  visibility: hidden;
}
.waf-row {
  clear: both;
  position: relative;
  &::before,
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}
.btn-loader {
  .btn-text {
    font-size: 0;
    display: flex;
    &::after {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      border: 0.1rem solid var(--color-white-default);
      border-top-color: transparent;
      animation: spinner 1s linear infinite;
      @extend %circle;
      @extend %m-auto;
    }
  }
}
.waf-row:before,
.waf-row:after {
  clear: both;
  content: "";
  display: table;
}
.waf-component {
  position: relative;
  display: block;
  padding: var(--full-space);
  margin: var(--half-space) 0;
}
.img-box {
  background-color: var(--shimmer-light-color);
  overflow: hidden;
  @include border-radius(var(--half-radius) var(--half-radius) 0 0, hidden);
  img {
    height: auto;
    width: 100%;
  }
}
img {
  height: auto;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}
.img-4by3,
.img-16by9,
.img-1by1,
.img-2by3,
.img-9by16,
.img-21by9,
.img-18by9,
.img-18by6,
.img-6by4 {
  .img-box {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      @include position(0, null, null, 0);
      &[alt] {
        font-size: 1.2rem;
        color: rgba(var(--black-color-rgb), 0.7);
      }
    }
  }
}
html body #onetrust-pc-sdk.otRelFont,
html body #onetrust-banner-sdk.otFloatingRoundedIcon.otRelFont {
  font-size: 1.6rem;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
//project css start
.dark-bg {
  @extend %black-light-bg;
}
body.dark-mode select.form-control option,
body.dark-mode select option {
  @extend %black-default-bg;
}
.dark-mode {
  .loadmore-wrap .loadmore {
    @extend %button-primary-bg;
  }
  .waf-component.waf-loader {
    @extend %black-light-bg;
    .loader {
      background-color: #950461;
      &:after,
      &:before {
        border-top-color: #E96BB0;
        border-bottom-color: #E96BB0;
      }
    }
  }
}
.hide-sponsors {
  .footer-top {
    @extend %d-none;
  }
}
.hide-footer {
  .footer-wrap {
    @extend %d-none;
  }
}
.waf-loading .waf-component.waf-loader {
  @include flex-config(flex);
}
.waf-component.waf-loader {
  position: fixed;
  width: var(--window-inner-width);
  height: calc(var(--window-inner-height) - var(--header-height));
  bottom: 0;
  left: 0;
  z-index: var(--loader);
  @extend %m-zero;
  @extend %white-default-bg;
  @include flex-config(none, null, center, center);
  .loader {
    position: relative;
    width: 10rem;
    height: 10rem;
    padding: 1.5rem;
    background-image: url(/static-assets/images/svg/logo.svg?v=#{$image-version});
    background-size: 4.5rem;
    background-position: center center;
    background-repeat: no-repeat;
    @extend %primary-bg;
    @extend %circle;
    @extend %flex-c-c;
    &:after,
    &::before {
      content: "";
      border: 0.3rem solid transparent;
      border-top-color: var(--color-primary-dark);
      border-bottom-color: var(--color-primary-dark);
      @extend %circle;
      @extend %position-center;
    }
    &:after {
      width: calc(100% + 1rem);
      height: calc(100% + 1rem);
      -webkit-animation: loader 1s linear infinite;
      animation: loader 1s linear infinite;
    }
    &::before {
      width: calc(100% + 3rem);
      height: calc(100% + 3rem);
      -webkit-animation: loader-reverse 1s linear infinite;
      animation: loader-reverse 1s linear infinite;
    }
  }
}
.waf-common-user-modal,
.waf-action-modal {
  &.popup-message {
    position: fixed;
    inset: 0;
    z-index: var(--modal-z);
    background: var(--color-black-light);
    @extend %flex-center;
    @extend %p-full;
    .waf-form-section {
      background: unset;
    }
    .form {
      &-list {
        @extend %flex;
      }
      &-aria {
        width: max-content;
      }
      &-wrapper {
        margin: auto;
      }
    }
  }
}
.global {
  &-msg {
    @extend %flex-column;
    gap: var(--half-space) 0;
    .message {
      position: relative;
      display: block;
      padding: var(--half-space);
      @extend %system-text;
      @extend %system-text-bg-1;
      border-left: 0.3rem solid var(--color-system-text);
      @extend %font-14-primary-r;
      &:empty {
        display: none;
      }
    }
  }
  &-error {
    .message {
      @extend %error-text;
      @extend %error-text-bg-1;
      border-left: 0.3rem solid var(--color-error-text);
    }
  }
  &-success {
    .message {
      @extend %success-text;
      @extend %success-text-bg-1;
      border-left: 0.3rem solid var(--color-success-text);
    }
  }
}
.dark-mode {
  .global {
    &-msg {
      .message {
        @extend %text;
      }
    }
    &-error {
      .message {
        @extend %text;
      }
    }
    &-success {
      .message {
        @extend %text;
      }
    }
  }
}
.banner {
  &-section {
    /* min-height: 14rem; */
    line-height: 0;
    @include border-radius(var(--half-radius), hidden);
    @extend %w-100;
    @extend %m-b-half;
    @include linear-gradient(270deg, hsl(var(--color-hsl-secondary)) 0%, hsl(var(--color-hsl-primary)) 121.25%);
    a {
      display: block;
      line-height: 0;
      @extend %w-100;
      @extend %h-100;
      @extend %flex-center;
    }
  }
  &-image {
    height: auto;
    @extend %w-100;
  }
}
.no-data:where(:not(.content)) {
  text-align: center;
  max-width: var(--container-max-width);
  margin: auto;
  padding: var(--full-space);
  @extend %capitalize;
  @extend %page-background-bg;
  @extend %radius-half;
  @extend %font-16-primary-b;
  @extend %accent;
}
.webview {
  .header-wrap,
  .footer-wrap {
    display: none;
  }
  .filter-section {
    bottom: var(--half-space);
    .more-filters {
      height: var(--window-inner-height);
      bottom: 0;
    }
  }
  .waf-skippers {
    .btn span {
      pointer-events: none;
    }
  }
  .waf-cricketscorecard {
    .footer-wrap {
      display: block;
    }
  }
}
.dark-mode {
  .banner {
    &-section {
      @include linear-gradient(270deg, hsl(var(--color-hsl-accent)) 0%, hsl(var(--color-hsl-secondary-light)) 121.25%);
    }
  }
  .filter-section {
    @extend %button-primary-bg;
    .more-filters {
      .waf-select-box {
        border-bottom: 0.1rem solid hsl(var(--color-hsl-black-default) / 0.2);
      }
      .select-list .list-item {
        @extend %white-default;
      }
      .footer {
        .btn-resetfilter {
          border-color: var(--color-white-default);
          &::after {
            @extend %white-default;
          }
          .btn-text {
            @extend %white-default;
          }
        }
      }
    }
  }
}
.scroll-top {
  display: none;
  position: fixed;
  right: var(--full-space);
  bottom: calc(var(--footer-height) + var(--half-space));
  transition: 1s;
  z-index: var(--scroll-top);
  &.active {
    display: block;
  }
  &-btn {
    width: 4rem;
    height: 4rem;
    @extend %primary-bg;
    @include border-radius(var(--half-radius));
    @extend %flex-center;
  }
  &-icon {
    &::after {
      content: "\e801";
      font: 1rem/1 $font-icon;
      @extend %white-default;
    }
  }
}
.page-scroll {
  .scroll-indicator {
    display: block;
    position: fixed;
    opacity: 1;
    width: 3rem;
    height: 5rem;
    bottom: calc(var(--header-height) + var(--full-space));
    right: var(--full-space);
    transition: opacity 300ms ease-in;
    z-index: var(--scroll-indicator);
    cursor: pointer;
    .bounce-arrow {
      width: 100%;
      height: 100%;
    }
    .icon-scroll-down {
      width: 100%;
      height: 100%;
      animation: bouncing-arrow 2000ms infinite;
      align-items: flex-end;
      @extend %flex-h-center;
      &::before {
        font: 1.4rem/1.4rem $font-icon;
        @extend %white-default;
      }
    }
  }
}
.scroll-indicator {
  display: none;
  opacity: 0;
  pointer-events: none;
}
@each $width,
$height in $aspect-ratio {
  .img-#{$width}by#{$height} .img-box {
    padding-bottom: calc(($height / $width) * 100%);
  }
}
.web {
  display: none;
}
.mob {
  display: block;
}
@each $width in $flex-width {
  .m-flex-#{$width} {
    flex-basis: calc($width * 1%);
    width: calc($width * 1%);
    &-gap {
      flex-basis: calc((#{$width * 1%} - var(--quater-space)));
      width: calc((#{$width * 1%} - var(--quater-space)));
    }
  }
}
.body-wrap {
  min-height: calc(var(--window-inner-height) - (var(--header-height) + var(--footer-to-height) + var(--footer-height)));
}
.btn-outline {
  @extend %btn-outline;
}
.btn-fill {
  @extend %btn-fill;
}
.btn-fill,
.btn-outline {
  &.btn-block {
    display: block;
    width: 100%;
  }
}
.btn-disabled {
  filter: grayscale(1);
  pointer-events: none;
}
/* web setup css done */
/* project common css start */
button {
  @extend %black-default;
}
.filter-section {
  width: 10rem;
  height: var(--filter-height);
  background: hsl(var(--color-hsl-primary-dark));
  margin: 0 auto;
  z-index: var(--filter-z);
  @extend %flex-center;
  @include border-radius(var(--half-space));
  @include position(null,
    0,
    var(--full-space),
    0,
    fixed);
  .filter {
    &-wrap {
      display: none;
    }
    &-action {
      width: 100%;
      height: 100%;
    }
    &-head {
      .title {
        margin: 0;
        font-family: $font-primary-bold;
        font-size: 1.4rem;
        // color: var(--primary-color);
      }
    }
  }
  .btn-resetfilter {
    display: none;
  }
  .dropdown-close {
    display: none;
  }
  .waf-select-box .selected-title {
    position: relative;
    cursor: pointer;
    padding: var(--half-space);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    height: 100%;
    font-family: $font-primary-bold;
    @include flex-config(flex, column, center, flex-start);
    .sub-title,
    .title {
      margin: 0;
      @extend %text;
      @extend %font-12-primary-r;
    }
    .title {
      @extend %black-default;
      @extend %font-14-primary-b;
    }
    &::after {
      content: "\e814";
      font-family: $font-icon;
      font-size: 1.2rem;
      @include transition(0.3s all);
      @include position(null, var(--full-space), null, null);
    }
  }
  .select-box-wrap {
    text-align: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 5rem 0 0.1rem 0;
    position: fixed;
    display: none;
    z-index: var(--filter-z);
    bottom: 0;
    padding: 0;
    @include translate(0, 100%);
    @include transition(0.3s all);
    @include border-radius(0 0 var(--half-radius) var(--half-radius));
    // @include box-shadow(-.1rem .3rem .4rem -.1rem rgba(var(--black-color-rgb), .28));
  }
  .select-list {
    padding: 0 var(--half-space);
    overflow: auto;
    max-height: 30rem;
    overflow-x: hidden;
    .list-item {
      padding: 1rem;
      position: relative;
      text-align: left;
      display: block;
      width: 100%;
      border-top: 0.1rem solid hsl(var(--color-hsl-black-default) / 0.2);
      @extend %black-default;
      @extend %font-14-m;
      &.active {
        font-family: $font-secondary-bold;
      }
      a {
        color: inherit;
        font-size: inherit;
        display: block;
      }
    }
  }
  .btn-resetfilter {
    width: 3rem;
    &::after {
      content: "\e811";
      font-family: $font-icon;
      @extend %black-default-7;
    }
    .btn-text {
      font-size: 0;
      color: var(--primary-color);
      font-family: $font-primary-regular;
    }
  }
  .btn-more {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 var(--half-space);
    // left: 0;
    // @include center(vertical);
    .btn-text {
      position: relative;
      width: 100%;
      height: 100%;
      font-size: 0;
      gap: var(--quater-space);
      @extend %flex-center;
      &::before,
      &::after {
        @extend %font-12-m;
      }
      &::before {
        content: "\e81f";
        font-family: $font-icon;
        @extend %white-default;
      }
      &::after {
        content: "Filter";
        @extend %white-default;
      }
    }
  }
  .more-filters {
    width: 100vw;
    height: calc(var(--window-inner-height) - var(--header-height));
    background: hsl(var(--color-hsl-page-background));
    z-index: var(--filter-more-z);
    pointer-events: none;
    @include position(null,
      calc(var(--window-inner-width) * -1),
      0,
      null,
      fixed);
    @include flex-config(flex, column, null, null);
    @include transition(right 500ms ease-in-out);
    .btn-cancel {
      color: var(--white-color);
      @include flex-config(flex, row-reverse, null, center);
      &::before {
        content: "\e812";
        font-family: $font-icon;
        width: 1.4rem;
        height: 1.4rem;
        border: 0.1rem solid hsl(var(--color-hsl-white-default));
        font-weight: bold;
        @include border-radius(100%);
        @include font(7);
        @extend %white-default;
        @extend %flex-center;
      }
      .btn-text {
        padding-right: var(--half-space);
        @extend %font-14-primary-r;
        @extend %white-default;
      }
    }
    .head {
      height: 4rem;
      background-color: hsl(var(--color-hsl-secondary-dark));
      padding: 0 var(--half-space);
      border-radius: 0 0 var(--half-radius) var(--half-radius);
      @include flex-config(flex, null, space-between, center);
      .title {
        margin: 0;
        @extend %white-default;
        @extend %font-12-primary-b;
      }
    }
    .body {
      height: calc(100% - 10rem);
      overflow: auto;
      background-color: var(--filter-body-color);
    }
    .footer {
      height: 6rem;
      @include flex-config(flex, null, center, center);
      .btn-site {
        margin: 0 var(--half-space);
      }
      .btn-applyfilter,
      .btn-resetfilter {
        min-width: 14rem;
        font-family: $font-medium;
        height: 4rem;
        @extend %button-primary-bg;
        @include border-radius(var(--half-space));
        @include flex-config(flex, null, center, center);
        .btn-text {
          @extend %font-16-primary-b;
        }
        &::before {
          font-family: $font-icon;
        }
      }
      .btn-applyfilter {
        .btn-text {
          @extend %white-default;
        }
      }
      .btn-resetfilter {
        background: transparent;
        border: 0.1rem solid hsl(var(--color-hsl-black-default) / 0.2);
        color: var(--background-color);
        margin-left: var(--full-space);
        .btn-text {
          order: -1;
          padding-right: var(--half-space);
          @extend %black-default-6;
        }
      }
    }
    .select-list {
      list-style-type: none;
    }
    .select-box-wrap {
      position: relative;
      top: auto;
      bottom: unset;
      transform: unset;
      z-index: var(--z1);
      box-shadow: none;
      width: 100%;
    }
    .waf-select-box {
      border-bottom: 0.1rem solid hsl(var(--color-hsl-black-default) / 0.2);
    }
  }
  .waf-select-box {
    position: relative;
    margin: 0 calc(var(--half-space) / 2);
    &.active {
      .selected-title {
        border-bottom: none;
        &:after {
          transform: scaleY(-1);
        }
      }
      .select-box-wrap {
        display: block;
      }
    }
  }
  &.active {
    .more-filters {
      // display: block;
      pointer-events: initial;
      right: 0;
    }
  }
}
.tab-list {
  @extend %flex;
  .tab-item {
    width: 50%;
    position: relative;
    height: var(--tab-height);
    cursor: pointer;
    @extend %flex-center;
    button,
    a {
      @extend %font-12-m;
    }
    &.active {
      button,
      a {
        font-family: $font-secondary-bold;
        @extend %button-primary;
      }
      &::after {
        height: 0.3rem;
        @extend %button-primary-bg;
      }
      span {
        @extend %button-primary;
      }
    }
    &::after {
      content: "";
      height: 0.1rem;
      bottom: 0;
      @extend %w-100;
      @extend %black-default-bg-1;
      @include center(horizontal);
    }
    span {
      height: 3.5rem;
      width: 100%;
      @extend %uppercase;
      @extend %black-default-7;
      @extend %font-12-secondary-b;
      @extend %flex-center;
    }
  }
}
.table-responsive {
  @extend %m-b-full;
  .table {
    overflow-x: auto;
    @include border-radius(var(--half-radius) var(--half-radius) 0 0, hidden);
    @extend %flex;
    .row-head {
      @extend %flex-center;
    }
    &-head {
      .table-data {
        @extend %secondary-bg;
      }
      .text {
        @extend %font-10-secondary-b;
        @extend %white-default;
        @extend %uppercase;
      }
    }
    &-right {
      overflow: auto;
    }
    &-row {
      height: max-content;
      @extend %flex-n-s;
      &.active {
        .table-data {
          @extend %secondary-bg-2;
        }
      }
    }
    &-body {
      .table-row {
        &:nth-child(odd) {
          .table-data {
            @extend %secondary-bg-1;
          }
        }
        // &:last-child {
        //   .table-data {
        //     @extend %secondary-dark-bg-1;
        //   }
        // }
      }
    }
    &-left {
      width: 50%;
      .table-data {
        width: 100%;
      }
    }
    &-right {
      width: 50%;
      .table-body .table-row {
        &:nth-child(odd) {
          .table-data {
            @extend %secondary-bg-1;
          }
        }
      }
    }
    &-data {
      min-height: 3.2rem;
      width: 8rem;
      flex-shrink: 0;
      @extend %p-half;
      @include flex-config(flex, null, center, center);
      &.text-left {
        @include flex-config(null, null, flex-start);
      }
      &.text-right {
        @include flex-config(null, null, flex-end);
      }
    }
  }
}
.article-date-info {
  @extend %m-y-full;
  .meta {
    position: relative;
    @extend %text-7;
    @extend %font-10-m;
    &-date {
      &::before {
        content: "\e80e";
        color: inherit;
        font-size: 1rem;
        font-family: $font-icon;
        @extend %m-r-quater;
      }
    }
  }
}
/* project common css end */
.loadmore-wrap {
  @extend %flex;
  .loadmore {
    border: 0;
    box-shadow: none;
    @extend %accent-bg;
    @extend %half-radius;
    @extend %white-default;
    @extend %p-y-half;
    @extend %p-x-full;
    @extend %m-x-auto;
    @extend %font-12-secondary-b;
  }
}
// secondary nav end
.flex {
  gap: var(--two-space);
  flex-wrap: wrap;
  @extend %flex-c-c;
  iframe {
    height: calc(var(--window-inner-height) - var(--header-height) - 4rem);
  }
}
.floating-button {
  position: fixed;
  bottom: calc(var(--footer-height) + var(--full-space) + 4rem);
  right: var(--full-space);
  z-index: var(--z-matchcenter-share);
  .btn-action {
    width: 4rem;
    height: 4rem;
    font-size: 0;
    @extend %half-radius;
    @extend %flex-c-c;
    @extend %primary-bg;
    &::before {
      content: "\e828";
      font-family: $font-icon;
      font-size: 1.6rem;
      transform: rotate(80deg);
      @extend %white-default;
    }
  }
}
@media screen and (min-width: $tablet-min-width) {
  .mob {
    display: none;
  }
  .web {
    display: block;
  }
  .home-news-list,
  .home-tv-list,
  .home-photo-list,
  .waf-squad,
  .waf-fixed-dynamic-content-listing,
  .waf-register-listing,
  .waf-gallery-listing,
  .sponsor-page-wrap,
  .documentary-content,
  .proflie-list,
  .bullet-section,
  .vision-section,
  .waf-team,
  .waf-article,
  .profile-list,
  .artist-lyrics-wrap,
  .waf-download-banner,
  .waf-judges,
  .waf-guest-judges,
  .waf-hosts,
  .waf-mentors,
  .waf-standings.widget-layout-06,
  .waf-statsdetail,
  .waf-cricketscorecard,
  .waf-membership,
  .home-podcast-list,
  .image-section,
  .waf-podcast,
  .podcast-listing,
  .waf-ad-membership,
  .waf-popular-search,
  .waf-royals-foundation {
    .layout-wrapper {
      width: var(--container-max-width);
      margin: 0 auto;
    }
  }
  .loadmore-wrap {
    .loadmore {
      @include font-style(14);
    }
  }
  .banner-section {
    margin-bottom: var(--full-space);
  }
  .filter-section {
    bottom: var(--half-space);
    .waf-select-box {
      .selected-title {
        .title {
          font-size: 1.4rem;
        }
      }
    }
    .more-filters {
      width: 35rem;
      height: calc(100% - var(--header-height));
      bottom: 0;
      .select-list {
        .list-item {
          font-size: 1.2rem;
        }
      }
      .footer {
        .btn-applyfilter,
        .btn-resetfilter {
          height: 3.4rem;
          @include border-radius(0.5rem);
          .btn-text {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
  @each $width in $flex-width {
    .flex#{$width} {
      flex-basis: calc($width * 1%);
      width: calc($width * 1%);
      &-gap {
        flex-basis: calc((#{$width * 1%} - var(--quater-space)));
        width: calc((#{$width * 1%} - var(--quater-space)));
      }
    }
  }
  /* web setup css done */
  .waf-component.waf-loader {
    top: unset;
    bottom: 0;
  }
  .social-icon {
    &.icon-copylink {
      position: relative;
      &[aria-expanded="true"] {
        &:before {
          content: "Copied to Clipboard";
          width: max-content;
          right: 0;
          transition: ease 1s;
          animation: fadeIn 3s forwards;
          padding: var(--half-space);
          border-radius: var(--full-radius);
          @include flex;
          @include bg(white-default);
          @include color(accent);
          @include center(vertical);
        }
      }
    }
  }
}
@media screen and (min-width: $tablet-min-width) and (max-width: $large-desktop-min-width) {
  .page-scroll {
    .scroll-indicator {
      display: none;
    }
  }
}
@media screen and (min-width: $large-desktop-min-width) {
  .table-responsive {
    .table {
      overflow-x: unset;
    }
  }
  .page-scroll {
    .scroll-indicator {
      bottom: var(--two-space);
      right: var(--container-white-space);
      &:hover {
        .icon-scroll-down {
          animation: none;
        }
      }
    }
  }
}
.reaction-section {
  button {
    &.icon {
      &-facebook,
      &-twitter,
      &-whatsapp {
        &::before {
          content: "";
        }
      }
    }
  }
}
#otpless-floating-button {
  inset: 0;
  margin: auto;
}