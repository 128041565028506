@use "../config" as *;

.modal {
	&-window {
		//display: block;
		border: none;
		width: var(--window-inner-width);
		height: var(--window-inner-height);
		z-index: var(--modal-z);
		overflow: auto;
		@extend %black-default-bg;
		@include position(0, 0, 0, 0, fixed);
		
		.btn-close {
			z-index: var(--modal-close-z);
			@include position(var(--full-space),
				var(--full-space),
				null,
				null,
				fixed);

			&::before {
				display: none;
			}

			&::after {
				content: "\e812";
				font-family: $font-icon;
				@extend %white-default;
			}
		}
	}

	&-head {
		padding-top: 4rem;
	}
}

.waf-press-release {
	&.widget-layout-02.press-page {
		.modal {

			&-head-wrap,
			&-footer {
				display: none;
			}

			&-body {
				max-width: calc(var(--container-max-width) - var(--full-space));
				margin: auto;
				width: 100%;
				overflow: auto;
				@extend %flex-center;

				.article-thumbnail {
					.img-box {
						padding-bottom: 0;

						img {
							@include position(unset, unset, unset, unset, relative);

							&[src=""] {
								display: none;
							}
						}
					}
				}
			}

			&-window {
				@extend %flex-center;

				.article-content {
					display: none;
				}
			}
		}
	}
}

.listing-resource {
	.article-item {
		padding: var(--full-space);
		width: 100%;

		.img-box {
			aspect-ratio: 16 / 9;
		}
	}

	.article-modal-title {
		@include color(white-default);
		@include font(24, $font-primary-regular);
	}

	.article-modal-description {

		p,
		.text {
			@include color(white-default);
			@include font(14, $font-secondary-regular);
		}
	}
}

@media screen and (min-width: $tablet-min-width) {
	.waf-press-release {
		.modal-body {
			height: calc(var(--window-inner-height) - 15rem);
			@include scroll(0.8rem,
				hsl(var(--color-hsl-white-default) / 0.2),
				hsl(var(--color-hsl-white-default) / 0.2),
				null);
		}
	}

	.modal {
		&-body {
			max-width: 80rem;
			margin: 0 auto;
			@include body-bg();
		}

		&-window {
			padding: var(--two-space);

			&::before {
				display: block;
			}

			&::after {
				left: -14rem;
				bottom: -10rem;
			}

			.btn-close {
				top: 4rem;
				right: 4rem;

				&::after {
					font-size: 2rem;
				}
			}
		}
	}
}