@use "./config" as *;
.waf-tracker {
    @extend %p-x-three-fourth;
    @extend %m-x-half-neg;
    @extend %p-b-half;
    .accordion-btn {
        @extend %d-none;
    }
    .head-wrap .title {
        font-size: 2.4rem;
        @extend %text;
    }
    .accordion-wrap:first-of-type {
        .tracker-item:nth-child(1) {
            position: relative;
            @include position-combo(tl);
            @extend %p-l-three-fourth;
            .number {
                &::before {
                    @include icon(bat-2, 24);
                }
            }
        }
        .tracker-item:nth-child(2) {
            @include position-combo(tr);
            @extend %p-r-three-fourth;
            .tracker-item-wrap {
                position: relative;
                &::after {
                    content: "";
                    height: 0.1rem;
                    width: 4.8rem;
                    bottom: 0;
                    transform: rotate(90deg);
                    @include position(5rem, null, 0, -5rem);
                    @extend %gray-dark-bg;
                }
            }
            .number {
                &::before {
                    @include icon(wickets, 24);
                }
            }
        }
        .tracker-item:nth-child(1),
        .tracker-item:nth-child(2) {
            background: transparent;
            &::before {
                background: transparent;
            }
            .tracker-label {
                max-width: 9.5rem;
                line-height: 1.6rem;
                @extend %m-l-auto;
                @extend %capitalize;
                @extend %font-18-primary-r;
                @extend %text;
                @extend %text-left;
            }
            .tracker-item-wrap {
                align-items: flex-start;
            }
            .number {
                position: relative;
                @extend %flex-c-c;
                @extend %font-50-primary-b;
                @extend %accent;
                &::before {
                    @extend %accent;
                    @extend %m-l-quater-neg;
                    @extend %p-r-half;
                }
            }
        }
    }
    .accordion-wrap:last-of-type {
        .tracker-item:nth-child(1),
        .tracker-item:nth-child(2),
        .tracker-item:nth-child(3) {
            width: calc(33.33% - 1rem);
        }
    }
    .tracker {
        &-wrap {
            position: relative;
            padding-top: calc(var(--full-space) * 6);
            margin-top: calc((var(--full-space) + var(--half-space)) * -1);
        }
        &-label {
            width: max-content;
            max-width: 6rem;
            line-height: 1.1rem;
            @extend %capitalize;
            @extend %text-center;
            @extend %font-14-primary-r;
            @extend %text;
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            @extend %gap-three-fourth;
        }
        &-item {
            width: calc(50% - .75rem);
            padding-inline: calc(var(--full-space) + var(--half-space));
            position: relative;
            @extend %radius-half;
            @extend %white-default-bg;
            @extend %p-y-three-fourth;
            &-wrap {
                flex-direction: column-reverse;
                @extend %gap-quater;
                @extend %flex-column-c-c;
            }
            &::before {
                content: "";
                pointer-events: none;
                @extend %w-100;
                @extend %h-100;
                @extend %half-radius;
                @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.1), hsl(var(--color-hsl-accent) / .1));
                @include position-combo(bl);
            }
        }
    }
    .number {
        line-height: 2.5rem;
        @extend %font-28-primary-b;
        @extend %accent;
    }
}
.dark-mode {
    .waf-tracker {
        .tracker-item {
            @extend %black-light-bg;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-row-tracker {
        margin-top: var(--full-space);
    }
}
@media screen and (min-width: $desktop-min-width) {
    .waf-tracker {
        padding-inline: 0;
        .accordion-wrap:first-of-type {
            .tracker-item:nth-child(1) {
                .tracker-item-wrap {
                    align-items: flex-end;
                }
                .tracker-label {
                    margin-right: 6rem;
                }
            }
            .tracker-item:nth-child(2) {
                .tracker-label {
                    margin-left: 5rem;
                }
                .tracker-item-wrap::after {
                    left: -6rem;
                }
            }
            .tracker-item:nth-child(1),
            .tracker-item:nth-child(2) {
                .number {
                    font-size: 8rem;
                    line-height: 7.2rem;
                    &::before {
                        font-size: 4rem;
                    }
                }
                .tracker-label {
                    max-width: unset;
                }
            }
        }
        .accordion-wrap {
            width: 33.33%;
        }
        .stadium {
            &-image {
                position: relative;
                width: 33rem;
                &:after {
                    content: "";
                    width: 100%;
                    height: 31rem;
                    @include background("tracker-players.png", no-repeat center/contain);
                    @include position(-4.8rem, null, null, 0);
                }
            }
        }
        .tracker {
            &-wrap {
                padding-block: calc(var(--full-space)*10) calc((var(--full-space)*3) + var(--half-space));
                @include border(bottom, 1, gray-dark);
                @include flex-config(flex, null, space-between);
            }
            &-item {
                min-height: 8.2rem;
                @include flex-config(flex, null, center, center);
                .number {
                    transition: all 300ms ease-in;
                }
                &:hover {
                    &::before {
                        @include linear-gradient(0deg, hsl(var(--color-hsl-accent) / 0.3), hsl(var(--color-hsl-accent) / 0.3));
                    }
                    .number {
                        @include color(primary-dark);
                    }
                    .tracker-item-wrap {
                        cursor: default;
                    }
                }
            }
        }
        .accordion-wrap:first-of-type {
            .tracker-item:nth-child(1),
            .tracker-item:nth-child(2) {
                height: auto;
                display: block;
            }
        }
    }
    .waf-row-tracker {
        margin-top: var(--two-space);
    }
    .dark-mode {
        .waf-tracker {
            .tracker-item {
                &:hover {
                    .number {
                        @include color(accent);
                    }
                }
            }
        }
    }
}