@use "./config" as *;
.dark-mode {
    .waf-scorestrip.widget-layout-08,
    .waf-fixtures {
        .card-item {
            @include linear-gradient(0deg, var(--color-accent) -5.77%, var(--color-secondary-dark) 99.65%);
        }
    }
}
.waf-scorestrip.widget-layout-08,
.waf-fixtures {
    padding: 0;
    margin: 0;
    .head-wrap {
        @extend %flex-v-center;
    }
    .text {
        @extend %capitalize;
        @extend %white-default-7;
        @extend %font-12-m;
    }
    .number {
        @extend %capitalize;
        @extend %white-default-7;
        @extend %font-12-m;
    }
    .meta {
        @extend %uppercase;
        @extend %white-default-7;
        @extend %font-12-m;
        &:first-child {
            position: relative;
            @extend %m-r-full;
            &::after {
                content: '';
                width: 0.1rem;
                height: 2rem;
                right: var(--half-space-negative);
                @extend %white-default-bg-1;
                @extend %position-v-center;
            }
        }
    }
    .time-text {
        @extend %uppercase;
        @extend %white-default;
        @extend %font-14-primary-b;
    }
    .score {
        display: inline-block;
        @extend %white-default-6;
        @extend %font-26-primary-b;
    }
    .overs {
        @extend %white-default-6;
        @extend %font-14-primary-r;
    }
    .run-rate {
        display: none;
    }
    .team {
        &-image {
            aspect-ratio: 1/1;
            @extend %circle;
            @extend %white-default-bg;
            @extend %flex-center;
        }
        &-logo {
            width: 5rem;
            aspect-ratio: 1/1;
        }
        &-wrapper {
            margin-bottom: var(--two-space);
            @extend %flex-sb;
        }
        &-current,
        &-won {
            .team-image {
                position: relative;
                // margin: 0.3rem;
                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 1/1;
                    border: 0.3rem solid var(--color-accent);
                    @extend %position-center;
                    @extend %circle-radius;
                }
            }
            .score {
                @extend %white-default;
            }
            .overs {
                @extend %white-default;
            }
        }
    }
    .card {
        &-head {
            margin-block: var(--full-space);
        }
        &-item {
            background: var(--color-primary-dark);
            position: relative;
            flex-shrink: 0;
            @include transition(all ease 500ms);
            @extend %m-t-full;
            @extend %p-full;
            @extend %radius-half;
            @extend %w-100;
            @include linear-gradient(180deg, var(--color-secondary-dark) 0%, var(--color-secondary-dark) 44.27%, var(--color-primary) 100%);
            background-size: 200% 200%;
            animation: gradient-bg 20s ease infinite;
            &::after {
                content: '';
                background: url(/static-assets/images/cssimages/homepage/fixture-bg.png?v=#{$image-version})center center no-repeat;
                background-size: contain;
                width: calc(100% - var(--two-space));
                aspect-ratio: 1/1;
                bottom: 0;
                opacity: 0.5;
                pointer-events: none;
                @extend %position-h-center;
            }
            .btn-more {
                @extend %flex-h-center;
                @extend %btn-fill;
            }
            .btn-text {
                @extend %uppercase;
            }
            &.live {
                .status {
                    @extend %success-text-bg;
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1.5rem;
                        aspect-ratio: 1/1;
                        background: url(/static-assets/images/svg/live.svg?v=#{$image-version})center center no-repeat;
                        background-size: contain;
                        @extend %m-r-quater;
                    }
                }
            }
            &.upcoming {
                .status {
                    background-color: var(--color-grey);
                    @extend %black-default;
                }
            }
            &.recent {
                .status {
                    @extend %error-text-bg;
                }
            }
        }
        &-footer-text {
            text-align: center;
            @extend %white-default;
            @extend %font-14-primary-r;
            @extend %m-b-full;
        }
        &-list {
            @extend %flex-v-center;
            @extend %w-100;
        }
        &-footer {
            position: relative;
            &::before {
                content: '';
                height: 0.1rem;
                width: 100%;
                top: var(--full-space-negative);
                @extend %white-default-bg-2;
                @extend %position-h-center;
            }
        }
    }
    .status {
        border-radius: 50vmax;
        height: 2.4rem;
        top: -1.2rem;
        padding: var(--quater-space) var(--half-space);
        @extend %position-h-center;
        @extend %capitalize;
        @extend %white-default;
        @extend %font-10-secondary-b;
        @extend %flex-v-center;
    }
    .countdown {
        &-wrapper {
            height: 6.2rem;
            position: relative;
            @extend %m-b-full;
            @extend %flex;
            &::after,
            &::before {
                content: '';
                height: 0.1rem;
                width: 100%;
                @extend %white-default-bg-2;
                @extend %position-h-center;
            }
            &::before {
                top: 0;
            }
            &::after {
                display: none;
                bottom: 0;
            }
            .icon {
                @extend %p-full;
                @extend %w-20;
                &:after {
                    content: '\e81b';
                    font-size: 2rem;
                    font-family: $font-icon;
                    @extend %white-default;
                }
            }
        }
        &-list {
            @extend %w-80;
            @extend %flex;
        }
        &-item {
            width: 25%;
            position: relative;
            @extend %p-full;
            @extend %flex-column-center;
            &::after {
                content: '';
                width: 0.1rem;
                height: calc(100% - var(--full-space));
                left: 0;
                @extend %white-default-bg-2;
                @extend %position-v-center;
            }
        }
        &-count {
            @extend %white-default;
            @extend %font-24-primary-b;
        }
        &-label {
            @extend %white-default;
            @extend %font-10-primary-r;
        }
    }
}
.waf-shimmer {
    &.waf-scorestrip.widget-layout-08 {
        .card-label,
        .card-footer-text {
            @include shimmer(17rem, 2rem);
        }
        .card-footer-text {
            margin-inline: auto;
        }
        .card-number {
            @include shimmer(5rem, 1.8rem);
        }
        .date-time {
            @include shimmer(7rem, 1.8rem)
        }
        .team-logo {
            object-position: 10rem;
        }
        .team-data {
            margin-top: 1rem;
            @include shimmer(9rem, 1.8rem)
        }
        .team-image {
            @include shimmer(null, null, 50%);
        }
        .team-score,
        .team-extra {
            opacity: 0;
        }
        .time-text {
            margin-top: 4rem;
            @include shimmer(2rem, 2rem, 50%);
        }
    }
}