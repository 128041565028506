@use '../config/' as *;
.waf-jersey-banner {
    padding: 0;
    margin: 0 calc(var(--half-space) * -1);
    .banner {
        &-link,
        &-link .image {
            @extend %d-block;
            .image {
                @extend %h-100;
            }
        }
    }
}