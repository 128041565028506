@use "../config" as *;
.dark-mode {
    .footer {
        &-top {
            @extend %page-background-bg;
        }
        &-wrap {
            .m-separator {
                &::after {
                    @extend %white-default-bg-3;
                }
            }
        }
        &-middle {
            @extend %primary-bg;
        }
        &-bottom {
            @extend %primary-light-bg;
        }
    }
    .client-logo {
        @extend %black-default-bg;
        .dark {
            display: block;
        }
        .light {
            display: none
        }
    }
    .client-title {
        @extend %white-default;
    }
    .waf-breadcrumb {
        background-color: var(--color-black);
        .breadcrumb {
            .breadcrumb-item {
                span {
                    color: hsl(var(--color-hsl-white-default)/0.4);
                }
                &:last-child {
                    span {
                        color: hsl(var(--color-hsl-white-default));
                    }
                }
                &:not(:last-child)::after {
                    @include color(white-default);
                }
            }
        }
    }
}
.footer {
    &-wrap {
        .text {
            @extend %font-10-secondary-b;
            @extend %white-default;
            @extend %capitalize;
        }
        .client-sub-title,
        .client-title {
            text-align: center;
            @extend %font-10-secondary-b;
            @extend %black-default;
            @extend %uppercase;
            @extend %m-b-full;
        }
        .m-separator {
            position: relative;
            &::after {
                content: '';
                width: 100%;
                height: 0.1rem;
                bottom: var(--full-space);
                @extend %position-h-center;
                @extend %black-default-bg-3;
            }
        }
    }
    &-top {
        position: relative;
        z-index: 1;
        @extend %white-default-bg;
        padding: var(--two-space) var(--full-space) 0;
        .layout-wrapper {
            width: var(--container-max-width);
            margin: 0 auto;
        }
    }
    &-link-list {
        flex-wrap: wrap;
        row-gap: var(--quater-space);
        @extend %flex-h-center;
    }
    &-middle,
    &-bottom {
        position: relative;
        height: var(--footer-middle-height);
        @extend %flex-center;
    }
    &-middle {
        @extend %primary-dark-bg;
    }
    &-bottom {
        @extend %primary-bg;
    }
    &-link {
        padding: 0 var(--half-space);
        position: relative;
        &-icon {
            display: none;
        }
        &:not(:first-child) {
            // @extend %m-b-full;
            &:after {
                content: '';
                width: 0.2rem;
                height: 1rem;
                left: 0;
                @extend %white-default-bg-5;
                @include center(vertical);
            }
        }
        a {
            width: max-content;
            display: inline-block;
            @extend %font-10-secondary-b;
            @extend %white-default;
            @extend %capitalize;
        }
    }
}
// .grooming-client .client-logo {
//     @extend %p-quater;
// }
.client {
    &-logo {
        min-height: 6.5rem;
        @extend %flex-c-c;
        @extend %m-b-half;
        @include border-radius(var(--half-radius));
        @extend %page-background-bg;
        img {
            height: 5.5rem;
            width: auto;
            max-width: 100%;
        }
        .dark {
            display: none;
        }
        .light {
            display: block;
        }
    }
    &-list {
        flex-wrap: wrap;
        margin-bottom: var(--two-space);
        @extend %flex-center;
    }
    &-item {
        padding: 0 var(--quater-space);
    }
    &-section {
        &-list {
            flex-wrap: wrap;
            @extend %flex-center;
        }
    }
}
.main-client .client-logo img {
    height: 5rem;
}
.grooming-client .client-logo img {
    height: 7.5rem;
}
.waf-breadcrumb {
    margin: 0;
    background-color: var(--color-grey);
    .layout-wrapper {
        max-width: var(--container-max-width);
        margin: auto;
    }
    .breadcrumb {
        height: var(--breadcrumb-height);
        list-style: none;
        .breadcrumb-item {
            display: inline;
            span {
                @extend %font-14-m;
                line-height: 2rem;
            }
            a {
                line-height: 2rem;
                color: hsl(var(--color-hsl-black-default));
            }
            &:last-child {
                span {
                    @extend %font-12-secondary-b;
                }
            }
            &:not(:last-child) {
                position: relative;
                color: hsl(var(--color-hsl-black-default)/.7);
                &::after {
                    content: "\e806";
                    font-family: $font-icon ;
                    color: hsl(var(--color-hsl-black-default)/.7);
                    font-size: 1rem;
                    padding: 0 1rem;
                    position: relative;
                    top: -0.1rem;
                }
            }
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .dark-mode {
        .v-separator {
            &::before {
                @include bg(white-default, 3)
            }
        }
    }
    .m-separator {
        &::after {
            display: none;
        }
    }
    .v-separator {
        position: relative;
        &::before {
            content: '';
            width: 0.1rem;
            height: 100%;
            right: 0;
            @include center(vertical);
            @include bg(black-default, 3);
        }
    }
    .client-list {
        margin: 0;
        padding: 0 var(--full-space);
    }
    .client-section {
        margin-bottom: var(--two-space);
    }
    .footer-link {
        a {
            @include flex-config(flex, null, null, center);
        }
        &-icon {
            display: block;
        }
        &-right {
            margin-right: auto;
            & ~ .footer-link:after {
                display: none;
            }
        }
        &-list {
            width: var(--container-max-width);
            margin: 0 auto;
        }
        .promo-anchor {
            &-icon {
                cursor: pointer;
                font-size: 0;
                &::before {
                    content: '';
                    font-family: $font-icon;
                    font-size: 1.4rem;
                    display: inline-block;
                    margin-right: var(--quater-space);
                    @include color(white-default, 10);
                }
            }
            &.promo-anchor {
                &-twitter::before {
                    // content: '\e81a';
                    content: '\e8ae';
                }
                &-facebook::before {
                    content: '\e815';
                }
                &-instagram::before {
                    content: '\e817';
                }
                &-linkedin::before {
                    content: '\e819';
                }
                &-youtube::before {
                    content: '\e813';
                }
            }
        }
    }
}