@use '../config/' as *;
.waf-ad-membership {
    padding-inline: 0;
    --_time: 6s;
    .thumbnail-section {
        max-width: 30rem;
        margin-inline: auto;
        @include position(null, 0, 0, 0);
        @extend %w-100;
        @extend %h-100;
    }
    .membership {
        &-list {
            @extend %relative;
            @extend %h-100;
            @extend %w-100;
        }
        &-item {
            width: min(65%, 30rem);
            aspect-ratio: 1.6;
            transform: rotate(35deg);
            transform-origin: bottom right;
            @include position(null, null, 5%, -5%);
            &:nth-child(1) {
                animation: cardProAnimation 10s infinite;
            }
            &:nth-child(2) {
                animation: cardEliteAnimation 10s infinite;
            }
        }
    }
    .membership-banner {
        &-dark {
            @extend %d-none;
        }
    }
    .waf-ad-wrapper {
        border-radius: 0;
    }
}
.dark-mode {
    .waf-ad-membership {
        .membership-banner {
            &-dark {
                @extend %d-block;
            }
            &-light {
                @extend %d-none;
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-ad-membership {
        .thumbnail-section {
            @include position(0, 0, unset, unset);
        }
    }
}
@media (min-width:$desktop-min-width) {
    .waf-ad-membership {
        .thumbnail-section {
            max-width: 40rem;
            @include position(0, 6rem, unset, unset);
        }
        .membership {
            &-list {
                height: 100%;
                aspect-ratio: unset;
            }
            &-item {
                width: min(69%, 40rem);
                @include position(47%, null, unset, -14%);
            }
        }
    }
}
@media (min-width:$xl-desktop-min-width) {
    .waf-ad-membership {
        .thumbnail-section {
            max-width: 50rem;
        }
        .membership {
            &-item {
                @include position(47%, null, unset, -14%);
            }
        }
    }
}