@keyframes loading {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 500px 0;
    }
}
@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
    50% {
        -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
        transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg) scale(1);
        transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
}
@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes loader-reverse {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg) scale(1);
        transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
    50% {
        -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
        transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
    }
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
}
@-webkit-keyframes loading {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 500px 0;
    }
}
@keyframes bouncing-arrow {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
@-webkit-keyframes bouncing-arrow {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}
@keyframes marquee {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}
@keyframes gradient-bg {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
@keyframes placeholderShimmer {
    100% {
        transform: translateX(100%);
    }
}
@-webkit-keyframes placeholderShimmer {
    100% {
        transform: translateX(100%);
    }
}
@keyframes exploreRotate {
    30% {
        transform: rotate(0deg);
    }
    50%,
    80% {
        transform: rotate(45deg);
    }
}
@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes firework {
    0% {
        transform: translate(var(--x), var(--initialY));
        width: var(--initialSize);
        opacity: 1;
    }
    50% {
        width: 0.5vmin;
        opacity: 1;
    }
    100% {
        width: var(--finalSize);
        opacity: 0;
    }
}
@keyframes hideFireWork {
    0% {
        transform: translateY(0vh);
    }
    99% {
        transform: translateY(0vh)
    }
    100% {
        transform: translateY(-150vh)
    }
}
@keyframes scaleButtonIcon {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
    }
}
@keyframes cardEliteAnimation {
    25% {
        transform: rotate(35deg);
    }
    30%,
    60% {
        transform: rotate(35deg);
    }
    65%,
    75% {
        transform: translateY(2%) rotate(35deg);
    }
    80%,
    95% {
        transform: rotate(35deg);
    }
    100% {
        transform: rotate(35deg);
    }
}
@keyframes cardProAnimation {
    25% {
        transform: rotate(35deg);
    }
    30%,
    60% {
        transform: rotate(53deg);
    }
    65%,
    75% {
        transform: translateY(-2%) rotate(53deg);
    }
    80%,
    95% {
        transform: rotate(53deg);
    }
    100% {
        transform: rotate(35deg);
    }
}
@keyframes cardInsiderAnimation {
    40% {
        transform: rotate(35deg);
    }
    45%,
    60% {
        transform: rotate(71deg);
    }
    65%,
    75% {
        transform: translateY(2%) rotate(71deg);
    }
    80%,
    95% {
        transform: rotate(71deg);
    }
    100% {
        transform: rotate(35deg);
    }
}
@keyframes buttonBorderAnimation {
    0% {
        opacity: 1;
        --btn-angle: 0deg;
        --btn-color-1: transparent;
        --btn-color-1-size: 20%;
        --btn-color-2-size: 80%;
    }
    20% {
        --btn-angle: 360deg;
        --btn-color-1: transparent;
        --btn-color-1-size: 20%;
        --btn-color-2-size: 80%;
    }
    40% {
        opacity: 1;
        --btn-angle: 540deg;
        --btn-color-1: var(--color-white-default);
        --btn-color-1-size: 0%;
        --btn-color-2-size: 100%;
    }
    50% {
        opacity: 0;
        --btn-angle: 540deg;
    }
    55%,
    100% {
        opacity: 0;
        --btn-angle: 720deg;
        --btn-color-1: transparent;
        --btn-color-1-size: 100%;
        --btn-color-2-size: 0%;
    }
}