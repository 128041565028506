@use "./config" as *;
@forward './home-page/showcase';
@forward './home-page/royals-tv';
@forward './home-page/store';
@forward './home-page/poll';
@forward './home-page/app-banner';
@forward './listing/listing';
@forward './podcast/podcast-home';
@forward './home-page/newsletter';
@forward './squad/squad';
@forward './home-page/foundation';
@forward './standings/home-standings';
@forward './standings/common-standings';
@forward './home-page/scorestrip';
@forward './fixtures/fixture-common';
@forward './fixtures/fixtures-home';
@forward './swiper/swiper-bundle';
@forward './home-page/homepage-hindi';
@forward './home-page/membership-banner';
@forward './home-page/jersey-banner';
@forward './form';
@forward './home-page/popular-search';
@forward './tracker/statslisting';
@forward './tracker/tracker';
@forward './home-page/impact-stats';
@forward './home-page/home-playerlist';
.home {
    .waf-breadcrumb {
        display: none;
    }
    &.dark-mode {
        @extend %black-default-bg;
    }
}
.title {
    margin: 0;
    // @extend %capitalize;
    @extend %black-dark;
    @extend %font-22-primary-b;
}
.waf-head {
    margin-bottom: var(--full-space);
    @extend %m-b-full;
    @extend %flex-sb-c;
}
.waf-listing {
    .meta {
        &.meta-date {
            font-size: 1.2rem;
        }
    }
    .head-tab {
        a {
            font-size: 1.4rem;
            font-family: $font-primary-bold;
            @extend %black-dark;
        }
    }
}
$bg-list: (
    mega,
    news,
    tv,
    video,
    photo,
    rsb,
    podcast,
    store,
    squad,
    showcase
);
.home-mega-list,
.home-news-list,
.home-tv-list,
.home-video-list,
.home-photo-list,
.home-rsb-list,
.home-podcast-list,
.home-store-list,
.home-squad-list,
.home-showcase-list {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@each $bg in $bg-list {
    .load-bg-pattern {
        .home-#{$bg}-list {
            background-image: url(/static-assets/images/cssimages/homepage/mobile/#{$bg}.jpg?v=#{$image-version});
        }
    }
    .load-bg-pattern.dark-mode {
        .home-#{$bg}-list {
            background-image: url(/static-assets/images/cssimages/homepage/mobile/#{$bg}-dark.jpg?v=#{$image-version});
        }
    }
    @media screen and (min-width: $tablet-min-width) {
        .load-bg-pattern {
            .home-#{$bg}-list {
                background-image: url(/static-assets/images/cssimages/homepage/#{$bg}.jpg?v=#{$image-version});
            }
        }
        .load-bg-pattern.dark-mode {
            .home-#{$bg}-list {
                background-image: url(/static-assets/images/cssimages/homepage/#{$bg}-dark.jpg?v=#{$image-version});
            }
        }
    }
}
.load-bg-pattern {
    .sticky-container-poll-app {
        @include background("cssimages/homepage/mobile/poll-section-bg.jpg");
        background-size: cover;
    }
    &.dark-mode {
        .waf-row-matches-standings {
            background: url(/static-assets/images/cssimages/homepage/matches-bg-dark.jpg?v=#{$image-version}) no-repeat;
            background-size: cover;
        }
        .sticky-container-poll-app {
            @include background("cssimages/homepage/mobile/poll-section-bg-dark.jpg");
            background-size: cover;
        }
    }
    .waf-scorestrip.widget-layout-08 {
        &::before {
            background: url(/static-assets/images/cssimages/homepage/mobile/matches-bg.jpg?v=#{$image-version}) bottom center no-repeat;
            background-size: cover;
        }
    }
    &.dark-mode .sticky-container-poll-app {
        background: url(/static-assets/images/cssimages/homepage/mobile/poll-dark.jpg?v=#{$image-version}) bottom center no-repeat;
        background-size: cover;
    }
    .sticky-container-poll-app {
        background: url(/static-assets/images/cssimages/homepage/mobile/poll.jpg?v=#{$image-version}) bottom center no-repeat;
        background-size: cover;
    }
    .waf-row-tracker {
        @include background("cssimages/homepage/mobile/tracker.png", no-repeat top/cover);
    }
    &.dark-mode .waf-row-tracker {
        @include background("cssimages/homepage/mobile/tracker-dark.png", no-repeat top/cover);
    }
    .waf-row-statslisting {
        @include background("cssimages/homepage/mobile/tracker.png", no-repeat bottom/cover);
    }
    &.dark-mode .waf-row-statslisting {
        @include background("cssimages/homepage/mobile/tracker-dark.png", no-repeat bottom/cover);
    }
}
.waf-listing {
    .article-item {
        img {
            transition: scale 300ms;
        }
        &:hover .img-box {
            img {
                scale: 1.1;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .dark-mode.load-bg-pattern {
        .waf-row-academy-foundation {
            @include background("svg/section-bg-dark.svg");
            background-size: cover;
        }
        .sticky-container-poll-app {
            @include background("cssimages/homepage/poll-section-bg-dark.jpg");
            background-size: cover;
        }
        .waf-row-tracker {
            @include background("cssimages/homepage/tracker-dark.png", no-repeat top/cover);
        }
        .waf-row-statslisting {
            @include background("cssimages/homepage/tracker-dark.png", no-repeat bottom/cover);
        }
    }
    .load-bg-pattern {
        .waf-row-matches-standings {
            background: url(/static-assets/images/cssimages/homepage/matches-bg.jpg?v=#{$image-version}) no-repeat;
            background-size: cover;
        }
        .sticky-container-poll-app {
            @include background("cssimages/homepage/poll-section-bg.jpg");
            background-size: cover;
        }
        .waf-row-tracker {
            @include background("cssimages/homepage/tracker.png", no-repeat top/cover);
        }
        .waf-row-statslisting {
            @include background("cssimages/homepage/tracker.png", no-repeat bottom/cover);
        }
    }
    .home-rsb-list,
    .home-mega-list,
    .home-news-list,
    .home-podcast-list,
    .home-video-list,
    .home-photo-list {
        .layout-wrapper {
            padding-block: var(--full-space);
        }
    }
}