@use '../config/' as *;
.dark-mode .waf-popular-search {
    @include background("cssimages/popular-search/popular-search-dark-m.png", left/cover);
}
.waf-popular-search {
    @include background("cssimages/popular-search/popular-search-m.png", left/cover);
    @extend %p-full;
    @extend %primary-bg;
    @extend %m-x-half-neg;
    
    .popular {
        &-section-title {
            margin-block: 0 var(--full-space);
            @extend %font-22-primary-b;
            @extend %white-default;
            @extend %flex-c-c;
            @extend %gap-half;
            &::before {
                @include icon(search-icon);
            }
        }
        &-list {
            flex-wrap: wrap;
            @extend %flex-c-c;
            @extend %gap-full;
        }
        &-title-link {
            position: relative;
            @extend %p-half;
            @extend %radius-half;
            @extend %white-default-bg-1;
            &:not(:last-child) {
                &::after {
                    content: "";
                    width: .2rem;
                    height: 80%;
                    position: absolute;
                    top: 10%;
                    right: -1rem;
                    @extend %white-default-bg-2;
                }
            }
        }
        &-title {
            font-size: 1.2rem;
            margin: 0;
            @extend %white-default;
            // &::after {
            //     content: '\e804';
            //     margin-left: var(--space-2);
            //     font-family: $font-icon;
            //     font-size: 1.2rem;
            // }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .dark-mode .waf-popular-search {
        @include background("cssimages/popular-search/popular-search-dark-d.png", left/cover);
    }
    .waf-popular-search {
        @include background("cssimages/popular-search/popular-search-d.png", left/cover);
        .popular{
            &-section-title{
                font-size: 2.4rem;
            }
            &-title{
                font-size: 1.4rem;
                &-link:not(:last-child)::after{
                    right: -2rem;
                }
            }
            &-list{
                gap: 2rem 4rem;
            }
        }
    }
}