@use "./config" as *;
.dark-mode {
    .home-playlist {
        .article {
            &-wrap {
                @include bg(page-background);
            }
            &-title {
                .text {
                    @extend %white-default;
                }
            }
        }
        .title {
            @extend %white-default;
        }
    }
}
.home-playlist {
    @extend %py-8;
    @extend %px-1;
    @extend %my-0;
    .layout-wrapper {
        max-width: var(--container-max-width);
        margin: 0 auto;
    }
    .head-tab {
        @extend %d-none;
    }
    .title {
        @extend %font-24-primary-b;
        @extend %black-default;
    }
    .article {
        &-list {
            @include card-count-new(1.3, var(--space-3))
        }
        &-item {
            @extend %full-radius;
            @extend %hidden;
        }
        &-title {
            @extend %flex-column-c-c;
            @extend %mt-2;
            .text {
                @extend %font-20-primary-b;
                @extend %black-default;
            }
        }
        &-wrap {
            @extend %white-default-bg;
            @extend %pb-4;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .home-playlist {
        .waf-body {
            position: relative;
        }
        .preview-swiper {
            position: static;
            .article {
                &-list {
                    @include card-count-new(4, var(--space-3));
                }
                &-item {
                    cursor: pointer;
                    &:hover .img-box {
                        img {
                            scale: 1.1;
                        }
                    }
                }
                &-thumbnail {
                    img {
                        transition: scale 300ms;
                    }
                }
            }
            &.swiper-initialized {
                .article-list {
                    gap: 0;
                    overflow-x: unset;
                }
                .swiper-button-next,
                .swiper-button-prev {
                    display: flex;
                }
            }
        }
        .swiper-button-prev {
            left: -2rem;
        }
        .swiper-button-next {
            right: -2rem
        }
        .swiper-button-next,
        .swiper-button-prev {
            display: none;
            background-color: clr(black-default, 2);
            @include center(vertical);
        }
    }
}