@use "./config" as *;
.dark-mode {
	.waf-squad {
		.title {
			@extend %white-default;
		}
		.head-tab {
			a {
				@extend %white-default;
			}
		}
		.squad-item {
			&.overseas {
				.icon {
					@extend %button-primary-bg;
				}
			}
		}
	}
}
.waf-squad {
	margin: 0 var(--half-space-negative);
	.head-wrap {
		@extend %flex-v-center;
		@extend %m-b-full;
	}
	.title {
		margin: 0;
		// @extend %uppercase;
		@extend %black-default;
		@extend %font-24-primary-b;
	}
	.head-tab {
		a {
			@extend %black-default;
			@extend %font-14-primary-r;
			@extend %m-l-full;
		}
	}
	.squad-wrapper {
		@extend %flex;
	}
	.squad-item {
		isolation: isolate;
		width: 100%;
		flex-shrink: 0;
		@include border-radius(var(--half-radius));
		&.overseas {
			.icon {
				width: 2.8rem;
				height: 2.8rem;
				padding: 0.7rem;
				@extend %primary-dark-bg;
				@extend %circle;
				@extend %position-top-right;
				@extend %flex-center;
				&::before {
					content: "\e820";
					font-size: 1.2rem;
					font-family: $font-icon;
					@extend %white-default;
				}
			}
		}
	}
	.first-name {
		@extend %white-default;
		@extend %font-44-primary-r;
		@extend %uppercase;
	}
	.last-name {
		@extend %accent;
		@extend %font-28-primary-b;
		@extend %uppercase;
	}
	.player {
		&-role {
			@extend %flex;
		}
		&-country {
			@extend %flex-v-center;
		}
		&-wrap {
			position: relative;
			@extend %p-full;
		}
		&-thumbnail {
			min-height: 48rem;
		}
		&-content {
			padding: var(--full-space) var(--two-space);
			@extend %w-100;
			@extend %position-bottom-left;
			@include linear-gradient(hsl(var(--primary-light-gradient) / 0.5) 0%,
				hsl(var(--primary-mid-gradient) / 7) 43.23%,
				hsl(var(--primary-dark-gradient) / 7) 100%);
		}
		&-name {
			@extend %m-b-full;
			position: relative;
			&::after {
				content: "";
				height: 0.1rem;
				bottom: var(--half-space-negative);
				@extend %w-100;
				@extend %white-default-bg-1;
				@include center(horizontal);
			}
		}
	}
	.country {
		&-flag {
			width: 3.6rem;
			height: auto;
			@include border-radius(0.5rem);
		}
		&-name {
			@extend %white-default-5;
			@extend %font-12-m;
			@extend %m-l-quater;
		}
	}
	.player-stats {
		&-item {
			flex-basis: 100%;
			position: relative;
			@include flex-config(flex, column, null, center);
			&:first-child::before,
			&::after {
				content: "";
				width: 0.1rem;
				@extend %h-100;
				@extend %white-default-bg-1;
				@include center(vertical);
			}
			&::after {
				right: 0;
			}
			&:first-child::before {
				left: 0;
			}
		}
		&-title {
			@extend %white-default-5;
			@extend %font-10-m;
			@extend %uppercase;
			@extend %m-b-half;
		}
		&-count {
			@extend %white-default;
			@extend %font-48-primary-r;
		}
		&-list {
			@extend %m-t-full;
			@extend %m-b-full;
			@extend %flex;
		}
	}
	.role {
		position: relative;
		@extend %white-default;
		@extend %font-14-m;
		@extend %m-r-full;
		@extend %capitalize;
		&::after {
			content: "";
			width: 0.1rem;
			right: var(--half-space-negative);
			@extend %h-100;
			@extend %white-default-bg-1;
			@include center(vertical);
		}
	}
	.card-action {
		flex-basis: 100%;
		width: 100%;
		flex-shrink: 0;
		position: relative;
		gap: var(--quater-space);
		@extend %m-t-two;
		@extend %flex-center;
		&::after {
			content: '';
			height: 0.1rem;
			top: var(--full-space-negative);
			@extend %w-100;
			@extend %white-default-bg-1;
			@include center(horizontal);
		}
		.btn-site {
			width: calc(50% - 0.25rem);
			flex-basis: calc(50% - 0.25rem);
			flex-shrink: 0;
		}
	}
	.btn-buy {
		background-color: transparent;
		border-color: hsl(var(--color-hsl-white-default) / 1);
		@extend %white-default;
		@extend %text-center;
		@extend %btn-fill;
		@extend %font-12-secondary-b;
	}
}