@use "./config" as *;
.dark-mode {
	.waf-poll {
		.title {
			@extend %white-default;
		}
		.progress-bar {
			@extend %button-primary-bg;
		}
		.radiomark::after {
			@extend %button-primary-bg;
		}
		.poll-head {
			background-color: var(--color-black-default);
		}
	}
}
.sticky-container-poll-app {
	padding: var(--full-space) var(--container-white-space);
	align-items: flex-end;
	@extend %extra-dark-bg;
}
.site-poll {
	margin: 0 var(--half-space-negative);
}
.waf-poll {
	padding: 0;
	.waf-head {
		margin-bottom: calc(var(--full-space) - var(--half-space));
	}
	.global-mgs {
		.message:empty {
			@extend %d-none;
		}
	}
	.custom-form {
		gap: 0;
	}
	.radio-box {
		display: block;
		position: relative;
		cursor: pointer;
		user-select: none;
		margin: var(--half-space) 0;
		border: .05rem solid var(--color-text);
		@extend %p-half;
		@extend %half-radius;
		.text {
			@extend %capitalize;
			@extend %font-18-primary-r;
		}
		.radio-container {
			flex-direction: row-reverse;
			@extend %flex-sb-c;
		}
		&-wrap {
			@extend %w-100;
			@extend %flex-column;
		}
		.radiomark {
			display: inline-block;
			position: relative;
			height: 1.5rem;
			width: 1.5rem;
			border: 0.1rem solid var(--color-text);
			@extend %circle;
			&:after {
				content: "";
				width: 0;
				height: 0;
				@extend %circle;
				@extend %primary-bg;
				@include position(0.3rem, null, null, 0.3rem);
				@include transition(all 100ms linear);
			}
		}
	}
	.form-radio-input {
		position: absolute;
		pointer-events: none;
		opacity: 0;
		width: 0;
		height: 0;
		&:checked ~ .radiomark:after {
			width: 0.7rem;
			height: 0.7rem;
		}
	}
	.poll-head {
		@include background("cssimages/homepage/poll/poll-head.svg", var(--color-secondary-dark));
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: auto 100%;
		@extend %half-radius;
		@extend %text-center;
		@extend %p-full;
	}
	.title {
		@extend %font-22-primary-b;
		@extend %black-default;
	}
	.waf-body {
		margin: 0 var(--half-space-negative);
	}
	.btn-fill {
		@extend %w-100;
	}
	.poll-section {
		padding: var(--half-space);
		@extend %m-b-half;
		.title {
			position: relative;
			@extend %font-22-primary-r;
			@extend %white-default;
		}
	}
	.poll-result {
		display: none;
		@extend %p-x-half;
		&.active {
			display: block;
		}
		&-wrap {
			@extend %flex-column;
		}
		.text {
			@extend %capitalize;
			@extend %font-20-primary-r;
		}
		.progress {
			&-count {
				@extend %radius-half;
				@extend %p-quater;
				@extend %primary-bg-4;
				@extend %font-14-primary-b;
				@extend %white-default;
			}
			&-bar-holder {
				flex-shrink: 0;
				height: 0.6rem;
				position: relative;
				@extend %radius-full;
				@extend %w-100;
				@extend %m-t-half;
				@extend %black-dark-bg-1;
			}
			&-bar {
				display: inline-block;
				@extend %h-100;
				@extend %primary-bg;
				@extend %radius-full;
				@extend %position-top-left;
			}
		}
	}
	.poll-count {
		flex-wrap: wrap;
		margin: var(--half-space) 0;
		@extend %flex-sb;
		&.lead .progress-count {
			@extend %primary-bg;
		}
	}
}
.site-app {
	> div {
		@extend %w-100;
		@extend %h-100;
		@extend %flex;
	}
}
@media screen and (min-width: $tablet-min-width) {
	.dark-mode {
		.sticky-container-poll-app {
			background-color: clr(extra-dark);
			background-size: cover;
			.col-lg-6:last-child {
				padding-right: 0;
			}
		}
		.waf-poll {
			background: var(--color-black-default);
			.poll-head {
				background-color: var(--color-extra-dark);
			}
		}
		.custom-form {
			background: var(--color-primary);
		}
	}
	.waf-poll {
		background: linear-gradient(180deg, var(--color-primary-dark) -16.97%, var(--color-secondary-dark) 109.7%);
		border-radius: var(--half-radius);
		padding: var(--full-space);
		.title {
			color: var(--color-white-default);
		}
		.waf-body {
			margin: 0;
		}
		.poll-result-wrap,
		.radio-box-wrap {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: var(--half-space);
			margin-top: var(--full-space);
		}
		.poll-section {
			padding: 0;
			margin-bottom: 0;
			border-radius: 0.5rem;
			@include border-radius(var(--half-radius));
			.poll-head {
				min-height: 7rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: var(--half-space);
				justify-content: center;
			}
			.title {
				@include font(20);
				text-align: left;
			}
		}
		.vote-now {
			margin-top: var(--half-space);
			.btn-fill {
				width: 100%;
			}
		}
		.radio-box {
			margin: 0;
			width: 100%;
			border-color: var(--color-white-default);
			.text {
				color: var(--color-white-default);
			}
			&-wrap {
				min-height: 11.2rem;
				gap: var(--full-space);
				margin-top: var(--full-space);
			}
			.radiomark {
				border-color: var(--color-white-default);
			}
		}
		.poll-result-wrap {
			.poll-count {
				width: 100%;
			}
			.text {
				color: clr(white-default);
			}
			.progress-bar-holder {
				background-color: clr(white-default, 2);
			}
		}
	}
	.dark-mode {
		.waf-poll {
			.poll-section {
				background: var(--color-primary-dark);
			}
		}
	}
}
@media (min-width: $xl-desktop-min-width) {
	.waf-poll {
		padding: var(--one-n-half-space) var(--full-space);
		.waf-head {
			.title {
				margin-bottom: var(--full-space);
				font-size: 2.8rem;
			}
		}
		.poll-section {
			.poll-head {
				padding: var(--full-space);
			}
		}
		.vote-now {
			margin-top: var(--full-space);
		}
	}
}