@use "../config" as *;

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
	width: 100%;
	padding-right: var(--half-space);
	padding-left: var(--half-space);
	margin-right: auto;
	margin-left: auto;
}

.row {
	flex-wrap: wrap;
	@extend %m-r-half-neg;
	@extend %m-l-half-neg;
	@extend %flex;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
	position: relative;
	width: 100%;
	padding-right: var(--half-space);
	padding-left: var(--half-space);
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;

	& &-item,
	iframe,
	embed,
	object,
	video {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}

	&::before {
		display: block;
		content: "";
	}

}
@each $width, $height in $aspect-ratio {
	.embed-responsive.embed-responsive-#{$width}by#{$height}::before {
		padding-top: calc(($height / $width) * 100%);
	}
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}

@media (min-width: $medium-mobile-min-width) {

	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		max-width: var(--container-max-width);
	}
}

@each $col, $width in $media-query {
	@media (min-width: #{$width}) {
		@for $i from 1 through 12 {
			.#{$col}-#{$i} {
				flex: 0 0 calc(100% / 12 * $i);
				max-width: calc(100% / 12 * $i);
			}
		}
	}
}