@use "variables" as *;
@use "map" as *;
@mixin shadow($box-shadow...) {
	-webkit-box-shadow: $box-shadow;
	-moz-box-shadow: $box-shadow;
	box-shadow: $box-shadow;
}
@mixin placeholder($color) {
	&::-webkit-input-placeholder {
		/* WebKit browsers */
		color: $color;
	}
	&:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: $color;
	}
	&::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: $color;
	}
	&:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: $color;
	}
}
@mixin flex-config($display,
	$flex-direction: null,
	$justify-content: null,
	$align-items: null) {
	display: $display;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}
@mixin flex {
	display: flex;
}
@mixin linear-gradient($gradientValues...) {
	background: linear-gradient($gradientValues);
}
@mixin transform($value) {
	transform: $value;
}
@mixin translate($x, $y) {
	transform: translate($x, $y);
}
@mixin rotate($deg) {
	@include transform(rotate(#{$deg}deg));
}
@mixin transition($transition...) {
	transition: $transition;
}
@mixin center($position) {
	position: absolute;
	@if $position=="vertical" {
		top: 50%;
		@include transform(translateY(-50%));
	}
	@else if $position=="horizontal" {
		left: 50%;
		@include transform(translateX(-50%));
	}
	@else if $position=="both" {
		top: 50%;
		left: 50%;
		@include transform(translate(-50%, -50%));
	}
}
@mixin position($top: null, $right: null, $bottom: null, $left: null, $position: absolute) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}
@mixin border-radius($value, $overflow: visible) {
	border-radius: $value;
	overflow: $overflow;
}
@mixin card-count($count, $gap: 0rem) {
	flex-basis: calc((100% / $count) - $gap);
	width: calc((100% / $count) - $gap);
	margin-left: calc($gap / 2);
	margin-right: calc($gap / 2);
}
@mixin grid($count, $gap: var(--full-space)) {
	display: grid;
	grid-template-columns: $count;
	gap: $gap;
}
@mixin font($font-size, $font-family: null) {
	@if $font-family ==null {
		font-size: calc($font-size * 0.1rem);
		line-height: calc(($font-size * 0.1rem) + 0.2rem);
	}
	@else {
		font: calc($font-size * 0.1rem) / calc(($font-size * 0.1rem) + 0.2rem) $font-family;
	}
}
//use @include font(14); if you dont want pass the famliy just want to change the font size
//use @include font(14, $font-bold);
@mixin line($color: light) {
	&::after {
		content: "";
		width: 0.2rem;
		height: 2rem;
		@if $color ==light {
			background-color: hsl(var(--color-hsl-primary-light) / 0.5);
		}
		@else if $color ==dark {
			background-color: hsl(var(--color-hsl-primary-dark) / 0.5);
		}
		right: 0;
		@include center(vertical);
	}
}
@mixin bg($color, $opacity: 10) {
	background-color: hsl(var(--color-hsl-#{$color}) / #{calc($opacity / 10)});
}
@mixin color($color, $opacity: 10) {
	color: hsl(var(--color-hsl-#{$color}) / #{calc($opacity / 10)});
}
@mixin dropdown($status, $animation-delay: 300ms) {
	@if $status ==close {
		clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
		@include transition(clip-path $animation-delay linear);
	}
	@else if $status ==open {
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
	}
}
@mixin dropup($status, $animation-delay: 300ms) {
	@if $status ==close {
		clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
		@include transition(clip-path $animation-delay linear);
	}
	@else if $status ==open {
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
	}
}
@mixin slide-left($status, $animation-delay: 300ms) {
	@if $status ==close {
		clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);
		@include transition(clip-path $animation-delay linear);
	}
	@else if $status ==open {
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
	}
}
@mixin fade-in($status, $animation-delay: 300ms) {
	@if $status ==close {
		opacity: 0;
		pointer-events: none;
		@include transition(opacity $animation-delay linear);
	}
	@else if $status ==open {
		pointer-events: inherit;
		opacity: 1;
	}
}
@mixin vertical-card() {
	.article {
		&-item {
			flex-shrink: 0;
			height: max-content;
			background-color: var(--color-white-default);
			@include card-count(1.1, var(--half-space));
			@include border-radius(var(--half-radius));
		}
		&-content {
			padding: var(--half-space);
		}
		&-title {
			margin-top: 0;
			color: var(--color-black-default);
			margin-bottom: var(--half-space);
			@include font(20, $font-primary-bold);
			@include truncate-vertical-line(20, 2);
		}
	}
}
@mixin scroll($width, $shadow, $bg-color, $outline) {
	&::-webkit-scrollbar {
		width: $width;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px $shadow;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $bg-color;
		outline: 1px solid $outline;
	}
}
@mixin body-bg() {
	&::before,
	&::after {
		background: url(/static-assets/images/svg/triangle-dark.svg?v=#{$image-version}) bottom center no-repeat;
		background-size: contain;
		content: "";
		width: 50rem;
		height: 50rem;
		opacity: 0.3;
		filter: grayscale(100%);
		pointer-events: none;
		z-index: var(--bg-gradient);
	}
	&::after {
		@include rotate(180);
		@include position(3rem, -25rem, null, null, fixed);
	}
	&::before {
		@include position(null, null, -12rem, -25rem, fixed);
	}
}
@mixin custom-scroll {
	&::-webkit-scrollbar {
		width: 0.3rem;
	}
	&::-webkit-scrollbar-track {
		background: hsl(var(--color-hsl-white-default)/.1);
		border-radius: var(--half-radius);
	}
	&::-webkit-scrollbar-thumb {
		border-radius: var(--half-radius);
		background: var(--color-grey);
	}
	&::-webkit-scrollbar-thumb:hover {
		border-radius: var(--half-radius);
		background: var(--color-accent);
	}
}
@mixin truncate-vertical-line($font-size, $line-number, $line-height: null) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: $line-number;
	font-size: calc($font-size * 0.1rem);
	@if $line-height ==null {
		line-height: calc(($font-size * 0.1rem) + 0.2rem);
		height: calc($line-number * (($font-size * 0.1rem) + 0.2rem));
	}
	@else {
		line-height: $line-height;
		height: calc($line-number * $line-height);
	}
}
// @include truncate-vertical-line(16,3);
// @include truncate-vertical-line(16,3,1.6rem);
@mixin font-style($font-size, $line-height: null, $font-family: null) {
	@if $font-family !=null and $line-height !=null and $line-height !=inherit {
		font: calc($font-size * 0.1rem) / calc($line-height * 0.1rem) $font-family;
	}
	@else if $font-family !=null and $line-height ==null and $line-height !=inherit {
		font: calc($font-size * 0.1rem) / calc(($font-size * 0.1rem) + 0.2rem) $font-family;
	}
	@else if $font-family ==null and $line-height ==null {
		font-size: calc($font-size * 0.1rem);
		line-height: calc(($font-size * 0.1rem) + 0.2rem);
	}
	@else if $font-family !=null and $line-height ==inherit {
		font: calc($font-size * 0.1rem) $font-family;
	}
	@else if $font-family ==null and $line-height !=null and $line-height !=inherit {
		font-size: calc($font-size * 0.1rem);
		line-height: calc($line-height * 0.1rem);
	}
}
// @include font-style(20,inherit,$font-primary-bold);
// @include font-style(20,20,$font-primary-bold);
// @include font-style(20,30);
// @include font-style(20);
// @include font-style(20,null,$font-primary-bold);
@mixin position-combo($combo: null, $x: null, $y: null, $position: absolute) {
	position: $position;
	@if $combo =="tl" {
		top: 0;
		left: 0;
	}
	@else if $combo =="tr" {
		top: 0;
		right: 0;
	}
	@else if $combo =="bl" {
		bottom: 0;
		left: 0;
	}
	@else if $combo =="br" {
		bottom: 0;
		right: 0;
	}
	@else if $combo =="inset" {
		inset: 0;
	}
	@else if $combo=="center" {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@else if $combo=="y-center" {
		top: 50%;
		right: $x;
		left: $y;
		transform: translate(0, -50%);
	}
	@else if $combo=="x-center" {
		left: 50%;
		top: $x;
		bottom: $y;
		transform: translate(-50%, 0);
	}
}
@mixin border($direction: all, $width: 1, $color: null, $opacity: 10, $style: solid) {
	$opacity-decimal: calc($opacity / 10);
	$color-hsla: hsl(var(--color-hsl-#{$color}) / $opacity-decimal);
	$width-rem: calc($width * 0.1rem);
	@if $direction ==all {
		border: $width-rem $style $color-hsla;
	}
	@else if $direction ==top {
		border-top: $width-rem $style $color-hsla;
	}
	@else if $direction ==right {
		border-right: $width-rem $style $color-hsla;
	}
	@else if $direction ==bottom {
		border-bottom: $width-rem $style $color-hsla;
	}
	@else if $direction ==left {
		border-left: $width-rem $style $color-hsla;
	}
	@else if $direction ==x {
		border-inline: $width-rem $style $color-hsla;
	}
	@else if $direction ==y {
		border-block: $width-rem $style $color-hsla;
	}
	@else {
		@error "Invalid direction #{$direction}, must be one of: all, left, right, top, bottom.";
	}
}
// how to use
// @include border(all);
// @include border(all, .4rem);
// @include border(all, .4rem,primary-hsl);
// @include border(all, .4rem,primary-hsl,5);
// @include border(all, .4rem,primary-hsl,null,solid);
// @include border(all, .1rem,primary-hsl,10,solid);
// @include border(left, .1rem,primary-hsl,10,solid);
// @include border(right, .1rem,primary-hsl,10,solid);
// @include border(top, .1rem,primary-hsl,10,solid);
// @include border(bottom, .1rem,primary-hsl,10,solid);
// @include border(all, .1rem,primary-hsl,10,solid);//default
@mixin icon($name, $size: 14, $line-height: null) {
	// $icon: map-get($icons, $name);
	@if $icon !=null {
		content: $icon;
		@include font($size, $line-height, $font-icon)
	}
	@else {
		@error "Icon '#{$name}' not found in $icons map.";
	}
}
// how to use 
// @include icon(arrow-down)
// @include icon(arrow-down,14,null)
// shimmer
@mixin shimmer($width: null, $height: null, $radius: var(--half-space)) {
	width: $width;
	height: $height;
	display: block;
	font-size: 0;
	background: var(--shimmer-light-color);
	background-repeat: no-repeat;
	border-radius: $radius;
	overflow: hidden;
	position: relative;
	&::after {
		content: "";
		background-image: linear-gradient(-45deg,
				var(--shimmer-light-color) 0%,
				var(--shimmer-light-color) 40%,
				var(--shimmer-dark-color) 50%,
				var(--shimmer-light-color) 60%,
				var(--shimmer-light-color) 100%);
		position: absolute;
		inset: -150%;
		transform: translateX(-100%);
		-webkit-animation: placeholderShimmer 2s linear 0s infinite normal forwards;
		animation: placeholderShimmer 2s linear 0s infinite normal forwards;
	}
	* {
		font-size: 0;
	}
}
// how to use
// @include shimmer()
// @include shimmer(100,20)
// @include shimmer(10,30,var(--space-2))
/*Background Properties*/
@mixin background($bg-img: null, $bg-props...) {
	@if $bg-img ==null {
		background: $bg-props;
	}
	@else {
		// background: url('/static-assets/images/#{$bg-img}?v=#{$image-version}') $bg-props;
		background: url('/static-assets/images/#{$bg-img}?v=#{$image-version}') $bg-props;
	}
}
// how to use
// @include background("live-stream/detail.jpg", hsl(var(--color-hsl-primary)) fixed no-repeat top/cover);
// @include background("live-stream/detail.jpg", fixed no-repeat top/cover);
// @include background("live-stream/detail.jpg", fixed top/cover);
// @include background("live-stream/detail.jpg", no-repeat top/cover);
// @include background("live-stream/detail.jpg", no-repeat top);
// @include background(null, hsl(var(--color-hsl-primary)) fixed no-repeat top/cover);
// @include background("live-stream/detail.jpg", linear-gradient(lightgreen, lightblue) fixed no-repeat top/cover);
// @include background("live-stream/detail.jpg", linear-gradient(90deg, hsl(var(--color-hsl-success-text)) 0.93%, hsl(var(--color-hsl-white-default)) 100%));
@mixin icon($name, $size: null, $line-height: null) {
	$icon: map-get($icons, $name);
	font-family: $font-icon;
	@if $icon !=null {
		content: $icon;
		font-weight: 400;
		@if $size !=null {
			font-size: $size * 0.1rem;
		}
		@if $line-height !=null {
			line-height: $line-height * 0.1rem;
		}
	}
	@else {
		@error "Icon '#{$name}' not found in $icons map.";
	}
}
// how to use
// @include icon(arrow-down)
// @include icon(arrow-down,14,null)
@mixin card-count-new($count, $gap: 0rem, $wrap: nowrap) {
	@include flex-config(flex, row);
	@if $gap !=0rem {
		gap: $gap;
	}
	@if $count % 1==0 {
		@if $wrap ==wrap {
			flex-wrap: wrap;
			overflow-x: hidden;
		}
		@else if $wrap ==nowrap {
			overflow-x: auto;
		}
		& > * {
			width: calc(100% / $count - (($count - 1) * $gap / $count));
			flex-basis: calc(100% / $count - (($count - 1) * $gap / $count));
			flex-shrink: 0;
		}
	}
	@else {
		overflow-x: auto;
		& > * {
			width: calc(100% / $count - ($count / ceil($count) * $gap));
			flex-basis: calc(100% / $count - ($count / ceil($count) * $gap));
			flex-shrink: 0;
		}
	}
}
// @mixin aspect-ratio($width, $height) {
//   aspect-ratio: list.slash($width, $height);
//   // Fallback (current, using padding hack)
//   @supports not (aspect-ratio: list.slash(1,1)) {
//      &::before {
//       float: left;
//       padding-top: calc(100% * #{$height} / #{$width});
//       content: "";
//     }
//     &::after {
//       display: block;
//       content: "";
//       clear: both;
//     }
//   }
// }
/*Weglot translation mixin starts*/
@mixin translate-hindi($font-size: null, $line-height: null, $height: null) {
	font-size: $font-size;
	line-height: $line-height;
	height: $height;
}
/*Weglot translation mixin ends*/
// Button border animation start
@mixin button-border-animation() {
	position: relative;
	z-index: var(--z-index1);
	transform-style: preserve-3d;
	&::before, &::after {
		content: '';
		border-radius: var(--half-radius);
		position: absolute;
		z-index: var(--z-index1-negative);
		inset: -.2rem;
		background-image: conic-gradient(from var(--btn-angle), var(--btn-color-1) var(--btn-color-1-size), var(--color-white-default) var(--btn-color-2-size));
		animation: buttonBorderAnimation 10s linear infinite;
		transform: translateZ(-.1rem);
	}
	&::after {
		filter: blur(1rem);
		opacity: .5;
	}
}
// Button border animation end